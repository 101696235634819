import {
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { push } from 'connected-react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { CommandsMenuComponent } from '../../components/commands-menu/commands-menu.component';
import { TablePaginationComponent } from '../../components/table-pagination/table-pagination.component';
import { PaperTable, PaperTableCell } from '../../components/table/paper-table.component';
import { AppRoutePath } from '../../routes/routes';
import { deviceListSelector, deleteDevice, devicesTotalCountSelector } from '../../store';
import { extendTableBreakpoint } from '../../theme';

export interface OwnProps {
  handlePageChange: (newPage: number) => void;
  page: number;
}

export const DevicesTableComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'table', 'data']);
  const devices = useSelector(deviceListSelector);
  const total = useSelector(devicesTotalCountSelector);
  const isTableExtended = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(extendTableBreakpoint)
  );
  const tableCellWidth = isTableExtended ? '20%' : 'calc(100% / 3)';

  const goToEditDevice = (id: string) => {
    dispatch(push(`/${AppRoutePath.devices}/${id}/${AppRoutePath.edit}`));
  };

  const deleteDeviceById = (id: string) => {
    dispatch(deleteDevice(id));
  };

  const StyledTableCell = (props: { canHide?: boolean; children: React.ReactNode }) => {
    return (
      <PaperTableCell
        styleProps={{ isHidden: !!props.canHide && !isTableExtended, width: tableCellWidth }}
      >
        {props.children}
      </PaperTableCell>
    );
  };

  return (
    <>
      <PaperTable dataTestId="devicesTable" ariaLabel={t('data:device.table')}>
        <TableHead>
          <TableRow>
            <StyledTableCell>{t('data:device.name')}</StyledTableCell>
            <StyledTableCell>{t('data:device.deviceType')}</StyledTableCell>
            <StyledTableCell>{t('data:device.line')}</StyledTableCell>
            <StyledTableCell canHide>{t('data:device.sraNumber')}</StyledTableCell>
            <StyledTableCell canHide>{t('data:device.serialNumber')}</StyledTableCell>
            <TableCell>{t('')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices && devices.length > 0 ? (
            devices.map((device) => {
              return (
                <TableRow key={device.id}>
                  <StyledTableCell>
                    <Link
                      component={NavLink}
                      to={`/${AppRoutePath.devices}/${device.id}/${AppRoutePath.edit}`}
                      underline="hover"
                    >
                      {device.name}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>
                    {t([
                      `data:device.deviceTypes.${device.deviceType}`,
                      `data:device.deviceTypes.notFound`,
                    ])}
                  </StyledTableCell>
                  <StyledTableCell>{device.productionLine?.name || ''}</StyledTableCell>
                  <StyledTableCell canHide>{device.sraNumber}</StyledTableCell>
                  <StyledTableCell canHide>{device.serialNumber}</StyledTableCell>
                  <TableCell>
                    <CommandsMenuComponent
                      handleEdit={() => goToEditDevice(device.id)}
                      handleDelete={() => deleteDeviceById(device.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={2}>
                {devices ? t('table:noMatch') : t('common:noDataAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </PaperTable>
      <TablePaginationComponent
        currentItemsCount={devices?.length || 0}
        count={total || 0}
        page={props.page}
        onChangePage={(event: unknown, newPage: number) => props.handlePageChange(newPage)}
      />
    </>
  );
};
