import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ProductionRun } from '../../model';
import { GridContainer, GridLevel } from '../grid/grid.container';
import { GridItem } from '../grid/grid.item';
import { ResponsiveImageComponent } from '../image/responsive-image/responsive-image.component';
import { DescriptionTableComponent, TableData } from '../table/description-table.component';

interface OwnProps {
  productionRun: ProductionRun;
  tableData: TableData[];
}

export const ProductionRunDetailsResponsiveComponent = (props: OwnProps) => {
  const { productionRun, tableData } = props;
  const { t } = useTranslation(['data']);

  return (
    <Box data-testid="ProductionRunDetailsComponent">
      <Typography variant="h3" data-testid="ProductionRunDetails_OrderNumber">
        {productionRun.orderNumber}
      </Typography>
      <GridContainer level={GridLevel.InfoPaper}>
        <GridItem s={4}>
          <ResponsiveImageComponent
            imageUrl={productionRun.article.thumbnailUrl}
            altText={t('data:article.image')}
          />
        </GridItem>
        <GridItem s={8}>
          <DescriptionTableComponent tableData={tableData} />
        </GridItem>
      </GridContainer>
    </Box>
  );
};
