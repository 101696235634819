import PauseIcon from '@mui/icons-material/PauseCircle';
import RuleIcon from '@mui/icons-material/Rule';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  hasActiveTestRun,
  hasIssue,
  hasMessageFlagIssues,
  isRunPausedManually,
} from '../../helper';
import { IssueCode, ProductionRun } from '../../model';
import { irisCustomColors } from '../../theme';

import { disruptionDimension, messageFlagClasses, StyledDiv } from './message-flag.styles';

export interface OwnProps {
  productionRun: ProductionRun;
  disruptionMinutes?: number;
  customContent?: JSX.Element;
  specifiedLabelColor?: string;
  specifiedFlagColor?: string;
}

export const MessageFlagComponent = (props: OwnProps) => {
  const {
    productionRun,
    disruptionMinutes,
    customContent,
    specifiedFlagColor,
    specifiedLabelColor,
  } = props;
  const { t } = useTranslation(['data']);

  const hasTU2Violation = hasIssue(productionRun, IssueCode.FertigPackVTU2Violation);
  const hasTU1Violation = hasIssue(productionRun, IssueCode.FertigPackVTU1Violation);
  const hasCriticalTU1Violation = productionRun.fertigPackVData.hasCriticalTU1Violation;
  const hasAverageWeightViolation = hasIssue(productionRun, IssueCode.AverageWeightBelowNominal);
  const hasCriticalAverageWeightViolation =
    productionRun.fertigPackVData.hasCriticalAverageWeightBelowNominalViolation;
  const activeTestRun = hasActiveTestRun(productionRun);
  const isPausedManually = isRunPausedManually(productionRun);

  const showMessageFlag = hasMessageFlagIssues(productionRun);

  const getFlagColor = () => {
    if (specifiedFlagColor) {
      return specifiedFlagColor;
    } else if (isPausedManually) {
      return irisCustomColors.irisGold;
    } else if (hasTU2Violation || productionRun.disrupted) {
      return irisCustomColors.irisRed;
    } else if (activeTestRun) {
      return irisCustomColors.irisGold;
    } else if (
      hasTU1Violation ||
      hasCriticalTU1Violation ||
      hasAverageWeightViolation ||
      hasCriticalAverageWeightViolation
    ) {
      return irisCustomColors.irisYellow;
    }
  };

  const getLabelColor = () => {
    const hasBrightFlag = getFlagColor() === irisCustomColors.irisYellow;
    return specifiedLabelColor ?? hasBrightFlag
      ? irisCustomColors.irisBlack
      : irisCustomColors.irisWhite;
  };

  return (
    <>
      {(showMessageFlag === true || customContent) && (
        <StyledDiv data-testid="productionRunMessageFlag">
          <Box
            className={messageFlagClasses.messageFlagContainer}
            style={{
              borderTop: `${disruptionDimension}px solid ${getFlagColor()}`,
              borderRight: `${disruptionDimension}px solid ${getFlagColor()}`,
            }}
            data-testid="messageFlagContainer"
          />
          <div className={messageFlagClasses.diagonalShadow} />
          <Box
            className={messageFlagClasses.messageFlagContent}
            style={{ color: `${getLabelColor()}` }}
            data-testid="messageFlagContent"
          >
            {customContent ? (
              <>{customContent}</>
            ) : isPausedManually ? (
              <>
                <PauseIcon fontSize="large" />
                <Typography data-testid={'productionRunPauseInfo'}>
                  <b>{t('data:productionRun.pause')}</b>
                </Typography>
              </>
            ) : hasTU2Violation || productionRun.disrupted === true ? (
              <>
                <WarningIcon fontSize="large" />
                {hasTU2Violation ? (
                  <Typography data-testid={'productionRunTU2ViolationInfo'}>
                    <b>{t('data:productionRun.tu2.short')}</b>
                  </Typography>
                ) : (
                  disruptionMinutes !== undefined && (
                    <Typography data-testid={'productionRunDisruptionInfo'}>
                      <b>{`${disruptionMinutes} ${t('common:minutes')}`}</b>
                    </Typography>
                  )
                )}
              </>
            ) : activeTestRun ? (
              <>
                <Box className={messageFlagClasses.testRunBox}>
                  <Box className={messageFlagClasses.imageBox}>
                    <RuleIcon
                      className={messageFlagClasses.svgIndex}
                      sx={{ color: irisCustomColors.irisGold }}
                    />
                    <span className={messageFlagClasses.whiteBackground}></span>
                  </Box>
                  <Typography data-testid={'productionRunTestRunInfo'}>
                    <b>{t('data:testRun.testRun')}</b>
                  </Typography>
                </Box>
              </>
            ) : hasTU1Violation || hasCriticalTU1Violation ? (
              <>
                <WarningIcon fontSize="large" />
                <Typography data-testid={'productionRunTU1ViolationInfo'}>
                  <b>{t('data:check.fertigPackVCategories.4')}</b>
                </Typography>
              </>
            ) : (
              (hasAverageWeightViolation || hasCriticalAverageWeightViolation) && (
                <>
                  <WarningIcon fontSize="large"></WarningIcon>
                  <Typography data-testid={'productionRunAverageWeightViolationInfo'}>
                    <b>{'X̅'}</b>
                  </Typography>
                </>
              )
            )}
          </Box>
        </StyledDiv>
      )}
    </>
  );
};
