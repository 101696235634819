import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { InfoboxComponent } from '../../../components/infobox/infobox.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { ContainerOutside } from '../../../components/structure';
import { NewArticle } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  currentArticleSelector,
  fetchArticle,
  fetchLines,
  lineListSelector,
  clearArticleFertigPackTolerances,
  hasFertigPackChecksSelector,
  articleHasFertigPackInputValuesSelector,
  clearChecksForArticle,
  fetchChecksForArticle,
  copyArticle,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { ChecksListComponent } from '../../checks/checks/checks-list.component';

import { ArticleFormComponent } from './article-form/article-form.component';

export const ArticleCopyPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['common', 'data']);

  const currentArticle = useSelector(currentArticleSelector);
  const articleId = id;
  const lines = useSelector(lineListSelector);
  const hasFertigPackChecks = useSelector(hasFertigPackChecksSelector);
  const hasFertigPackInputValues = useSelector(articleHasFertigPackInputValuesSelector);

  const isMatchingLinePresent =
    currentArticle &&
    lines &&
    (currentArticle.productionLine
      ? lines.find((line) => line.id === currentArticle.productionLine!.id)
      : true);

  useEffect(() => {
    articleId && dispatch(fetchArticle(articleId));
    return () => {
      dispatch(clearChecksForArticle());
    };
  }, [dispatch, articleId]);

  useEffect(() => {
    dispatch(fetchLines());
    return () => {
      dispatch(clearArticleFertigPackTolerances());
    };
  }, [dispatch]);

  useEffect(() => {
    articleId && dispatch(fetchChecksForArticle(articleId));
  }, [dispatch, articleId]);

  const submit = (article: NewArticle, image?: File) => {
    dispatch(copyArticle(articleId, article, image));
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.articles}`));
  };

  return (
    <>
      <DetailLayoutComponent
        backLinkText={t('data:article.backToArticles')}
        backLinkTarget={`/${AppRoutePath.articles}`}
        headlineText={t('data:article.new')}
      >
        {isMatchingLinePresent && (
          <>
            {hasFertigPackChecks && !hasFertigPackInputValues && (
              <ContainerOutside sx={{ marginBottom: irisSpacing.container.space }}>
                <InfoboxComponent
                  subline={t('data:article.nominalAndTaraValueMissing')}
                  type={'warning'}
                />
              </ContainerOutside>
            )}
            <ArticleFormComponent
              submit={submit}
              cancel={cancel}
              article={currentArticle}
              lines={lines}
              copy
            />
            <ChecksListComponent
              articleId={currentArticle.id}
              checkPeriodicity={currentArticle.checkPeriodicity}
              copy
            />
          </>
        )}
      </DetailLayoutComponent>
    </>
  );
};
