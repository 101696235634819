import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { ContainerOutsideWithHeader } from '../../../../components/structure';
import {
  Line,
  MeasurementOption,
  NewArticle,
  TareMode,
  TareDeterminationMode,
  MeasurementUnit,
} from '../../../../model';
import {
  calcArticleFertigPackTolerances,
  articleFertigPackTolerancesSelector,
  clearArticleFertigPackTolerances,
} from '../../../../store';

import { ArticleFormBaseDataComponent } from './article-form-base-data.component';
import { ArticleFormBaseSettingsComponent } from './article-form-base-settings.component';
import { ArticleFormNominalCapacityComponent } from './article-form-nominal-capacity.component';
import { ArticleFormRoot } from './article-form.component.styles';

export interface OwnProps {
  article?: NewArticle;
  submit: (article: NewArticle, image?: File) => void;
  cancel: () => void;
  lines: Line[];
  copy?: boolean;
}

export type ArticleFormData = {
  articleName: string;
  articleNameSuffix: string;
  articleNumber: string;
  productionLine: Line | undefined;
  measurementUnit: MeasurementUnit;
  checkPeriodicity: number;
  nominalValue: number | string;
  nominalValueVolume: number | string;
  tareMode: TareMode;
  tareValue: number | string;
  tareSampleSize: number | string;
  tareDeterminationMode: TareDeterminationMode | undefined;
  measurementType: MeasurementOption;
  volumeValue: number | string;
  densityValue: number | string;
  lowerTolerance: number | string;
  upperTolerance: number | string;
  lowerToleranceVolume: number | string;
  upperToleranceVolume: number | string;
  singleTareMaxValue: number | string;
  singleTareMinValue: number | string;
  individualTareMaxValue: number | string;
  individualTareMinValue: number | string;
};

export const getFormValues = (article?: NewArticle): ArticleFormData => ({
  articleName: article?.name || '',
  articleNameSuffix: article?.nameSuffix || '',
  articleNumber: article?.articleNumber || '',
  productionLine: article?.productionLine || undefined,
  measurementUnit: article?.measurementUnit ? article?.measurementUnit : MeasurementUnit.Gram,
  checkPeriodicity: article?.checkPeriodicity || 60,
  nominalValue:
    !article?.volumeValue && article?.nominalValue !== undefined ? article.nominalValue : '',
  nominalValueVolume:
    article?.volumeValue && article?.nominalValue !== undefined ? article.nominalValue : '',
  tareMode: article?.tareMode ? article.tareMode : TareMode.Preset,
  tareValue:
    article?.tareValue !== undefined && article?.tareMode === TareMode.Preset
      ? article.tareValue
      : '',
  tareSampleSize:
    article?.tareSampleSize !== undefined && article?.tareMode === TareMode.Single
      ? article.tareSampleSize
      : '1',
  tareDeterminationMode:
    article?.tareDeterminationMode && article.tareSampleSize
      ? article.tareDeterminationMode
      : TareDeterminationMode.BeforeStart,
  measurementType: article?.volumeValue ? MeasurementOption.Volume : MeasurementOption.Weight,
  volumeValue: article?.volumeValue !== undefined ? article.volumeValue : '',
  densityValue: article?.densityValue !== undefined ? article.densityValue : '',
  lowerTolerance:
    !article?.volumeValue && article?.lowerTolerance !== undefined ? article.lowerTolerance : '',
  upperTolerance:
    !article?.volumeValue && article?.upperTolerance !== undefined ? article.upperTolerance : '',
  lowerToleranceVolume:
    article?.volumeValue && article?.lowerTolerance !== undefined ? article.lowerTolerance : '',
  upperToleranceVolume:
    article?.volumeValue && article?.upperTolerance !== undefined ? article.upperTolerance : '',
  singleTareMaxValue:
    article?.tareMaxValue !== undefined && article?.tareMode === TareMode.Single
      ? article.tareMaxValue
      : '',
  singleTareMinValue:
    article?.tareMinValue !== undefined && article?.tareMode === TareMode.Single
      ? article.tareMinValue
      : '',
  individualTareMaxValue:
    article?.tareMaxValue !== undefined && article?.tareMode === TareMode.OrderedIndividual
      ? article.tareMaxValue
      : '',
  individualTareMinValue:
    article?.tareMinValue !== undefined && article?.tareMode === TareMode.OrderedIndividual
      ? article.tareMinValue
      : '',
});

export const ArticleFormComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { article, copy } = props;
  const { t } = useTranslation(['common', 'form', 'data']);
  const methods = useForm<ArticleFormData>(
    copy
      ? {
          defaultValues: { ...getFormValues(article), articleNumber: '' },
        }
      : { defaultValues: getFormValues(article) }
  );
  const { handleSubmit, reset, watch, getValues } = methods;
  const [imageUrl, setImageUrl] = useState<string | undefined>(article?.imageUrl);
  const [chosenImageFile, setChosenImageFile] = useState<File | undefined>();
  const currentTolerances = useSelector(articleFertigPackTolerancesSelector);
  const currentWeightTolerances = currentTolerances?.weight;
  const currentVolumeTolerances = currentTolerances?.volume;

  const [shouldGetTolerances, setShouldGetTolerances] = useState<boolean>(false);
  const [resetCountTolerances, setResetCountTolerances] = useState<number>(0);

  const {
    nominalValue: currentNominalValue,
    measurementType: measurementTypeWatched,
    densityValue,
    volumeValue,
    nominalValueVolume: currentNominalValueVolume,
    lowerTolerance: currentLowerTolerance,
    lowerToleranceVolume: currentLowerToleranceVolume,
    upperTolerance: currentUpperTolerance,
    upperToleranceVolume: currentUpperToleranceVolume,
    measurementUnit,
  } = watch();

  const measurementType =
    measurementTypeWatched ||
    (article?.volumeValue && MeasurementOption.Volume) ||
    MeasurementOption.Weight;

  useEffect(() => {
    if (
      (shouldGetTolerances ||
        (currentNominalValueVolume !== '' &&
          currentNominalValueVolume &&
          volumeValue !== '' &&
          densityValue !== '')) &&
      measurementType === MeasurementOption.Volume &&
      (measurementUnit === MeasurementUnit.Milliliter || measurementUnit === MeasurementUnit.Liter)
    ) {
      dispatch(
        calcArticleFertigPackTolerances(
          Number(measurementUnit),
          Number(currentNominalValueVolume),
          Number(densityValue)
        )
      );
      setShouldGetTolerances(false);
    } else if (
      shouldGetTolerances ||
      (currentNominalValue &&
        measurementType === MeasurementOption.Weight &&
        (measurementUnit === MeasurementUnit.Gram || measurementUnit === MeasurementUnit.Kilogram))
    ) {
      dispatch(
        calcArticleFertigPackTolerances(Number(measurementUnit), Number(currentNominalValue))
      );
      setShouldGetTolerances(false);
    }
  }, [
    dispatch,
    article,
    measurementType,
    currentNominalValueVolume,
    densityValue,
    currentNominalValue,
    volumeValue,
    shouldGetTolerances,
    measurementUnit,
  ]);

  const removeImage = () => {
    setImageUrl(undefined);
    setChosenImageFile(undefined);
  };

  const onSubmit = handleSubmit((formData: ArticleFormData) => {
    props.submit(
      {
        name: formData.articleName,
        nameSuffix: formData.articleNameSuffix,
        articleNumber: formData.articleNumber,
        productionLine: formData.productionLine || undefined,
        measurementUnit: Number(formData.measurementUnit),
        nominalValue:
          measurementType === MeasurementOption.Weight &&
          currentNominalValue &&
          currentNominalValue !== ''
            ? +currentNominalValue
            : measurementType === MeasurementOption.Volume &&
              currentNominalValueVolume &&
              currentNominalValueVolume !== ''
            ? +currentNominalValueVolume
            : undefined,
        tareMode: Number(formData.tareMode),
        tareValue:
          Number(formData.tareMode) === TareMode.Preset &&
          formData.tareValue &&
          formData.tareValue !== ''
            ? +formData.tareValue
            : undefined,
        tareSampleSize:
          Number(formData.tareMode) === TareMode.Single
            ? Number(formData.tareSampleSize)
            : undefined,
        tareMinValue:
          Number(formData.tareMode) === TareMode.Single &&
          formData.singleTareMinValue &&
          formData.singleTareMinValue !== ''
            ? +formData.singleTareMinValue
            : Number(formData.tareMode) === TareMode.OrderedIndividual &&
              formData.individualTareMinValue &&
              formData.individualTareMinValue !== ''
            ? +formData.individualTareMinValue
            : undefined,
        tareMaxValue:
          Number(formData.tareMode) === TareMode.Single &&
          formData.singleTareMaxValue &&
          formData.singleTareMaxValue !== ''
            ? +formData.singleTareMaxValue
            : Number(formData.tareMode) === TareMode.OrderedIndividual &&
              formData.individualTareMaxValue &&
              formData.individualTareMaxValue !== ''
            ? +formData.individualTareMaxValue
            : undefined,
        volumeValue:
          formData.volumeValue && formData.volumeValue !== '' ? +formData.volumeValue : undefined,
        densityValue:
          formData.densityValue && formData.densityValue !== ''
            ? +formData.densityValue
            : undefined,
        lowerTolerance:
          measurementType === MeasurementOption.Weight &&
          formData.lowerTolerance &&
          formData.lowerTolerance !== ''
            ? +formData.lowerTolerance
            : measurementType === MeasurementOption.Volume &&
              formData.lowerToleranceVolume &&
              formData.lowerToleranceVolume !== ''
            ? +formData.lowerToleranceVolume
            : undefined,
        upperTolerance:
          measurementType === MeasurementOption.Weight &&
          formData.upperTolerance &&
          formData.upperTolerance !== ''
            ? +formData.upperTolerance
            : measurementType === MeasurementOption.Volume &&
              formData.upperToleranceVolume &&
              formData.upperToleranceVolume !== ''
            ? +formData.upperToleranceVolume
            : undefined,
        checkPeriodicity: +formData.checkPeriodicity,
        imageUrl: imageUrl || undefined,
        tareDeterminationMode:
          Number(formData.tareMode) === TareMode.Single
            ? Number(formData.tareDeterminationMode)
            : undefined,
      },
      chosenImageFile
    );
  });

  const getCurrentValues = (): ArticleFormData => ({
    articleName: getValues('articleName'),
    articleNameSuffix: getValues('articleNameSuffix'),
    articleNumber: getValues('articleNumber'),
    productionLine: getValues('productionLine'),
    measurementUnit: getValues('measurementUnit'),
    checkPeriodicity: getValues('checkPeriodicity'),
    nominalValue: getValues('nominalValue'),
    tareMode: getValues('tareMode'),
    nominalValueVolume: getValues('nominalValueVolume'),
    tareValue: getValues('tareValue'),
    tareSampleSize: getValues('tareSampleSize'),
    tareDeterminationMode: getValues('tareDeterminationMode'),
    measurementType: getValues('measurementType'),
    volumeValue: getValues('volumeValue'),
    densityValue: getValues('densityValue'),
    lowerTolerance: getValues('lowerTolerance'),
    upperTolerance: getValues('upperTolerance'),
    lowerToleranceVolume: getValues('lowerToleranceVolume'),
    upperToleranceVolume: getValues('upperToleranceVolume'),
    singleTareMinValue: getValues('singleTareMinValue'),
    singleTareMaxValue: getValues('singleTareMaxValue'),
    individualTareMinValue: getValues('individualTareMinValue'),
    individualTareMaxValue: getValues('individualTareMaxValue'),
  });

  const clearTolerance = () => {
    dispatch(clearArticleFertigPackTolerances());
  };

  const resetTolerances = () => {
    clearTolerance();
    setResetCountTolerances(resetCountTolerances + 1);
    reset({
      ...getCurrentValues(),
      lowerTolerance: '',
      upperTolerance: '',
      lowerToleranceVolume: '',
      upperToleranceVolume: '',
    });
  };

  const lowerTolerance =
    measurementType === MeasurementOption.Weight && currentLowerTolerance !== ''
      ? Number(currentLowerTolerance)
      : measurementType === MeasurementOption.Volume && currentLowerToleranceVolume !== ''
      ? Number(currentLowerToleranceVolume)
      : undefined;
  const upperTolerance =
    measurementType === MeasurementOption.Weight && currentUpperTolerance !== ''
      ? Number(currentUpperTolerance)
      : measurementType === MeasurementOption.Volume && currentUpperToleranceVolume !== ''
      ? Number(currentUpperToleranceVolume)
      : undefined;

  return (
    <FormRoot>
      <ArticleFormRoot>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} className={formClasses.root}>
            <ContainerOutsideWithHeader>
              <ArticleFormBaseDataComponent
                chosenImageFile={chosenImageFile}
                setChosenImageFile={setChosenImageFile}
                removeImage={removeImage}
                lines={props.lines}
                imageUrl={imageUrl}
                article={article}
              />
              <ArticleFormNominalCapacityComponent
                clearTolerance={clearTolerance}
                currentVolumeTolerances={currentVolumeTolerances}
                currentWeightTolerances={currentWeightTolerances}
                resetCountTolerances={resetCountTolerances}
                resetTolerances={resetTolerances}
                setResetCountTolerances={setResetCountTolerances}
                article={article}
                setShouldGetTolerances={setShouldGetTolerances}
              />

              <ArticleFormBaseSettingsComponent
                currentTolerances={currentTolerances}
                measurementType={measurementType}
                lowerTolerance={lowerTolerance}
                upperTolerance={upperTolerance}
              />
            </ContainerOutsideWithHeader>
            <Grid item>
              <Box className={formClasses.buttons}>
                <Button
                  data-testid="cancel-btn"
                  color="secondary"
                  variant="contained"
                  onClick={() => props.cancel()}
                >
                  {t('form:cancel')}
                </Button>
                <Button data-testid="submit-btn" color="primary" type="submit" variant="contained">
                  {t('form:submit')}
                </Button>
              </Box>
            </Grid>
          </form>
        </FormProvider>
      </ArticleFormRoot>
    </FormRoot>
  );
};
