import styled from '@emotion/styled';
import { TableCell } from '@mui/material';

interface CheckResultsTableStyleProps {
  styleProps: { isTableExtended: boolean };
}

export const PrimaryTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<CheckResultsTableStyleProps>(({ theme, styleProps }) => ({
  width: styleProps.isTableExtended ? 'calc(100% / 6)' : '25%',
}));

export const DoublePrimaryTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<CheckResultsTableStyleProps>(({ theme, styleProps }) => ({
  width: styleProps.isTableExtended ? 'calc(100% / 3)' : '50%',
}));

export const SecondaryTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<CheckResultsTableStyleProps>(({ theme, styleProps }) => ({
  width: 'calc(100% / 6)',
  display: styleProps.isTableExtended ? undefined : 'none',
}));
