import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { irisCustomColors } from '../../theme';

export interface OwnProps {
  handleEdit?: () => void;
  disableEdit?: boolean;
  handleDuplicate?: () => void;
  disableDuplicate?: boolean;
  handleMoveUp?: () => void;
  disableMoveUp?: boolean;
  handleMoveDown?: () => void;
  disableMoveDown?: boolean;
  handleDelete?: () => void;
  disabled?: boolean;
}

const PREFIX = 'CommandsMenuComponent';
const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  moreButton: `${PREFIX}-moreButton`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.menuButton}`]: {
    margin: 0,
  },

  [`& .${classes.moreButton}`]: {
    color: irisCustomColors.irisBlack,
    padding: 0,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`&.${classes.root}`]: {
    '& .MuiListItemIcon-root': {
      marginLeft: '0.5rem',
    },
    '& .MuiListItemText-root': {
      marginTop: '0.125rem',
      marginRight: '0.5rem',
      marginBottom: '0.125rem',
      marginLeft: 20,
    },
  },
}));

export const CommandsMenuComponent = (props: OwnProps) => {
  const { t } = useTranslation(['common']);

  const {
    handleEdit,
    disableEdit,
    handleDuplicate,
    disableDuplicate,
    handleMoveUp,
    disableMoveUp,
    handleMoveDown,
    disableMoveDown,
    handleDelete,
    disabled,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      <IconButton
        aria-controls="commands-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.moreButton}
        size="large"
        disabled={disabled}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        className={classes.menuButton}
        id="commands-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {handleEdit && (
          <StyledMenuItem
            divider={!handleDuplicate}
            aria-label={t('common:edit')}
            disabled={disableEdit}
            onClick={() => {
              handleClose();
              handleEdit();
            }}
            classes={{
              root: classes.root,
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:edit')} />
          </StyledMenuItem>
        )}
        {handleDuplicate && (
          <StyledMenuItem
            divider={true}
            aria-label={t('common:copy')}
            disabled={disableDuplicate}
            onClick={() => {
              handleClose();
              handleDuplicate();
            }}
            classes={{
              root: classes.root,
            }}
          >
            <ListItemIcon>
              <CopyIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:copy')} />
          </StyledMenuItem>
        )}

        {handleMoveUp && (
          <StyledMenuItem
            aria-label={t('common:moveUp')}
            disabled={disableMoveUp}
            onClick={() => {
              handleClose();
              handleMoveUp();
            }}
            classes={{
              root: classes.root,
            }}
          >
            <ListItemIcon>
              <ArrowUpIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:moveUp')} />
          </StyledMenuItem>
        )}
        {handleMoveDown && (
          <StyledMenuItem
            divider={true}
            aria-label={t('common:moveDown')}
            disabled={disableMoveDown}
            onClick={() => {
              handleClose();
              handleMoveDown();
            }}
            classes={{
              root: classes.root,
            }}
          >
            <ListItemIcon>
              <ArrowDownIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:moveDown')} />
          </StyledMenuItem>
        )}

        {handleDelete && (
          <StyledMenuItem
            aria-label={t('common:delete')}
            onClick={() => {
              handleClose();
              handleDelete();
            }}
            classes={{
              root: classes.root,
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:delete')} />
          </StyledMenuItem>
        )}
      </Menu>
    </Root>
  );
};
