import { TextField } from '@mui/material';
import React, { ChangeEvent, useCallback, forwardRef } from 'react';

interface OwnProps {
  maxdigits?: number;
  [x: string]: any;
}

const nonNumericPattern: RegExp = /[^0-9]/gi;

export const NumericTextField = forwardRef<HTMLInputElement, OwnProps>((props, ref) => {
  const { onChange, maxdigits, ...otherProps } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.currentTarget.value = e.currentTarget.value.replace(nonNumericPattern, '');
      if (maxdigits && e.currentTarget.value.length > maxdigits)
        e.currentTarget.value = e.currentTarget.value.substring(0, maxdigits);

      onChange && onChange(e);
    },
    [onChange, maxdigits]
  );

  return <TextField ref={ref} {...{ ...otherProps, onChange: handleChange }} />;
});
