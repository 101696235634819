import { Box, Dialog, FormLabel, FormLabelTypeMap, styled } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { irisSpacing } from '../../../theme';

interface StylePropsWrapper {
  styleProps: {
    smallScreen: boolean;
  };
}

export const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  '& .MuiDialog-container': {
    justifyContent: styleProps.smallScreen ? 'flex-start' : 'center',
  },
  '& .MuiDialog-paperWidthMd': {
    minWidth: '900px',
    margin: styleProps.smallScreen ? 0 : '32px',
  },
}));

export const RootForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '&>*': {
    marginBottom: theme.spacing(2),
  },
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  '& button:not(:last-child)': {
    marginRight: theme.spacing(irisSpacing.button.space),
  },
}));

export const SublineFormlabel: OverridableComponent<FormLabelTypeMap<{}, 'label'>> = styled(
  FormLabel
)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  '&.Mui-focused': {
    color: theme.palette.text.primary,
  },
}));

export const MinutesBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  '& .MuiFilledInput-root': {
    width: '127px',
  },
}));
