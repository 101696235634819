import * as React from 'react';

import { dataTableClasses, DataTableRoot } from '../data-table/data-table.styles';

export interface TableData {
  rowName: string;
  rowValue?: string | number;
  'data-testid'?: string;
}

export interface OwnProps {
  tableData: TableData[];
}

export const DescriptionTableComponent = (props: OwnProps) => {
  const { tableData } = props;
  return (
    <DataTableRoot data-testid="DescriptionTableComponent">
      <table className={dataTableClasses.dataTable}>
        <tbody>
          {tableData.map((data) => (
            <tr key={data.rowName}>
              <td>{data.rowName}</td>
              <td data-testid={data['data-testid']}>{data.rowValue ?? '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </DataTableRoot>
  );
};
