import { DisruptionDevice } from './device.model';

export interface ProductionRunEvent {
  id: string;
  eventCode: ProductionRunEventCode;
  iconType: ProductionRunEventType;
  started: string;
  ended?: string;
  description?: string;
  device?: DisruptionDevice;
}

export enum ProductionRunEventCode {
  ManualDisruption = 1,
  FertigPackVTU2Violation = 2,
  FertigPackVTU1Violation = 3,
  ManualPause = 4,
  AverageWeightBelowNominalViolation = 5,
}

export enum ProductionRunEventType {
  Error = 1,
  Warning = 2,
  Pause = 3,
}
