import { Line } from './line.model';

export interface Article extends NewArticle {
  id: string;
  lastModified: string;
}

export interface NewArticle {
  articleNumber: string;
  name: string;
  nameSuffix?: string;
  checkPeriodicity: number;
  productionLine?: Line;
  measurementUnit: MeasurementUnit;
  nominalValue?: number;
  tareMode: TareMode;
  tareValue?: number;
  tareSampleSize?: number;
  tareDeterminationMode?: TareDeterminationMode;
  tareMaxValue?: number;
  tareMinValue?: number;
  volumeValue?: number;
  densityValue?: number;
  lowerTolerance?: number;
  upperTolerance?: number;
  imageUrl?: string;
  thumbnailUrl?: string;
}

export enum TareMode {
  Preset = 1,
  Single = 2,
  OrderedIndividual = 3,
}

export enum TareDeterminationMode {
  BeforeStart = 1,
  BeforeChecks = 2,
}

export enum MeasurementOption {
  Weight = '1',
  Volume = '2',
}

export enum MeasurementUnit {
  Gram = 1,
  Kilogram = 2,
  Milliliter = 3,
  Liter = 4,
}
