import {
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  styled,
  useMediaQuery,
} from '@mui/material';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { CommandsMenuComponent } from '../../../components/commands-menu/commands-menu.component';
import { CenteredImageInTableComponent } from '../../../components/image/centered-image/centered-image.component';
import { TablePaginationComponent } from '../../../components/table-pagination/table-pagination.component';
import { PaperTable, PaperTableCell } from '../../../components/table/paper-table.component';
import { AppRoutePath } from '../../../routes/routes';
import { articleListSelector, deleteArticle, articlesTotalCountSelector } from '../../../store';
import { extendTableBreakpoint } from '../../../theme';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  width: '25%',
}));

const ImageTableCell = styled(PaperTableCell)(({ theme, styleProps }) => ({
  width: '150px',
}));

export interface OwnProps {
  handlePageChange: (newPage: number) => void;
  page: number;
}

export const ArticlesTableComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['table', 'data', 'common']);
  const articles = useSelector(articleListSelector);
  const total = useSelector(articlesTotalCountSelector);
  const isTableExtended = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(extendTableBreakpoint)
  );

  const goToEditArticle = (id: string) => {
    dispatch(push(`/${AppRoutePath.articles}/${id}/${AppRoutePath.edit}`));
  };

  const copyArticleById = (id: string) => {
    dispatch(push(`/${AppRoutePath.articles}/${id}/${AppRoutePath.copy}`));
  };

  const deleteArticleById = (id: string) => {
    dispatch(deleteArticle(id));
  };

  return (
    <>
      <PaperTable dataTestId="articlesTable" ariaLabel={t('data:article.table')}>
        <TableHead>
          <TableRow>
            <ImageTableCell styleProps={{ isHidden: !isTableExtended }}> </ImageTableCell>
            <StyledTableCell>{t('data:article.number')}</StyledTableCell>
            <StyledTableCell>{t('data:article.name')}</StyledTableCell>
            <StyledTableCell>{t('data:article.nameSuffix')}</StyledTableCell>
            <StyledTableCell>{t('data:article.defaultLine')}</StyledTableCell>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {articles && articles.length > 0 ? (
            articles.map((article) => {
              return (
                <TableRow key={article.id}>
                  <ImageTableCell styleProps={{ isHidden: !isTableExtended }}>
                    <CenteredImageInTableComponent
                      imageUrl={article.thumbnailUrl}
                      alttext={t('data:article.image')}
                    />
                  </ImageTableCell>
                  <StyledTableCell>{article.articleNumber}</StyledTableCell>
                  <StyledTableCell>
                    <Link
                      component={NavLink}
                      to={`/${AppRoutePath.articles}/${article.id}/${AppRoutePath.edit}`}
                      underline="hover"
                    >
                      {article.name}
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>{article.nameSuffix}</StyledTableCell>
                  <StyledTableCell>{article.productionLine?.name}</StyledTableCell>
                  <TableCell>
                    <CommandsMenuComponent
                      handleEdit={() => goToEditArticle(article.id)}
                      handleDuplicate={() => copyArticleById(article.id)}
                      handleDelete={() => deleteArticleById(article.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                {articles ? t('table:noMatch') : t('common:noDataAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </PaperTable>
      <TablePaginationComponent
        count={total || 0}
        page={props.page}
        currentItemsCount={articles?.length || 0}
        onChangePage={(event: unknown, newPage: number) => props.handlePageChange(newPage)}
      />
    </>
  );
};
