import { DialogContent, Theme, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Check } from '../../../model';
import {
  editCheckForArticle,
  editableCheckAttributeListSelector,
  fetchCheckAttributes,
  isEditCheckDialogOpenSelector,
} from '../../../store';
import { popOverBreakpoint } from '../../../theme';

import { CheckFormComponent } from './check-form.component';
import { StyledDialog } from './check.styles';

export interface OwnProps {
  handleCancel: () => void;
  checkPeriodicity: number;
  articleId: string;
  check?: Check;
}

export const CheckEditDialogComponent = (props: OwnProps) => {
  const { checkPeriodicity, articleId, check, handleCancel } = props;
  const dispatch = useDispatch();
  const editableCheckAttributes = useSelector(editableCheckAttributeListSelector);
  const isEditCheckDialogOpen = useSelector(isEditCheckDialogOpenSelector);

  useEffect(() => {
    check && dispatch(fetchCheckAttributes());
  }, [dispatch, check]);

  const submit = (editedCheck: Omit<Check, 'id' | 'lastModified'>) => {
    check && dispatch(editCheckForArticle(articleId, { ...editedCheck, id: check.id }));
  };

  const cancel = () => {
    handleCancel();
  };

  const smallScreen = !useMediaQuery((theme: Theme) => theme.breakpoints.up(popOverBreakpoint));

  return (
    <>
      {check &&
        editableCheckAttributes &&
        editableCheckAttributes.find(
          (checkAttribute) => checkAttribute.id === check.checkAttribute.id
        ) && (
          <StyledDialog styleProps={{ smallScreen }} open={isEditCheckDialogOpen} maxWidth="md">
            <DialogContent>
              <CheckFormComponent
                submit={submit}
                cancel={cancel}
                articleCheckPeriodicity={checkPeriodicity}
                checkAttributes={editableCheckAttributes || []}
                check={check}
              ></CheckFormComponent>
            </DialogContent>
          </StyledDialog>
        )}
    </>
  );
};
