import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ContainerInside, ContainerOutside } from '../../../../components/structure';
import { CheckAttribute, CheckAttributeType } from '../../../../model';
import {
  checkAttributeTypeListSelector,
  fetchCheckAttributeTypes,
} from '../../../../store/check-attribute-types';

import { getCheckAttributeFormComponent } from './check-attribute.component';

const PREFIX = 'CheckAttributeSelectComponent';
const classes = {
  cancelButton: `${PREFIX}-cancelButton`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.cancelButton}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    height: 50,
  },
}));

export interface CheckAttributeImages {
  yesNoImage?: File;
  trafficLightGreenImage?: File;
  trafficLightYellowImage?: File;
  trafficLightRedImage?: File;
  freeTextImage?: File;
}

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (
    checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
    images?: CheckAttributeImages
  ) => void;
  cancel: () => void;
}

export const CheckAttributeSelectComponent = (props: OwnProps) => {
  const { checkAttribute } = props;
  const { t } = useTranslation(['data', 'form']);
  const dispatch = useDispatch();
  const checkAttributeTypes = useSelector(checkAttributeTypeListSelector);

  useEffect(() => {
    dispatch(fetchCheckAttributeTypes());
  }, [dispatch]);

  const [checkAttributeType, setCheckAttributeType] = useState<CheckAttributeType | ''>(
    checkAttribute?.checkAttributeType || ''
  );
  const isInitialCheckAttributeType =
    Number(checkAttributeType) === checkAttribute?.checkAttributeType;
  const formProps = {
    ...props,
    checkAttribute: isInitialCheckAttributeType ? props.checkAttribute : undefined,
  };

  const checkAttributeFormProps = {
    ...formProps,
    checkAttributeType,
  };

  return (
    <ContainerOutside>
      <Root>
        <FormRoot>
          <Box className={formClasses.root}>
            <Paper>
              <ContainerInside>
                <GridContainer level={GridLevel.InputPaper}>
                  <GridItem s={6} xl={4}>
                    <FormControl fullWidth={true} disabled={!!checkAttribute}>
                      <InputLabel id="checkAttributeType-label" variant="outlined">
                        {t('data:checkAttribute.checkAttributeType')}
                      </InputLabel>
                      <Select
                        labelId="checkAttributeType-label"
                        label={t('data:checkAttribute.checkAttributeType')}
                        variant="outlined"
                        value={
                          checkAttributeTypes && checkAttributeTypes.length
                            ? checkAttributeType
                            : ''
                        }
                        onChange={(event) => setCheckAttributeType(event.target.value as number)}
                        data-testid="select-checkAttributeType"
                      >
                        {checkAttributeTypes &&
                          checkAttributeTypes.map((typeId) => (
                            <MenuItem
                              value={typeId}
                              key={typeId}
                              data-testid={`checkAttributeType${typeId}`}
                            >
                              {t([
                                `data:checkAttribute.checkAttributeTypeCodes.${typeId}`,
                                `data:checkAttribute.checkAttributeTypeCodes.notFound`,
                              ])}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </Box>
          {checkAttributeType ? (
            getCheckAttributeFormComponent(checkAttributeFormProps)
          ) : (
            <GridItem>
              <Box className={classes.cancelButton}>
                <Button
                  data-testid="cancel-btn"
                  color="secondary"
                  variant="contained"
                  onClick={() => props.cancel()}
                >
                  {t('form:cancel')}
                </Button>
              </Box>
            </GridItem>
          )}
        </FormRoot>
      </Root>
    </ContainerOutside>
  );
};
