import { Paper, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { CheckAttribute, CheckAttributeSpecification, CheckAttributeType } from '../../../../model';

import { ButtonsFormComponent } from './buttons-form.component';
import { DescriptionFrameComponent } from './description-frame.component';
import { SampleSizeComponent } from './sample-size.component';

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>) => void;
  cancel: () => void;
}

type FormData = {
  checkAttributeName: string;
  specification: CheckAttributeSpecification | '';
  fertigPackVDescription: string;
  sampleSize: number;
};

const getFormValues = (checkAttribute?: CheckAttribute): FormData => ({
  checkAttributeName: checkAttribute?.name || '',
  specification: checkAttribute?.specification || '',
  fertigPackVDescription: checkAttribute?.fertigPackVDescription || '',
  sampleSize: checkAttribute?.sampleSize || 1,
});

export const FertigPackVFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);
  const { checkAttribute } = props;
  const methods = useForm<FormData>({
    defaultValues: getFormValues(checkAttribute),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit({
      checkAttributeType: CheckAttributeType.FertigPackV,
      name: formData.checkAttributeName,
      specification: CheckAttributeSpecification.Weight,
      sampleSize: Number(formData.sampleSize),
      fertigPackVDescription: formData.fertigPackVDescription,
    });
  });

  return (
    <FormRoot>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className={formClasses.root}>
          <ContainerOutsideWithHeader>
            <Typography variant="h2">{t('data:checkAttribute.description')}</Typography>
            <Paper>
              <ContainerInside>
                <GridContainer>
                  <GridItem>
                    <GridContainer level={GridLevel.InputPaper}>
                      <DescriptionFrameComponent
                        checkAttributeType={CheckAttributeType.FertigPackV}
                      />
                      <GridItem s={12} xl={8}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth={true}
                              label={t('data:checkAttribute.fertigPackVDescription')}
                              inputProps={{
                                'aria-label': t('data:checkAttribute.fertigPackVDescription'),
                                'data-testid': 'fertigPackVDescription-input',
                              }}
                              error={errors.fertigPackVDescription !== undefined}
                              helperText={
                                errors.fertigPackVDescription &&
                                errors.fertigPackVDescription.message
                              }
                            />
                          )}
                          control={control}
                          defaultValue={''}
                          name="fertigPackVDescription"
                          rules={{
                            maxLength: { value: 256, message: t('form:maxLength', { max: '256' }) },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </ContainerOutsideWithHeader>
          <SampleSizeComponent />
          <GridItem>
            <ButtonsFormComponent cancel={props.cancel} />
          </GridItem>
        </form>
      </FormProvider>
    </FormRoot>
  );
};
