import { produce } from 'immer';
import { Action } from 'redux';

import { Line } from '../../model';
import { addItemToFirstPositionInArray, deleteItemFromArray } from '../reducer-utils';

import {
  LinesActionType,
  fetchLinesSuccess,
  fetchLineSuccess,
  addLineSuccess,
  editLineSuccess,
  deleteLineSuccess,
} from './lines.actions';

export interface LinesState {
  fetchingAll?: boolean;
  lines?: Line[];
  total?: number;
  currentLine?: Line;
}

export const getInitialState = (): LinesState => {
  return {};
};

export const linesReducer = (
  previousState: LinesState = getInitialState(),
  action: Action<LinesActionType>
) => {
  let nextState;

  switch (action.type) {
    case LinesActionType.linesFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = true;
      });
      break;
    case LinesActionType.linesFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchLinesSuccess>;
        draftState.lines = fetchSuccessAction.payload.lines;
        draftState.total = fetchSuccessAction.payload.total;
        draftState.fetchingAll = false;
      });
      break;
    case LinesActionType.linesFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = false;
      });
      break;
    case LinesActionType.lineFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentLine;
      });
      break;
    case LinesActionType.lineFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchLineSuccess>;
        draftState.currentLine = fetchSuccessAction.payload.line;
      });
      break;
    case LinesActionType.lineAddSuccess:
      nextState = produce(previousState, (draftState) => {
        const addSuccessAction = action as ReturnType<typeof addLineSuccess>;
        draftState.lines = addItemToFirstPositionInArray(
          draftState.lines,
          addSuccessAction.payload.line
        );
        draftState.total = draftState.total ? draftState.total + 1 : 1;
      });
      break;
    case LinesActionType.lineEditSuccess:
      nextState = produce(previousState, (draftState) => {
        const editSuccessAction = action as ReturnType<typeof editLineSuccess>;
        draftState.lines = addItemToFirstPositionInArray(
          draftState.lines,
          editSuccessAction.payload.line
        );
      });
      break;
    case LinesActionType.lineDeleteSuccess:
      nextState = produce(previousState, (draftState) => {
        const deleteSuccessAction = action as ReturnType<typeof deleteLineSuccess>;
        if (!draftState.lines) {
          return;
        }
        draftState.lines = deleteItemFromArray(draftState.lines, deleteSuccessAction.payload.id);
        draftState.total = draftState.total ? draftState.total - 1 : 0;
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
