import { produce } from 'immer';
import { Action } from 'redux';

import { InternalErrorCode } from '../../http/http-error-codes';
import { User } from '../../model';
import { addItemToFirstPositionInArray, deleteItemFromArray } from '../reducer-utils';

import {
  UsersActionType,
  fetchUsersSuccess,
  fetchUserSuccess,
  addUserSuccess,
  addUserFailure,
  editUserSuccess,
  editUserFailure,
  deleteUserSuccess,
  resetUserPasswordSuccess,
} from './users.actions';

export interface UsersState {
  fetchingAll?: boolean;
  users?: User[];
  total?: number;
  currentUser?: User;
  currentUserTempPassword?: string;
  usernameAlreadyInUse: boolean;
  requestResetPasswordWasFailed?: boolean;
}

export const getInitialState = (): UsersState => {
  return { usernameAlreadyInUse: false };
};

export const usersReducer = (
  previousState: UsersState = getInitialState(),
  action: Action<UsersActionType>
) => {
  let nextState;

  switch (action.type) {
    case UsersActionType.usersFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = true;
      });
      break;
    case UsersActionType.usersFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchUsersSuccess>;
        draftState.users = fetchSuccessAction.payload.users;
        draftState.total = fetchSuccessAction.payload.total;
        draftState.fetchingAll = false;
      });
      break;
    case UsersActionType.usersFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = false;
      });
      break;
    case UsersActionType.userFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentUser;
        draftState.usernameAlreadyInUse = false;
      });
      break;
    case UsersActionType.userFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchUserSuccess>;
        draftState.currentUser = fetchSuccessAction.payload.user;
      });
      break;
    case UsersActionType.userAddSuccess:
      nextState = produce(previousState, (draftState) => {
        const addSuccessAction = action as ReturnType<typeof addUserSuccess>;
        draftState.users = addItemToFirstPositionInArray(
          draftState.users,
          addSuccessAction.payload.user
        );
        draftState.total = draftState.total ? draftState.total + 1 : 1;
      });
      break;
    case UsersActionType.userEditSuccess:
      nextState = produce(previousState, (draftState) => {
        const editSuccessAction = action as ReturnType<typeof editUserSuccess>;
        draftState.users = addItemToFirstPositionInArray(
          draftState.users,
          editSuccessAction.payload.user
        );
      });
      break;
    case UsersActionType.userResetPasswordSuccess:
      nextState = produce(previousState, (draftState) => {
        const resetSuccessAction = action as ReturnType<typeof resetUserPasswordSuccess>;
        draftState.currentUserTempPassword = resetSuccessAction.payload.password;
      });
      break;
    case UsersActionType.userAddFailure:
    case UsersActionType.userEditFailure:
      nextState = produce(previousState, (draftState) => {
        const failureAction = action as ReturnType<typeof addUserFailure | typeof editUserFailure>;
        const { e } = failureAction.payload;
        if (e.internalErrorCode === InternalErrorCode.UsernameAlreadyUsed) {
          draftState.usernameAlreadyInUse = true;
        }
      });
      break;
    case UsersActionType.userDeleteSuccess:
      nextState = produce(previousState, (draftState) => {
        const deleteSuccessAction = action as ReturnType<typeof deleteUserSuccess>;
        if (!draftState.users) {
          return;
        }
        draftState.users = deleteItemFromArray(draftState.users, deleteSuccessAction.payload.id);
        draftState.total = draftState.total ? draftState.total - 1 : 0;
      });
      break;
    case UsersActionType.userTempPasswordClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentUserTempPassword;
      });
      break;
    case UsersActionType.userUsernameAlreadyInUseClear:
      nextState = produce(previousState, (draftState) => {
        draftState.usernameAlreadyInUse = false;
      });
      break;
    case UsersActionType.userRequestResetPassword:
    case UsersActionType.userRequestResetPasswordFailedClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.requestResetPasswordWasFailed;
      });
      break;
    case UsersActionType.userRequestResetPasswordFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.requestResetPasswordWasFailed = true;
      });
      break;
    case UsersActionType.userRequestResetPasswordSuccess:
      nextState = produce(previousState, (draftState) => {
        draftState.requestResetPasswordWasFailed = false;
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
