import { produce } from 'immer';
import { Action } from 'redux';

import { CheckAttribute } from '../../model';

import {
  addItemToArray,
  addItemToFirstPositionInArray,
  deleteItemFromArray,
} from './../reducer-utils';
import {
  CheckAttributesActionType,
  fetchCheckAttributesSuccess,
  fetchCheckAttributeSuccess,
  addCheckAttributeSuccess,
  editCheckAttributeSuccess,
  deleteCheckAttributeSuccess,
  duplicateCheckAttributeSuccess,
} from './check-attributes.actions';

export interface CheckAttributesState {
  fetchingAll?: boolean;
  checkAttributes?: CheckAttribute[];
  total?: number;
  currentCheckAttribute?: CheckAttribute;
}

export const getInitialState = (): CheckAttributesState => {
  return {};
};

export const checkAttributesReducer = (
  previousState: CheckAttributesState = getInitialState(),
  action: Action<CheckAttributesActionType>
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case CheckAttributesActionType.checkAttributesFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = true;
      });
      break;
    case CheckAttributesActionType.checkAttributesFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchCheckAttributesSuccess>;
        draftState.checkAttributes = fetchSuccessAction.payload.checkAttributes;
        draftState.total = fetchSuccessAction.payload.total;
        draftState.fetchingAll = false;
      });
      break;
    case CheckAttributesActionType.checkAttributesFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = false;
      });
      break;
    case CheckAttributesActionType.checkAttributeFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentCheckAttribute;
      });
      break;
    case CheckAttributesActionType.checkAttributeFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchCheckAttributeSuccess>;
        draftState.currentCheckAttribute = fetchSuccessAction.payload.checkAttribute;
      });
      break;
    case CheckAttributesActionType.checkAttributeAddSuccess:
      nextState = produce(previousState, (draftState) => {
        const addSuccessAction = action as ReturnType<typeof addCheckAttributeSuccess>;
        draftState.checkAttributes = addItemToArray(
          draftState.checkAttributes,
          addSuccessAction.payload.checkAttribute
        );
        draftState.total = draftState.total ? draftState.total + 1 : 1;
      });
      break;
    case CheckAttributesActionType.checkAttributeEditSuccess:
      nextState = produce(previousState, (draftState) => {
        const editSuccessAction = action as ReturnType<typeof editCheckAttributeSuccess>;
        draftState.checkAttributes = addItemToArray(
          draftState.checkAttributes,
          editSuccessAction.payload.checkAttribute
        );
      });
      break;
    case CheckAttributesActionType.checkAttributeDeleteSuccess:
      nextState = produce(previousState, (draftState) => {
        const deleteSuccessAction = action as ReturnType<typeof deleteCheckAttributeSuccess>;
        draftState.checkAttributes = deleteItemFromArray(
          draftState.checkAttributes,
          deleteSuccessAction.payload.id
        );
        draftState.total = draftState.total ? draftState.total - 1 : 0;
      });
      break;
    case CheckAttributesActionType.checkAttributeDuplicateSuccess:
      const duplicateSuccessAction = action as ReturnType<typeof duplicateCheckAttributeSuccess>;
      nextState = produce(previousState, (draftState) => {
        draftState.checkAttributes = addItemToFirstPositionInArray(
          draftState.checkAttributes,
          duplicateSuccessAction.payload.checkAttribute
        );
        if (draftState.total) draftState.total = draftState.total + 1;
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
