import { Paper, Table, TableCell, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

import { irisContainerColors, irisCustomColors } from '../../theme';

const PREFIX = 'PaperTable';
const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    borderCollapse: 'separate',
    minWidth: 600,
    '& thead tr th': {
      minHeight: 60,
      height: 60,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(3),
      fontSize: '1rem',
    },
    '& tbody tr td': {
      minHeight: 60,
      height: 60,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(3),
      verticalAlign: 'top',
    },
  },
  [`& .${classes.container}`]: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderBottomWidth: '0px',
    borderColor: irisContainerColors.tableBorder,
  },
}));

export const PaperTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<PaperCellProps>(({ theme, styleProps }) => ({
  width: styleProps.width,
  display: styleProps.isHidden ? 'none' : undefined,
}));

interface PaperCellProps {
  styleProps: { isHidden?: boolean; width?: string };
}

export interface OwnProps {
  children: React.ReactNode;
  ariaLabel?: string;
  dataTestId?: string;
  forPrint?: boolean;
}

export const PaperTable = (props: OwnProps) => {
  return (
    <Root data-testid={props.dataTestId}>
      <TableContainer
        aria-label={props.ariaLabel}
        className={classes.container}
        component={Paper}
        style={
          props.forPrint
            ? {
                boxShadow: 'none',
                borderTop: 'solid',
                borderColor: irisCustomColors.irisGray,
                borderWidth: '1px',
              }
            : undefined
        }
      >
        <Table className={classes.root}>{props.children}</Table>
      </TableContainer>
    </Root>
  );
};
