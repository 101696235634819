import { Box, Container, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import background from '../../../assets/img/iris-login-background.png';
import logoBlue from '../../../assets/img/iris-logo-blue.png';
import { LicenseInfoComponent } from '../../license-info/license-info.component';

const PREFIX = 'StartScreenLayoutComponent';
const classes = {
  paper: `${PREFIX}-paper`,
  paperBox: `${PREFIX}-paperBox`,
  logoImage: `${PREFIX}-logoImage`,
  backgroundImageContainer: `${PREFIX}-backgroundImageContainer`,
  backgroundImage: `${PREFIX}-backgroundImage`,
  container: `${PREFIX}-container`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    width: '450px',
  },
  [`& .${classes.paperBox}`]: {
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
  },
  [`& .${classes.logoImage}`]: {
    marginTop: '-106px',
  },
  [`& .${classes.backgroundImageContainer}`]: {
    marginTop: '-106px',
    opacity: '30%',
    position: 'absolute',
    left: '5%',
    bottom: '5%',
    zIndex: -1,
    width: '70%',
  },
  [`& .${classes.backgroundImage}`]: {
    width: 'inherit',
    height: 'inherit',
    maxWidth: '90%',
    maxHeight: '90%',
  },
  [`&.${classes.container}`]: {
    justifyContent: 'center',
    display: 'flex',
    height: '100vh',
  },
}));

export interface OwnProps {
  children: React.ReactNode;
}

export const StartScreenLayoutComponent = (props: OwnProps) => {
  const { t } = useTranslation(['common']);

  return (
    <StyledContainer className={classes.container}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box mb={3} className={classes.backgroundImageContainer}>
          <img
            className={classes.backgroundImage}
            src={background}
            alt={t('common:backgroundImage')}
          />
        </Box>
        <Box mb={3} className={classes.logoImage}>
          <img src={logoBlue} alt={t('common:iris')} />
        </Box>
        <Paper className={classes.paper}>
          <Box className={classes.paperBox}>{props.children}</Box>
        </Paper>
        <LicenseInfoComponent />
      </Box>
    </StyledContainer>
  );
};
