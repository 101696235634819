import WarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Link,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { DurationInfoComponent } from '../../../components/duration-info/duration-info.component';
import { CenteredImageInTableComponent } from '../../../components/image/centered-image/centered-image.component';
import { TablePaginationComponent } from '../../../components/table-pagination/table-pagination.component';
import { PaperTable } from '../../../components/table/paper-table.component';
import { hasIssue } from '../../../helper';
import { IssueCode } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  productionRunsFinishedListSelector,
  productionRunsFinishedTotalCountSelector,
  startWsConnection,
  productionRunsWebsocketConfig,
} from '../../../store';
import { ProductionRunBarChartComponent } from '../production-run/production-run-bar-chart.component';

export interface OwnProps {
  handlePageChange: (newPage: number) => void;
  page: number;
}

const StyledWarningBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));

const StyledWarningText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  fontWeight: 'bold',
  fontSize: '18px',
}));

const ViolationWarning = (caption: string) => {
  return (
    <StyledWarningBox data-testid="warningBox">
      <WarningIcon fontSize="large" color="error"></WarningIcon>
      <StyledWarningText data-testid="violationCaption">{caption}</StyledWarningText>
    </StyledWarningBox>
  );
};

export const ProductionRunsFinishedTableComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'table', 'data']);
  const productionRuns = useSelector(productionRunsFinishedListSelector);
  const total = useSelector(productionRunsFinishedTotalCountSelector);
  const theme = useTheme();
  const isXlOrLarger = useMediaQuery(theme.breakpoints.up('xl'));

  useEffect(() => {
    dispatch(startWsConnection(productionRunsWebsocketConfig));
  }, [dispatch]);

  const PrimaryTableCell = styled(TableCell)(({ theme }) => ({
    width: isXlOrLarger ? '16.7%' : '25%',
  }));

  const SecondaryTableCell = styled(TableCell)(({ theme }) => ({
    width: isXlOrLarger ? '16.7%' : '0px',
    display: isXlOrLarger ? undefined : 'none',
  }));

  const SecondaryImageTableCell = styled(SecondaryTableCell)(({ theme }) => ({
    width: isXlOrLarger ? '150px' : '0px',
  }));

  return (
    <>
      <PaperTable
        dataTestId="productionRunsFinishedTable"
        ariaLabel={t('data:productionRun.table')}
      >
        <TableHead>
          <TableRow>
            <SecondaryImageTableCell> </SecondaryImageTableCell>
            <PrimaryTableCell>{t('data:productionOrder.number')}</PrimaryTableCell>
            <PrimaryTableCell>{t('data:productionOrder.line')}</PrimaryTableCell>
            <PrimaryTableCell>{t('data:article.article')}</PrimaryTableCell>
            <SecondaryTableCell>{t('data:productionOrder.finished')}</SecondaryTableCell>
            <SecondaryTableCell>{t('data:productionOrder.batchNumber')}</SecondaryTableCell>
            <PrimaryTableCell>{t('data:productionOrder.results')}</PrimaryTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productionRuns && productionRuns.length > 0 ? (
            productionRuns.map((productionRun) => {
              return (
                <TableRow key={productionRun.id}>
                  <SecondaryImageTableCell>
                    <CenteredImageInTableComponent
                      imageUrl={productionRun.article.thumbnailUrl}
                      alttext={t('data:article.image')}
                    />
                  </SecondaryImageTableCell>
                  <PrimaryTableCell>
                    <Link
                      component={NavLink}
                      to={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.finished}/${productionRun.id}`}
                      underline="hover"
                    >
                      {productionRun.orderNumber}
                    </Link>
                  </PrimaryTableCell>
                  <PrimaryTableCell>{productionRun.article.productionLine?.name}</PrimaryTableCell>
                  <PrimaryTableCell>{productionRun.article.name}</PrimaryTableCell>
                  <SecondaryTableCell>
                    {' '}
                    <DurationInfoComponent
                      startDate={new Date(productionRun.started)}
                      endDate={new Date(productionRun.finished!)}
                    ></DurationInfoComponent>
                  </SecondaryTableCell>
                  <SecondaryTableCell>
                    {productionRun.batchNumber ? productionRun.batchNumber : '-'}
                  </SecondaryTableCell>
                  <PrimaryTableCell>
                    {hasIssue(productionRun, IssueCode.FertigPackVTU2Violation) ? (
                      ViolationWarning(t('data:productionRun.tu2.violation'))
                    ) : hasIssue(productionRun, IssueCode.FertigPackVTU1Violation) ? (
                      ViolationWarning(t('data:productionRun.tu1.violationWarning'))
                    ) : hasIssue(productionRun, IssueCode.AverageWeightBelowNominal) ? (
                      ViolationWarning(
                        t('data:productionRun.averageWeightBelowNominal.violationWarning')
                      )
                    ) : (
                      <ProductionRunBarChartComponent
                        productionRun={productionRun}
                      ></ProductionRunBarChartComponent>
                    )}
                  </PrimaryTableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={2}>
                {productionRuns ? t('table:noMatch') : t('common:noDataAvailable')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </PaperTable>
      <TablePaginationComponent
        currentItemsCount={productionRuns?.length || 0}
        count={total || 0}
        page={props.page}
        onChangePage={(event: unknown, newPage: number) => props.handlePageChange(newPage)}
      />
    </>
  );
};
