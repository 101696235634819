import { Grid } from '@mui/material';
import React from 'react';

export type GridItemCol = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface OwnProps {
  children?: React.ReactNode;
  s?: GridItemCol;
  m?: GridItemCol;
  xl?: GridItemCol;
  id?: React.Key;
  'data-testid'?: string;
}

export const GridItem = (props: OwnProps) => {
  const { children, s, m, xl, id, ...rest } = props;

  const sCols = s ? s : 12;
  const mCols = m ? m : sCols;
  const xlCols = xl ? xl : mCols;

  return (
    <Grid item key={id} xs={sCols} sm={sCols} md={mCols} lg={mCols} xl={xlCols} {...rest}>
      {children}
    </Grid>
  );
};
