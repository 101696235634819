import { produce } from 'immer';
import { Action } from 'redux';

import { Article } from '../../model';
import { addItemToFirstPositionInArray, deleteItemFromArray } from '../reducer-utils';

import { ToleranceResult } from './../../model/tolerance-result.model';
import {
  ArticlesActionType,
  fetchArticlesSuccess,
  fetchArticleSuccess,
  addArticleSuccess,
  editArticleSuccess,
  deleteArticleSuccess,
  copyArticleSuccess,
  calcArticleFertigPackTolerancesSuccess,
  changeArticleFertigPackInputValues,
} from './articles.actions';

export interface ArticlesState {
  fetchingAll?: boolean;
  articles?: Article[];
  total?: number;
  currentArticle?: Article;
  currentFertigPackVTolerances?: ToleranceResult;
  hasFertigPackInputValues: boolean;
}

export const getInitialState = (): ArticlesState => {
  return { hasFertigPackInputValues: false };
};
const clearArticleInState = (previousState: ArticlesState) => {
  return produce(previousState, (draftState) => {
    delete draftState.currentArticle;
  });
};

const clearArticleFertigPackTolerancesInState = (previousState: ArticlesState) => {
  return produce(previousState, (draftState) => {
    delete draftState.currentFertigPackVTolerances;
  });
};

export const articlesReducer = (
  previousState: ArticlesState = getInitialState(),
  action: Action<ArticlesActionType>
) => {
  let nextState;

  switch (action.type) {
    case ArticlesActionType.articlesFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = true;
      });
      break;
    case ArticlesActionType.articlesFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchArticlesSuccess>;
        draftState.articles = fetchSuccessAction.payload.articles;
        draftState.total = fetchSuccessAction.payload.total;
        draftState.fetchingAll = false;
      });
      break;
    case ArticlesActionType.articlesFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAll = false;
      });
      break;
    case ArticlesActionType.articleFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentArticle;
        delete draftState.currentFertigPackVTolerances;
      });
      break;
    case ArticlesActionType.articleFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchArticleSuccess>;
        draftState.currentArticle = fetchSuccessAction.payload.article;
      });
      break;
    case ArticlesActionType.articleAddSuccess:
      nextState = produce(previousState, (draftState) => {
        const addSuccessAction = action as ReturnType<typeof addArticleSuccess>;
        draftState.articles = addItemToFirstPositionInArray(
          draftState.articles,
          addSuccessAction.payload.article
        );
        draftState.total = draftState.total ? draftState.total + 1 : 1;
      });
      break;
    case ArticlesActionType.articleEditSuccess:
      nextState = produce(previousState, (draftState) => {
        const editSuccessAction = action as ReturnType<typeof editArticleSuccess>;
        draftState.articles = addItemToFirstPositionInArray(
          draftState.articles,
          editSuccessAction.payload.article
        );
      });
      break;
    case ArticlesActionType.articleDeleteSuccess:
      nextState = produce(previousState, (draftState) => {
        const deleteSuccessAction = action as ReturnType<typeof deleteArticleSuccess>;
        if (!draftState.articles) {
          return;
        }
        draftState.articles = deleteItemFromArray(
          draftState.articles,
          deleteSuccessAction.payload.id
        );
        draftState.total = draftState.total ? draftState.total - 1 : 0;
      });
      break;
    case ArticlesActionType.articleCopySuccess:
      nextState = produce(previousState, (draftState) => {
        const copySuccessAction = action as ReturnType<typeof copyArticleSuccess>;
        draftState.articles = addItemToFirstPositionInArray(
          draftState.articles,
          copySuccessAction.payload.article
        );
        draftState.total = draftState.total ? draftState.total + 1 : 1;
      });
      break;
    case ArticlesActionType.articleCurrentClear:
      nextState = clearArticleInState(previousState);
      break;
    case ArticlesActionType.articleCalcFertigPackTolerancesSuccess:
      const tolerancesAction = action as ReturnType<typeof calcArticleFertigPackTolerancesSuccess>;
      nextState = produce(previousState, (draftstate) => {
        draftstate.currentFertigPackVTolerances = tolerancesAction.payload.tolerances;
      });
      break;
    case ArticlesActionType.articleFertigPackTolerancesClear:
      nextState = clearArticleFertigPackTolerancesInState(previousState);
      break;
    case ArticlesActionType.articleFertigPackInputValuesChange:
      const changeAction = action as ReturnType<typeof changeArticleFertigPackInputValues>;
      if (previousState.hasFertigPackInputValues !== changeAction.payload.hasInputValues) {
        nextState = produce(previousState, (draftState) => {
          draftState.hasFertigPackInputValues = changeAction.payload.hasInputValues;
        });
      } else {
        nextState = previousState;
      }

      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
