import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { RoleInfoComponent } from '../../../components/role-info/role-info.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../components/structure';
import { User, UserRole } from '../../../model';

import { UserAccessDataComponent } from './user-access-data.component';

const PREFIX = 'UserFormComponent';
const classes = {
  roleContainer: `${PREFIX}-roleContainer`,
  radioContainer: `${PREFIX}-radioContainer`,
  radioInfo: `${PREFIX}-radioInfo`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.roleContainer}`]: {
    display: 'flex',
  },

  [`& .${classes.radioContainer}`]: {
    display: 'flex',
  },

  [`& .${classes.radioInfo}`]: {
    paddingLeft: '31px',
  },
}));

export interface OwnProps {
  user?: User;
  submit: (user: Omit<User, 'id' | 'lastModified' | 'passwordResetRequested'>) => void;
  cancel: () => void;
}

type FormData = {
  username: string;
  firstName: string;
  lastName: string;
  workspace: string;
  role: UserRole;
};
const getFormValues = (user?: User): FormData => ({
  username: user?.username || '',
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  workspace: user?.workspace || '',
  role: user?.role || UserRole.Production,
});

export const UserFormComponent = (props: OwnProps) => {
  const { user } = props;
  const { t } = useTranslation(['form', 'data']);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormData>({
    defaultValues: getFormValues(user),
  });
  const [selectedRole, setSelectedRole] = useState<UserRole>(user?.role || UserRole.Production);

  React.useEffect(() => {
    // wait for data to be loaded and reset default values
    user && reset(getFormValues(user));
  }, [user, reset]);

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit({
      username: formData.username,
      firstName: formData.firstName,
      lastName: formData.lastName,
      workspace: formData.workspace,
      role: Number(formData.role),
    });
  });

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(Number(event.target.value));
  };

  const getRoleInfo = (role: UserRole) => {
    let roleName;
    let permissionCount;
    switch (role) {
      case UserRole.Admin:
        roleName = 'administrator';
        permissionCount = 1;
        break;
      case UserRole.Supervisor:
        roleName = 'supervisor';
        permissionCount = 6;
        break;
      case UserRole.Production:
        roleName = 'production';
        permissionCount = 2;
        break;
      default:
        return <></>;
    }

    let jsxElements = [];
    for (let i = 1; i <= permissionCount; i++) {
      jsxElements.push(
        <RoleInfoComponent key={i} infoText={t(`data:user:permissions:${roleName}.${i}`)} />
      );
    }

    return jsxElements;
  };

  const validateUsername = (username: string) => {
    const isLowercase = username.toLowerCase() === username;
    if (!isLowercase) return t('form:noUpperCase');

    const hasSpaces = username.includes(' ');
    if (hasSpaces) return t('form:noSpaces');

    return true;
  };

  return (
    <FormRoot>
      <Root>
        <form onSubmit={onSubmit} className={formClasses.root}>
          <ContainerOutsideWithHeader>
            <Paper>
              <ContainerInside>
                <GridContainer level={GridLevel.InputPaper}>
                  <GridItem>
                    <GridContainer level={GridLevel.InputPaper}>
                      <GridItem s={6} xl={4}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth={true}
                              required
                              label={t('data:user.username')}
                              inputProps={{ 'aria-label': t('data:user.username') }}
                              error={errors.username !== undefined}
                              helperText={errors.username && errors.username.message}
                            />
                          )}
                          control={control}
                          name="username"
                          rules={{
                            required: { value: true, message: t('form:fieldIsRequired') },
                            validate: (value: string) => validateUsername(value),
                            minLength: { value: 1, message: t('form:minLength', { min: '1' }) },
                            maxLength: { value: 30, message: t('form:maxLength', { max: '30' }) },
                          }}
                        />
                      </GridItem>
                      <GridItem s={6} xl={4}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth={true}
                              required
                              label={t('data:user.firstName')}
                              inputProps={{ 'aria-label': t('data:user.firstName') }}
                              error={errors.firstName !== undefined}
                              helperText={errors.firstName && errors.firstName.message}
                            />
                          )}
                          control={control}
                          name="firstName"
                          rules={{
                            required: { value: true, message: t('form:fieldIsRequired') },
                            validate: (value: string) =>
                              value.trim() ? true : (t('form:fieldIsRequired') as string),
                            minLength: { value: 1, message: t('form:minLength', { min: '1' }) },
                            maxLength: { value: 64, message: t('form:maxLength', { max: '64' }) },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <GridContainer level={GridLevel.InputPaper}>
                      <GridItem s={6} xl={4}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth={true}
                              required
                              label={t('data:user.lastName')}
                              inputProps={{ 'aria-label': t('data:user.lastName') }}
                              error={errors.lastName !== undefined}
                              helperText={errors.lastName && errors.lastName.message}
                            />
                          )}
                          control={control}
                          name="lastName"
                          rules={{
                            required: { value: true, message: t('form:fieldIsRequired') },
                            validate: (value: string) =>
                              value.trim() ? true : (t('form:fieldIsRequired') as string),
                            minLength: { value: 1, message: t('form:minLength', { min: '1' }) },
                            maxLength: { value: 64, message: t('form:maxLength', { max: '64' }) },
                          }}
                        />
                      </GridItem>
                      <GridItem s={6} xl={4}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth={true}
                              label={t('data:user.workspace')}
                              inputProps={{ 'aria-label': t('data:user.workspace') }}
                              error={errors.workspace !== undefined}
                              helperText={errors.workspace && errors.workspace.message}
                            />
                          )}
                          control={control}
                          name="workspace"
                          rules={{
                            minLength: { value: 1, message: t('form:minLength', { min: '1' }) },
                            maxLength: { value: 64, message: t('form:maxLength', { max: '64' }) },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </ContainerOutsideWithHeader>
          <ContainerOutsideWithHeader>
            <Typography variant="h2">{t('data:user.rightsAssignment')}</Typography>
            <Paper>
              <ContainerInside>
                <Controller
                  render={({ field }) => (
                    <RadioGroup {...field} aria-label={t('data:user.role')} name="role">
                      <GridContainer level={GridLevel.InputPaper}>
                        <GridItem s={12} xl={4}>
                          <FormControl>
                            <GridContainer level={GridLevel.InputPaper}>
                              <GridItem>
                                <FormControlLabel
                                  data-testid="production-radio-btn"
                                  control={
                                    <Radio
                                      onChange={handleRoleChange}
                                      value={UserRole.Production}
                                      checked={selectedRole === UserRole.Production}
                                    />
                                  }
                                  label={t('data:user:userRoles.3') as string}
                                />
                                <Box className={classes.radioInfo}>
                                  {getRoleInfo(UserRole.Production)}
                                </Box>
                              </GridItem>
                            </GridContainer>
                            {errors.role && errors.role.message && (
                              <FormHelperText>{errors.role.message}</FormHelperText>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem s={12} xl={4}>
                          <FormControl>
                            <GridContainer level={GridLevel.InputPaper}>
                              <GridItem>
                                <FormControlLabel
                                  data-testid="supervisor-radio-btn"
                                  control={
                                    <Radio
                                      onChange={handleRoleChange}
                                      value={UserRole.Supervisor}
                                      checked={selectedRole === UserRole.Supervisor}
                                    />
                                  }
                                  label={t('data:user:userRoles.2') as string}
                                />
                                <Box className={classes.radioInfo}>
                                  <Typography>{t('data:user.additionallyAllowed')}</Typography>
                                  {getRoleInfo(UserRole.Supervisor)}
                                </Box>
                              </GridItem>
                            </GridContainer>
                            {errors.role && errors.role.message && (
                              <FormHelperText>{errors.role.message}</FormHelperText>
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem s={12} xl={4}>
                          <FormControl>
                            <GridContainer>
                              <GridItem>
                                <FormControlLabel
                                  data-testid="administrator-radio-btn"
                                  control={
                                    <Radio
                                      onChange={handleRoleChange}
                                      value={UserRole.Admin}
                                      checked={selectedRole === UserRole.Admin}
                                    />
                                  }
                                  label={t('data:user:userRoles.1') as string}
                                />
                                <Box className={classes.radioInfo}>
                                  <Typography>{t('data:user.additionallyAllowed')}</Typography>
                                  {getRoleInfo(UserRole.Admin)}
                                </Box>
                              </GridItem>
                            </GridContainer>
                            {errors.role && errors.role.message && (
                              <FormHelperText>{errors.role.message}</FormHelperText>
                            )}
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </RadioGroup>
                  )}
                  control={control}
                  name="role"
                  rules={{
                    required: { value: true, message: t('form:fieldIsRequired') },
                  }}
                />
              </ContainerInside>
            </Paper>
          </ContainerOutsideWithHeader>
          {user && (
            <ContainerOutsideWithHeader>
              <UserAccessDataComponent userId={user.id} username={user.username} />
            </ContainerOutsideWithHeader>
          )}
          <GridItem>
            <Box className={formClasses.buttons}>
              <Button
                data-testid="cancel-btn"
                color="secondary"
                variant="contained"
                onClick={() => props.cancel()}
              >
                {t('form:cancel')}
              </Button>
              <Button data-testid="submit-btn" color="primary" type="submit" variant="contained">
                {t('form:submit')}
              </Button>
            </Box>
          </GridItem>
        </form>
      </Root>
    </FormRoot>
  );
};
