import { Paper } from '@mui/material';

import { GiveAwayChartInfoComponent } from '../../../components/give-away-chart/give-away-chart-info.component';
import { ProductionRun } from '../../../model';

export interface OwnProps {
  productionRun: ProductionRun;
}

export const FertigPackVGiveAwayComponent = (props: OwnProps) => {
  const { productionRun } = props;

  return (
    <Paper data-testid="fertigPackVGiveAwayComponent">
      <GiveAwayChartInfoComponent productionRun={productionRun} />
    </Paper>
  );
};
