import React from 'react';
import { Redirect, Switch } from 'react-router';

import { User, UserRole } from '../model';
import { ArticleCopyPage } from '../pages/articles/article/article-copy.page';
import { ArticleEditPage } from '../pages/articles/article/article-edit.page';
import { ArticleNewPage } from '../pages/articles/article/article-new.page';
import { CheckAttributeEditPage } from '../pages/check-attributes/check-attribute/check-attribute-edit.page';
import { CheckAttributeNewPage } from '../pages/check-attributes/check-attribute/check-attribute-new.page';
import { DeviceEditPage } from '../pages/devices/device/device-edit.page';
import { DeviceNewPage } from '../pages/devices/device/device-new.page';
import { LineEditPage } from '../pages/lines/line/line-edit.page';
import { LineNewPage } from '../pages/lines/line/line-new.page';
import { ProductionOrderEditPage } from '../pages/production/production-order/production-order-edit.page';
import { ProductionOrderNewPage } from '../pages/production/production-order/production-order-new.page';
import { ProductionOrdersOverviewPage } from '../pages/production/production-orders/production-orders-overview.page';
import { ProductionRunFinishedPage } from '../pages/production/production-run-finished/production-run-finished.page';
import { ProductionRunRunningPage } from '../pages/production/production-run-running/production-run-running.page';
import { ProductionRunsFinishedOverviewPage } from '../pages/production/production-runs-finished/production-runs-finished-overview.page';
import { ProductionRunsRunningOverviewPage } from '../pages/production/production-runs-running/production-runs-running-overview.page';
import { ProfilePage } from '../pages/profile/profile.page';
import { UserEditPage } from '../pages/users/user/user-edit.page';
import { UserNewPage } from '../pages/users/user/user-new.page';

import { PrivateRoute } from './private-route';
import { AppRoutePath, SidebarRoute, jobsRoutes, configurationRoutes } from './routes';

export default function privateRoutes(
  changePassword: boolean,
  licenseExpired: boolean,
  loggedInUser: User | undefined
): React.ReactNode {
  return (
    <Switch>
      {/* No Root content yet */}
      {/* <Route exact={true} path={AppRoutePath.root} /> */}
      {jobsRoutes.map((route: SidebarRoute) => {
        return (
          <PrivateRoute
            key={route.translationKeyName}
            exact={true}
            path={`/${route.path}`}
            component={route.component}
            loggedInUser={loggedInUser}
            changePassword={changePassword}
            minRole={route.minRole}
            licenseExpired={licenseExpired}
            visibleWhenLicenseIsExpired={route.visibleWhenLicenseIsExpired}
          />
        );
      })}
      {configurationRoutes.map((route: SidebarRoute) => {
        return (
          <PrivateRoute
            key={route.translationKeyName}
            exact={true}
            path={`/${route.path}`}
            component={route.component}
            loggedInUser={loggedInUser}
            changePassword={changePassword}
            minRole={route.minRole}
            licenseExpired={licenseExpired}
            visibleWhenLicenseIsExpired={route.visibleWhenLicenseIsExpired}
          />
        );
      })}
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.articles}/${AppRoutePath.new}`}
        component={ArticleNewPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.articles}/:id/${AppRoutePath.edit}`}
        component={ArticleEditPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.articles}/:id/${AppRoutePath.copy}`}
        component={ArticleCopyPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.checkAttributes}/${AppRoutePath.new}`}
        component={CheckAttributeNewPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.checkAttributes}/:id/${AppRoutePath.edit}`}
        component={CheckAttributeEditPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/${AppRoutePath.new}`}
        component={ProductionOrderNewPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/:id/${AppRoutePath.edit}`}
        component={ProductionOrderEditPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.finished}/:id`}
        component={ProductionRunFinishedPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}`}
        component={ProductionRunsRunningOverviewPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}`}
        component={ProductionOrdersOverviewPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.finished}`}
        component={ProductionRunsFinishedOverviewPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/:id`}
        component={ProductionRunRunningPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.lines}/${AppRoutePath.new}`}
        component={LineNewPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.lines}/:id/${AppRoutePath.edit}`}
        component={LineEditPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.devices}/${AppRoutePath.new}`}
        component={DeviceNewPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.devices}/:id/${AppRoutePath.edit}`}
        component={DeviceEditPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={false}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.users}/${AppRoutePath.new}`}
        component={UserNewPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Admin}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={true}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.users}/:id/${AppRoutePath.edit}`}
        component={UserEditPage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Admin}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={true}
      />
      <PrivateRoute
        exact={true}
        path={`/${AppRoutePath.me}`}
        component={ProfilePage}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={true}
      />
      <PrivateRoute
        render={() => (
          <Redirect
            to={
              licenseExpired
                ? `/${AppRoutePath.license}`
                : `/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/`
            }
          />
        )}
        loggedInUser={loggedInUser}
        changePassword={changePassword}
        minRole={UserRole.Supervisor}
        licenseExpired={licenseExpired}
        visibleWhenLicenseIsExpired={true}
      />
    </Switch>
  );
}
