import { styled } from '@mui/material/styles';
import React from 'react';

import { ProductionRun } from '../../../model';
import { irisCheckResultColors } from '../../../theme';
import { getPercentage } from '../../../utils/calc-utils';

const PREFIX = 'ProductionRunBarChartComponent';
const classes = {
  barContainer: `${PREFIX}-barContainer`,
  greenBarResult: `${PREFIX}-greenBarResult`,
  yellowBarResult: `${PREFIX}-yellowBarResult`,
  redBarResult: `${PREFIX}-redBarResult`,
  blueBarResult: `${PREFIX}-blueBarResult`,
  grayBarResult: `${PREFIX}-grayBarResult`,
};

interface StylePropsWrapper {
  styleProps: { productionRun: ProductionRun };
}

const barHeight = '25px';

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ styleProps }) => ({
  [`& .${classes.barContainer}`]: {
    minWidth: '200px',
    display: 'flex',
  },

  [`& .${classes.greenBarResult}`]: {
    height: barHeight,
    backgroundColor: irisCheckResultColors.passed,
    width: `${getPercentage(
      styleProps.productionRun.checkSamplesPassed,
      styleProps.productionRun.checkSamplesDone
    )}%`,
  },

  [`& .${classes.yellowBarResult}`]: {
    height: barHeight,
    backgroundColor: irisCheckResultColors.sufficient,
    width: `${getPercentage(
      styleProps.productionRun.checkSamplesSufficient
        ? styleProps.productionRun.checkSamplesSufficient
        : 0,
      styleProps.productionRun.checkSamplesDone
    )}%`,
  },

  [`& .${classes.redBarResult}`]: {
    height: barHeight,
    backgroundColor: irisCheckResultColors.failed,
    width: `${getPercentage(
      styleProps.productionRun.checkSamplesFailed,
      styleProps.productionRun.checkSamplesDone
    )}%`,
  },

  [`& .${classes.blueBarResult}`]: {
    height: barHeight,
    backgroundColor: irisCheckResultColors.unrated,
    width: `${getPercentage(
      styleProps.productionRun.checkSamplesUnrated,
      styleProps.productionRun.checkSamplesDone
    )}%`,
  },

  [`& .${classes.grayBarResult}`]: {
    height: barHeight,
    backgroundColor: irisCheckResultColors.skipped,
    width: `${getPercentage(
      styleProps.productionRun.checkSamplesSkipped,
      styleProps.productionRun.checkSamplesDone
    )}%`,
  },
}));

export interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunBarChartComponent = (props: OwnProps) => {
  const { productionRun } = props;

  return (
    <Root styleProps={{ productionRun: props.productionRun }}>
      {productionRun.checkSamplesDone > 0 ? (
        <div className={classes.barContainer}>
          <div data-testid="passed-result" className={classes.greenBarResult}>
            {' '}
          </div>
          <div data-testid="sufficient-result" className={classes.yellowBarResult}>
            {' '}
          </div>
          <div data-testid="failed-result" className={classes.redBarResult}>
            {' '}
          </div>
          <div data-testid="unrated-result" className={classes.blueBarResult}>
            {' '}
          </div>
          <div data-testid="skipped-result" className={classes.grayBarResult}>
            {' '}
          </div>
        </div>
      ) : (
        <div className={classes.barContainer} />
      )}
    </Root>
  );
};
