import { Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TFunction } from 'i18next';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { ExportButtonComponent } from '../../../components/buttons/export-button.component';
import { EventsTableComponent } from '../../../components/events-table/events-table.component';
import { GridContainer } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { IssuesComponent } from '../../../components/issue/issues.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { PrintContentComponent } from '../../../components/print/print-content.component';
import { ProductionRunDetailsResponsiveComponent } from '../../../components/production-details/production-details-responsive.component';
import {
  ContainerInside,
  ContainerOutside,
  ContainerOutsideWithHeader,
} from '../../../components/structure';
import { TableData } from '../../../components/table/description-table.component';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../constants';
import { getFertigPackVStatistics } from '../../../helper';
import {
  getTableDataArticle,
  getTableDataBatchNumber,
  getTableDataDuration,
  getTableDataFinishTime,
  getTableDataOrderAmount,
  getTableDataStartTime,
} from '../../../helper/table-data/table-data.helper';
import { IssueCode, ProductionRun, ProductionRunStatus } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  productionRunFinishedSelector,
  fetchProductionRunFinished,
  fetchProductionRunCheckResults,
  exportProductionRunCheckResults,
  checkResultsTotalCountSelector,
  exportProductionRunEvents,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { FertigPackVCategoriesComponent } from '../production-run/fertigpackv-categories.component';
import { FertigPackVGiveAwayComponent } from '../production-run/fertigpackv-give-away.component';
import { ProductionRunAverageWeightComponent } from '../production-run/production-run-average-weight.component';
import { ProductionRunPieChartComponent } from '../production-run/production-run-pie-chart.component';

import { CheckResultsTableComponent } from './check-results-table.component';

enum ExportData {
  Events,
  CheckResults,
}

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ProductionRunFinishedPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['data', 'common']);
  const productionRunId = id;
  const [page, setPage] = useState<number>(0);
  const componentRef = createRef<HTMLDivElement>();
  const checkResultsCount = useSelector(checkResultsTotalCountSelector);
  const productionRun = useSelector(productionRunFinishedSelector);
  const isRunFinished = productionRun && productionRun.status === ProductionRunStatus.Done;
  const statistics = productionRun && getFertigPackVStatistics(productionRun);
  const hasFertigPackVWeight =
    !!statistics?.checkSamplesAverageWeight || !!statistics?.checkSamplesAverageVolume;
  const hasFertigPackVSamples = !!statistics?.checkSamplesDone;

  useEffect(() => {
    dispatch(fetchProductionRunFinished(productionRunId));
  }, [dispatch, productionRunId]);

  useEffect(() => {
    if (productionRunId) {
      dispatch(
        fetchProductionRunCheckResults(productionRunId, {
          pagination: { page, perPage: DEFAULT_ITEMS_PER_PAGE },
        })
      );
    }
  }, [dispatch, productionRunId, page]);

  const handlePrint = useReactToPrint({
    content: () => componentRef!.current!,
    documentTitle: `${productionRun?.orderNumber}_${productionRun?.article.productionLine.name}_Auswertung`,
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const createFileName = (orderNumber: string, exportData: ExportData) => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    const monthStr = month < 10 ? `0${month}` : month.toString();
    const dayStr = day < 10 ? `0${day}` : day.toString();
    let suffix = '';
    switch (exportData) {
      case ExportData.CheckResults:
        suffix = '_EP';
        break;
      case ExportData.Events:
        suffix = '_M';
        break;
    }

    const dateStr = `${year}${monthStr}${dayStr}`;
    return `${orderNumber}_${dateStr}${suffix}.csv`;
  };

  const exportCheckResults = () => {
    productionRun &&
      dispatch(
        exportProductionRunCheckResults(
          productionRun.id,
          createFileName(productionRun.orderNumber, ExportData.CheckResults)
        )
      );
  };

  const exportEvents = () => {
    productionRun &&
      dispatch(
        exportProductionRunEvents(
          productionRun.id,
          createFileName(productionRun.orderNumber, ExportData.Events)
        )
      );
  };

  const getTableData = (productionRun: ProductionRun, t: TFunction): TableData[] => {
    return [
      getTableDataStartTime(productionRun, t),
      getTableDataFinishTime(productionRun, t),
      getTableDataDuration(productionRun, t),
      getTableDataArticle(productionRun, t),
      getTableDataOrderAmount(productionRun, t),
      getTableDataBatchNumber(productionRun, t),
    ];
  };

  return (
    <DetailLayoutComponent
      backLinkText={t('data:productionRun.backToOverview')}
      backLinkTarget={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.finished}/`}
      headlineText={!isRunFinished ? t('data:productionRun.couldNotLoad') : ''}
      print={isRunFinished ? handlePrint : undefined}
    >
      {isRunFinished && (
        <>
          <Box>
            <IssuesComponent
              issues={productionRun.issues}
              allowedIssueCodes={[IssueCode.FertigPackVTU2Violation]}
              iconSize={'medium'}
              elementStyle={'infobox'}
            />
            <ContainerOutside>
              <Paper>
                <ContainerInside>
                  <ProductionRunDetailsResponsiveComponent
                    tableData={getTableData(productionRun, t)}
                    productionRun={productionRun}
                  />
                </ContainerInside>
              </Paper>
            </ContainerOutside>
            <GridItem>
              <Typography variant="h2">{t('data:productionRun.checkResults')}</Typography>
              <GridContainer>
                <GridItem>
                  <ProductionRunPieChartComponent
                    productionRun={productionRun}
                    noCheckResultsText={t('data:productionRun.noCheckResults')}
                  />
                </GridItem>
                {hasFertigPackVWeight && (
                  <GridItem>
                    <ProductionRunAverageWeightComponent
                      headlineText={t('data:productionRun.fertigPackVAverageResult')}
                      productionRun={productionRun}
                    />
                  </GridItem>
                )}
                {hasFertigPackVSamples && (
                  <>
                    <GridItem>
                      <FertigPackVCategoriesComponent productionRun={productionRun} />
                    </GridItem>
                    <GridItem>
                      <FertigPackVGiveAwayComponent productionRun={productionRun} />
                    </GridItem>
                  </>
                )}
              </GridContainer>
            </GridItem>
            <ContainerOutsideWithHeader>
              <HeaderBox>
                <Typography variant="h2">{t('data:productionRun.events.events')}</Typography>
                <>
                  {!productionRun.events.length || (
                    <Box mt={irisSpacing.h2.mt} mb={irisSpacing.h2.mb}>
                      <ExportButtonComponent
                        disabled={false}
                        text={t('common:csvExport')}
                        onClick={exportEvents}
                        testId={'exportBtnEvents'}
                      />
                    </Box>
                  )}
                </>
              </HeaderBox>
              <EventsTableComponent
                events={productionRun.events}
                noEventsText={t('data:productionRun.events.noEvents')}
              />
            </ContainerOutsideWithHeader>
            <ContainerOutsideWithHeader>
              <HeaderBox>
                <Typography variant="h2">{t('data:checkResult.checkResults')}</Typography>
                <>
                  {!checkResultsCount || (
                    <Box mt={irisSpacing.h2.mt} mb={irisSpacing.h2.mb}>
                      <ExportButtonComponent
                        disabled={false}
                        text={t('common:csvExport')}
                        onClick={exportCheckResults}
                        testId={'exportBtnCheckResults'}
                      />
                    </Box>
                  )}
                </>
              </HeaderBox>
              <CheckResultsTableComponent handlePageChange={handlePageChange} page={page} />
            </ContainerOutsideWithHeader>
          </Box>
          <div data-testid="printContentComponent" style={{ overflow: 'hidden', height: 0 }}>
            <PrintContentComponent productionRun={productionRun} ref={componentRef} />
          </div>
        </>
      )}
    </DetailLayoutComponent>
  );
};
