import { TFunction } from 'i18next';

import { TableData } from '../../components/table/description-table.component';
import i18n from '../../i18next';
import { ProductionRun } from '../../model';
import { dateAndTimeOptions, getDurationText } from '../../utils/date-utils';

export const getTableDataStartTime = (productionRun: ProductionRun, t: TFunction): TableData => {
  const startDate = new Date(productionRun.started);
  return {
    rowName: t('data:productionRun.start'),
    rowValue: isNaN(startDate.valueOf())
      ? undefined
      : `${startDate.toLocaleString(i18n.language, dateAndTimeOptions)} ${t('common:time')}`,
    'data-testid': 'ProductionRunDetails_StartTime',
  };
};

export const getTableDataFinishTime = (productionRun: ProductionRun, t: TFunction): TableData => {
  const endDate = new Date(productionRun.finished ?? 0);
  const isDateValid = productionRun.finished && !isNaN(endDate.valueOf());
  return {
    rowName: t('data:productionOrder.end'),
    rowValue: isDateValid
      ? `${endDate.toLocaleString(i18n.language, dateAndTimeOptions)} ${t('common:time')}`
      : undefined,
    'data-testid': 'ProductionRunDetails_FinishTime',
  };
};

export const getTableDataDuration = (productionRun: ProductionRun, t: TFunction): TableData => {
  const startDate = new Date(productionRun.started);
  const endDate = new Date(productionRun.finished ?? 0);
  return {
    rowName: t('data:productionRun.duration'),
    rowValue: productionRun.finished
      ? getDurationText(startDate, endDate, t('common:hours'))
      : undefined,
    'data-testid': 'ProductionRunDetails_Duration',
  };
};

export const getTableDataOrderAmount = (productionRun: ProductionRun, t: TFunction): TableData => {
  return {
    rowName: t('data:productionOrder.amount'),
    rowValue: productionRun.amount,
    'data-testid': 'ProductionRunDetails_Amount',
  };
};

export const getTableDataArticle = (productionRun: ProductionRun, t: TFunction): TableData => {
  return {
    rowName: t('data:article.article'),
    rowValue: `${productionRun.article.articleNumber} | ${productionRun.article.name}${
      productionRun.article.nameSuffix ? ' | ' + productionRun.article.nameSuffix : ''
    }`,
    'data-testid': 'ProductionRunDetails_Article',
  };
};

export const getTableDataBatchNumber = (productionRun: ProductionRun, t: TFunction): TableData => {
  return {
    rowName: t('data:productionOrder.batchNumber'),
    rowValue: productionRun.batchNumber,
    'data-testid': 'ProductionRunDetails_BatchNumber',
  };
};
