import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { formClasses } from '../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { ContainerInside } from '../../../components/structure';
import { SRA_PREFIX } from '../../../constants';
import { DeviceType, Line, VirtualDevice } from '../../../model';
import { fetchLines, lineListSelector } from '../../../store';

export interface OwnProps {
  device?: VirtualDevice;
  submit: (device: Omit<VirtualDevice, 'id' | 'lastModified' | 'weighingBridges'>) => void;
  cancel: () => void;
}

type FormData = {
  deviceName: string;
  productionLine: Line | undefined;
  sraNumber?: string;
  serialNumber?: string;
};

const getFormValues = (device?: VirtualDevice): FormData => ({
  deviceName: device?.name || '',
  productionLine: device?.productionLine || undefined,
  sraNumber: (device?.sraNumber && device?.sraNumber.replace(SRA_PREFIX, '')) || '',
  serialNumber: device?.serialNumber || '',
});

export const VirtualDeviceFormComponent = (props: OwnProps) => {
  const { device } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['form', 'data']);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    register,
  } = useForm<FormData>({
    defaultValues: getFormValues(device),
  });
  const lines = useSelector(lineListSelector);

  useEffect(() => {
    dispatch(fetchLines());
  }, [dispatch]);

  useEffect(() => {
    // Autocomplete needs custom register
    register('productionLine');
  });

  useEffect(() => {
    // wait for data to be loaded and reset default values
    device && reset(getFormValues(device));
  }, [device, reset]);

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit({
      name: formData.deviceName,
      sraNumber: formData.sraNumber ? `${SRA_PREFIX}${formData.sraNumber}` : '',
      serialNumber: formData.serialNumber,
      productionLine: formData.productionLine,
      deviceType: DeviceType.virtual,
    });
  });

  const handleLineChange = (e: React.ChangeEvent<{}>, value: Line | null) => {
    setValue('productionLine', value || undefined);
  };

  return (
    <form onSubmit={onSubmit} className={formClasses.root}>
      <Typography variant="h2">{t('data:device.baseData')}</Typography>
      <Paper data-testid="virtualDeviceBaseData">
        <ContainerInside>
          <GridContainer level={GridLevel.InputPaper}>
            <GridItem s={6} xl={4}>
              <GridContainer level={GridLevel.InputPaper}>
                <GridItem>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth={true}
                        required
                        label={t('data:device.name')}
                        inputProps={{
                          'aria-label': t('data:device.name'),
                          'data-testid': 'virtual-device-name-input',
                        }}
                        error={errors.deviceName !== undefined}
                        helperText={errors.deviceName && errors.deviceName.message}
                      />
                    )}
                    control={control}
                    name="deviceName"
                    rules={{
                      required: { value: true, message: t('form:fieldIsRequired') },
                      validate: (value: string) =>
                        value.trim() ? true : (t('form:fieldIsRequired') as string),
                      minLength: {
                        value: 2,
                        message: t('form:minLength', { min: '2' }),
                      },
                      maxLength: {
                        value: 100,
                        message: t('form:maxLength', { max: '100' }),
                      },
                    }}
                  />
                </GridItem>
                <GridItem>
                  <GridContainer level={GridLevel.InputPaper}>
                    <GridItem s={6}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth={true}
                            label={t('data:device.serialNumber')}
                            inputProps={{
                              'aria-label': t('data:device.serialNumber'),
                              'data-testid': 'serial-number-input',
                            }}
                            error={errors.serialNumber !== undefined}
                            helperText={errors.serialNumber && errors.serialNumber.message}
                          />
                        )}
                        control={control}
                        name="serialNumber"
                        rules={{
                          maxLength: {
                            value: 64,
                            message: t('form:maxLength', { max: '64' }),
                          },
                        }}
                      />
                    </GridItem>
                    <GridItem s={6}>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth={true}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">{SRA_PREFIX}</InputAdornment>
                              ),
                            }}
                            label={t('data:device.sraNumber')}
                            inputProps={{
                              'aria-label': t('data:device.sraNumber'),
                              'data-testid': 'sra-number-input',
                            }}
                            error={errors.sraNumber !== undefined}
                            helperText={errors.sraNumber && errors.sraNumber.message}
                          />
                        )}
                        control={control}
                        name="sraNumber"
                        rules={{
                          maxLength: {
                            value: 64,
                            message: t('form:maxLength', { max: '64' }),
                          },
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem>
                  <Autocomplete
                    options={lines || []}
                    getOptionLabel={(line: Line) => line.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    defaultValue={device?.productionLine || undefined}
                    isOptionEqualToValue={(option: Line, value: Line) => option.id === value.id}
                    clearText={t('common:delete')}
                    noOptionsText={t('common:noDataAvailable')}
                    openText={t('common:open')}
                    closeText={t('common:close')}
                    id="productionLineAutocomplete"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="productionLine"
                        label={t('data:line.line')}
                        inputProps={{
                          ...params.inputProps,
                          'data-testid': 'line-input',
                        }}
                        variant="outlined"
                        error={!!errors.productionLine}
                        helperText={errors.productionLine && (errors.productionLine as any).message}
                      />
                    )}
                    onChange={handleLineChange}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </ContainerInside>
      </Paper>
      <Box className={formClasses.buttons}>
        <Button
          data-testid="cancel-btn"
          color="secondary"
          variant="contained"
          onClick={() => props.cancel()}
        >
          {t('form:cancel')}
        </Button>
        <Button data-testid="submit-btn" color="primary" variant="contained" type="submit">
          {t('form:submit')}
        </Button>
      </Box>
    </form>
  );
};
