import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Link, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ApiKeyComponent } from '../../components/api-key/api-key.component';
import { DataTableRoot } from '../../components/data-table/data-table.styles';
import { GridContainer, GridLevel } from '../../components/grid/grid.container';
import { GridItem } from '../../components/grid/grid.item';
import { InfoboxComponent } from '../../components/infobox/infobox.component';
import { LicenseInfoComponent } from '../../components/license-info/license-info.component';
import {
  HeaderContainer,
  BodyStructure,
  ContainerOutside,
  HeaderStructure,
  PageStructure,
  ContainerInside,
  ContainerOutsideWithHeader,
} from '../../components/structure';
import { isModuleLicensed } from '../../helper';
import { ModuleType } from '../../model';
import {
  addLicenseSuccessfulSelector,
  expiredLicenseFailureSelector,
  fetchLicense,
  licenseSelector,
  clearLicenseUploadStates,
  addLicense,
} from '../../store';
import { irisSpecialColors } from '../../theme';
import { dateOptions } from '../../utils/date-utils';

interface LicenseExpirationDateProps {
  expired: boolean;
}

interface LicenseModuleTextProps {
  licensed: boolean;
}

const LicenseExpirationDate = styled(Typography)<LicenseExpirationDateProps>(
  ({ theme, expired }) => ({
    color: expired ? theme.palette.error.main : 'initial',
  })
);

const LicenseModuleText = styled(Typography)<LicenseModuleTextProps>(({ theme, licensed }) => ({
  color: !licensed ? theme.palette.text.disabled : 'initial',
}));

const LicenseExpirationText = styled(Typography)(() => ({
  color: irisSpecialColors.attributeCaption,
}));

const LicenseFileInput = styled('input')(() => ({
  display: 'None',
}));

const LicenseModuleContainer = styled(Box)(() => ({
  display: 'flex',
  minHeight: '30px',
}));

const LicenseModuleIconContainer = styled(Box)(({ theme }) => ({
  minWidth: theme.spacing(4),
}));

const LicenseCheckIcon = styled(CheckIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const LicensePage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['license', 'form', 'common']);
  const license = useSelector(licenseSelector);
  const addLicenseSuccessful = useSelector(addLicenseSuccessfulSelector);
  const attemptedLicenseExpired = useSelector(expiredLicenseFailureSelector);

  useEffect(() => {
    license === undefined && dispatch(fetchLicense());
  }, [dispatch, license]);

  useEffect(() => {
    return () => {
      dispatch(clearLicenseUploadStates());
    };
  }, [dispatch]);

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      dispatch(addLicense(e.target.files[0]));
    }
  };

  const getInfoBox = () => {
    if (addLicenseSuccessful === true && license && license.expired === false) {
      return <InfoboxComponent type="success" subline={t('license:addLicenseSuccessful')} />;
    } else if (attemptedLicenseExpired === true) {
      return <InfoboxComponent type="error" subline={t('license:licenseExpired')} />;
    } else if (addLicenseSuccessful === false) {
      const subline = (
        <Trans t={t} i18nKey="addLicenseFailed">
          The license could not be extended. Please try the upload again or contact your{' '}
          <Link href="mailto:service@hoefelmeyer.de" underline="hover">
            Höfelmeyer system consultant
          </Link>
          .
        </Trans>
      );
      return <InfoboxComponent type="error" subline={subline} />;
    }
    return <></>;
  };

  const getModuleItem = (moduleType: ModuleType, index: number) => {
    const licensed = isModuleLicensed(license, moduleType);

    return (
      <LicenseModuleContainer mb={1} data-testid={`moduleContainer_${moduleType}`}>
        {licensed && (
          <LicenseModuleIconContainer>
            <LicenseCheckIcon />
          </LicenseModuleIconContainer>
        )}
        <LicenseModuleText licensed={!!licensed}>
          {t([`license:moduleTypes.${index}`, `license:moduleTypes.notFound`])}
        </LicenseModuleText>
      </LicenseModuleContainer>
    );
  };

  const licenseGrid = (header: string) => {
    return (
      license && (
        <GridItem s={6}>
          <Paper>
            <ContainerInside>
              <GridContainer>
                <GridItem>
                  <Box data-testid="license">
                    <Typography variant="h3">{header}</Typography>
                    <Box display={'flex'} gap={1}>
                      <LicenseExpirationText>{t('license:licenseValidTo')}:</LicenseExpirationText>
                      <LicenseExpirationDate expired={license.expired}>
                        {new Date(license.validTo).toLocaleString(i18n.language, dateOptions)}
                      </LicenseExpirationDate>
                    </Box>
                  </Box>
                </GridItem>
              </GridContainer>
            </ContainerInside>
          </Paper>
        </GridItem>
      )
    );
  };

  return (
    <PageStructure>
      <DataTableRoot>
        <HeaderStructure>
          <HeaderContainer>
            <Typography variant="h1">{t('license:licenseManagement')}</Typography>
          </HeaderContainer>
        </HeaderStructure>
        <BodyStructure>
          {(addLicenseSuccessful != null || attemptedLicenseExpired != null) && (
            <Box mb={4}>{getInfoBox()}</Box>
          )}
          {license && license.expired === true && (
            <Box mb={4}>
              <InfoboxComponent type="error" subline={t('license:licenseExpiredPleaseRenew')} />
            </Box>
          )}
          {license && (
            <ContainerOutsideWithHeader>
              <Typography variant="h2">{t('license:yourIrisProducts')}</Typography>
              <GridContainer>
                {licenseGrid('IRIS @office')}
                {licenseGrid('IRIS @production')}
              </GridContainer>
            </ContainerOutsideWithHeader>
          )}
          <ContainerOutside>
            <Paper>
              <ContainerInside>
                <Typography variant="h3">{t('license:modules')}</Typography>
                <GridContainer>
                  <GridItem>
                    {getModuleItem(ModuleType.StaticQualityControl, 1)}
                    {getModuleItem(ModuleType.ManualCheck, 2)}
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </ContainerOutside>
          <ContainerOutside>
            <Paper>
              <ContainerInside>
                <Typography variant="h3">{t('license:extendLicense')}</Typography>
                <GridContainer level={GridLevel.InputPaper}>
                  <GridItem>
                    <Box>
                      <LicenseFileInput
                        accept=".license"
                        id="license-upload"
                        multiple
                        type="file"
                        data-testid="licenseUploadInput"
                        onChange={uploadFile}
                      />
                      <label htmlFor="license-upload">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          data-testid="uploadLicenseBtn"
                        >
                          {t('license:uploadLicense')}
                        </Button>
                      </label>
                    </Box>
                  </GridItem>
                  <GridItem>
                    <Box>
                      <Typography>
                        <Trans t={t} i18nKey="contactSystemConsultant">
                          Contact{' '}
                          <Link href="mailto:service@hoefelmeyer.de" underline="hover">
                            Höfelmeyer system consultant
                          </Link>{' '}
                          now to get a license.
                        </Trans>
                      </Typography>
                    </Box>
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </ContainerOutside>

          <ContainerOutside>
            <ApiKeyComponent />
          </ContainerOutside>
          <LicenseInfoComponent />
        </BodyStructure>
      </DataTableRoot>
    </PageStructure>
  );
};
