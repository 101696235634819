import { Paper, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { DEFAULT_MAX_SAMPLESIZE } from '../../../../constants';
import { NumericTextField } from '../../../../custom';

export const SampleSizeComponent = () => {
  const { t } = useTranslation(['form', 'data']);
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <ContainerOutsideWithHeader>
      <Typography variant="h2">{t('data:checkAttribute.samples')}</Typography>
      <Paper>
        <ContainerInside>
          <GridContainer level={GridLevel.InputPaper}>
            <GridItem s={3} xl={2}>
              <Controller
                render={({ field }) => (
                  <NumericTextField
                    {...field}
                    maxdigits={2}
                    fullWidth={true}
                    required
                    label={t('form:amount')}
                    inputProps={{ 'data-testid': 'sample-size-input' }}
                    error={errors.sampleSize !== undefined}
                    helperText={errors.sampleSize && errors.sampleSize.message}
                  />
                )}
                control={control}
                name="sampleSize"
                rules={{
                  required: { value: true, message: t('form:fieldIsRequired') },
                  validate: (value: string) =>
                    Number.isInteger(Number(value)) ? true : (t('form:integerValue') as string),
                  min: { value: 1, message: t('form:minValue', { min: '1' }) },
                  max: {
                    value: DEFAULT_MAX_SAMPLESIZE,
                    message: t('form:maxValue', { max: DEFAULT_MAX_SAMPLESIZE }),
                  },
                }}
              />
            </GridItem>
          </GridContainer>
        </ContainerInside>
      </Paper>
    </ContainerOutsideWithHeader>
  );
};
