import MuiPauseIcon from '@mui/icons-material/PauseCircle';
import MuiWarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  styled,
  TableBody,
  TableHead,
  TableRow,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ProductionRunEvent, ProductionRunEventType } from '../../model';
import { openProductionRunEventDescriptionDialog } from '../../store';
import { extendTableBreakpoint, irisCustomColors } from '../../theme';
import { dateAndTimeOptions, getDurationText } from '../../utils/date-utils';
import { PaperTable, PaperTableCell } from '../table/paper-table.component';

import { EventDialogComponent } from './event-dialog.component';

export interface OwnProps {
  events: ProductionRunEvent[];
  noEventsText: string;
  forPrint?: boolean;
}

const TableCellLeft = styled(PaperTableCell)(({ theme, styleProps }) => ({
  borderLeft: 'solid',
  borderColor: irisCustomColors.irisGray,
  borderWidth: '1px',
}));

const TableCellRight = styled(PaperTableCell)(({ theme, styleProps }) => ({
  borderRight: 'solid',
  borderColor: irisCustomColors.irisGray,
  borderWidth: '1px',
}));

const FromToCellBox = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
}));

export const EventTypeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

export const ErrorIcon = styled(MuiWarningIcon)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export const WarningIcon = styled(MuiWarningIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

export const PauseIcon = styled(MuiPauseIcon)(({ theme }) => ({
  color: irisCustomColors.irisGold,
}));

const getIcon = (iconType: ProductionRunEventType) => {
  switch (iconType) {
    case ProductionRunEventType.Error:
      return <ErrorIcon />;
    case ProductionRunEventType.Warning:
      return <WarningIcon />;
    case ProductionRunEventType.Pause:
      return <PauseIcon />;
  }
};

export const EventsTableComponent = (props: OwnProps) => {
  const { i18n, t } = useTranslation(['data', 'common']);
  const { events, noEventsText, forPrint } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTableExtended = useMediaQuery(theme.breakpoints.up(extendTableBreakpoint));
  const tableCellWidth = isTableExtended ? '20%' : '25%';

  const handleOpenReason = (event: ProductionRunEvent) => {
    dispatch(openProductionRunEventDescriptionDialog(event));
  };

  const StyledTableCell = (props: {
    border?: 'left' | 'right';
    canHide?: boolean;
    children: React.ReactNode;
  }) => {
    const { canHide, children, border, ...restProps } = props;
    const isHidden = !forPrint && canHide && !isTableExtended;
    const width = tableCellWidth;
    return !forPrint || !border ? (
      <PaperTableCell styleProps={{ isHidden, width }} {...restProps}>
        {children}
      </PaperTableCell>
    ) : border === 'left' ? (
      <TableCellLeft styleProps={{ isHidden, width }} {...restProps}>
        {children}
      </TableCellLeft>
    ) : (
      <TableCellRight styleProps={{ isHidden, width }} {...restProps}>
        {children}
      </TableCellRight>
    );
  };

  return (
    <Box data-testid="eventsTableComponent">
      <EventDialogComponent />
      {events && events.length > 0 ? (
        <PaperTable
          ariaLabel={t('data:productionRun.events.table')}
          dataTestId={'eventsTable'}
          forPrint={forPrint}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell border="left" data-testid="tableCellHeader">
                {t('common:fromTo')}
              </StyledTableCell>
              <StyledTableCell canHide>{t('common:duration')}</StyledTableCell>
              <StyledTableCell>{t('data:productionRun.events.type')}</StyledTableCell>
              <StyledTableCell>{t('data:productionRun.events.reason')}</StyledTableCell>
              <StyledTableCell border="right">
                {t('data:productionRun.events.device')}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((runEvent: ProductionRunEvent) => {
              return (
                <TableRow key={runEvent.id}>
                  <StyledTableCell border="left">
                    <FromToCellBox>
                      <div>
                        {`${new Date(runEvent.started).toLocaleString(
                          i18n.language,
                          dateAndTimeOptions
                        )}`}
                      </div>
                      <div>
                        {`- ${
                          runEvent.ended
                            ? new Date(runEvent.ended).toLocaleString(
                                i18n.language,
                                dateAndTimeOptions
                              )
                            : t('common:toNow')
                        }`}
                      </div>
                    </FromToCellBox>
                  </StyledTableCell>
                  <StyledTableCell canHide>
                    {runEvent.started &&
                      runEvent.ended &&
                      `${getDurationText(
                        new Date(runEvent.started),
                        new Date(runEvent.ended),
                        t('common:hours')
                      )}`}
                  </StyledTableCell>
                  <StyledTableCell>
                    <EventTypeContainer>
                      {getIcon(runEvent.iconType)}
                      {t([
                        `data:productionRun.events.eventCode.${runEvent.eventCode}`,
                        `data:productionRun.events.eventCode.notFound`,
                      ])}
                    </EventTypeContainer>
                  </StyledTableCell>
                  <StyledTableCell>
                    {forPrint ? (
                      <>{runEvent.description}</>
                    ) : (
                      runEvent.description && (
                        <Link
                          component="button"
                          variant="body2"
                          underline="always"
                          color="secondary"
                          data-testid="showReasonLink"
                          onClick={() => handleOpenReason(runEvent)}
                        >
                          {t('data:productionRun.events.showReason')}
                        </Link>
                      )
                    )}
                  </StyledTableCell>
                  <StyledTableCell border="right">{runEvent.device?.name}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </PaperTable>
      ) : (
        <Box>{noEventsText}</Box>
      )}
    </Box>
  );
};
