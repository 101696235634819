import MuiWarningIcon from '@mui/icons-material/Warning';
import {
  Box,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { CommandsMenuComponent } from '../../../components/commands-menu/commands-menu.component';
import { CenteredImageInTableComponent } from '../../../components/image/centered-image/centered-image.component';
import { InfoboxComponent } from '../../../components/infobox/infobox.component';
import { getPredecessor, getSuccessor } from '../../../components/order-utils';
import { ContainerOutside } from '../../../components/structure';
import { PaperTable, PaperTableCell } from '../../../components/table/paper-table.component';
import { ProductionOrder, ProductionOrderStartError } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  productionOrderListSelector,
  deleteProductionOrder,
  changeProductionOrderOrder,
  startWsConnection,
  productionOrdersWebsocketConfig,
  hasNotStartableProductionOrdersSelector,
} from '../../../store';
import { extendTableBreakpoint, irisSpacing } from '../../../theme';

const ImageTableCell = styled(PaperTableCell)(({ theme }) => ({
  width: '150px',
}));

const MessageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  verticalAlign: 'middle',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}));

const WarningIcon = styled(MuiWarningIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.warning.main,
}));

export const ProductionOrdersTableComponent = () => {
  const dispatch = useDispatch();
  const productionOrders = useSelector(productionOrderListSelector);
  const hasNotStartableProductionOrders = useSelector(hasNotStartableProductionOrdersSelector);
  const { t } = useTranslation(['data', 'common']);
  const isTableExtended = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(extendTableBreakpoint)
  );
  const tableCellWidth = isTableExtended ? '20%' : '25%';

  useEffect(() => {
    dispatch(startWsConnection(productionOrdersWebsocketConfig));
  }, [dispatch]);

  const goToEditProductionOrder = (id: string) => {
    dispatch(
      push(
        `/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/${id}/${AppRoutePath.edit}`
      )
    );
  };

  const moveUp = (productionOrder: ProductionOrder) => {
    dispatch(
      changeProductionOrderOrder(
        productionOrder.id,
        getPredecessor(productionOrders, productionOrder)
      )
    );
  };

  const moveDown = (productionOrder: ProductionOrder) => {
    dispatch(
      changeProductionOrderOrder(
        productionOrder.id,
        getSuccessor(productionOrders, productionOrder)
      )
    );
  };

  const deleteProductionOrderById = (id: string) => {
    dispatch(deleteProductionOrder(id));
  };

  const StyledTableCell = (props: { canHide?: boolean; children: React.ReactNode }) => {
    return (
      <PaperTableCell
        styleProps={{ isHidden: !!props.canHide && !isTableExtended, width: tableCellWidth }}
      >
        {props.children}
      </PaperTableCell>
    );
  };

  return (
    <Box data-testid="productionOrdersTableComponent">
      {hasNotStartableProductionOrders && (
        <ContainerOutside sx={{ marginBottom: irisSpacing.container.space }}>
          <InfoboxComponent
            headline={t('data:productionOrder.warningInfoHeadline')}
            subline={t('data:productionOrder.warningInfoSubline')}
            type={'warning'}
          />
        </ContainerOutside>
      )}
      {productionOrders && productionOrders.length > 0 ? (
        <PaperTable dataTestId="productionOrdersTable" ariaLabel={t('data:productionOrder.table')}>
          <TableHead>
            <TableRow>
              <ImageTableCell styleProps={{ isHidden: !isTableExtended }}> </ImageTableCell>
              <StyledTableCell>{t('data:productionOrder.number')}</StyledTableCell>
              <StyledTableCell>{t('data:productionOrder.line')}</StyledTableCell>
              <StyledTableCell>{t('data:article.article')}</StyledTableCell>
              <StyledTableCell canHide>{t('data:productionOrder.batchNumber')}</StyledTableCell>
              <StyledTableCell>{t('common:messages')}</StyledTableCell>
              <TableCell>{t('')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productionOrders.map((productionOrder: ProductionOrder) => {
              return (
                <TableRow key={productionOrder.id}>
                  <ImageTableCell styleProps={{ isHidden: !isTableExtended }}>
                    <CenteredImageInTableComponent
                      imageUrl={productionOrder.article.thumbnailUrl}
                      alttext={t('data:article.image')}
                    />
                  </ImageTableCell>
                  <StyledTableCell>
                    <Link
                      component={NavLink}
                      to={`/${AppRoutePath.production}/${AppRoutePath.orders}/${AppRoutePath.planned}/${productionOrder.id}/${AppRoutePath.edit}`}
                      underline="hover"
                    >
                      <>{productionOrder.orderNumber}</>
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>
                    {productionOrder.productionLine ? productionOrder.productionLine.name : '-'}
                  </StyledTableCell>
                  <StyledTableCell>{productionOrder.article.name}</StyledTableCell>
                  <StyledTableCell canHide>
                    {productionOrder.batchNumber ? productionOrder.batchNumber : '-'}
                  </StyledTableCell>
                  <StyledTableCell>
                    {!productionOrder.startable &&
                      productionOrder.startErrors.map((errorCode: ProductionOrderStartError) => {
                        return (
                          <MessageBox
                            title={t([
                              `data:productionOrder.productionOrderStartErrorTooltips.${errorCode}`,
                              `data:productionOrder.notStartable`,
                            ])}
                            key={`${productionOrder.id}-${errorCode}`}
                          >
                            <WarningIcon />
                            <Typography>
                              {t([
                                `data:productionOrder.ProductionOrderStartErrorCodes.${errorCode}`,
                                `data:productionOrder.ProductionOrderStartErrorCodes.notFound`,
                              ])}
                            </Typography>
                          </MessageBox>
                        );
                      })}
                  </StyledTableCell>
                  <TableCell>
                    <CommandsMenuComponent
                      handleEdit={() => goToEditProductionOrder(productionOrder.id)}
                      handleMoveUp={() => moveUp(productionOrder)}
                      disableMoveUp={
                        getPredecessor(productionOrders, productionOrder) === productionOrder.id
                      }
                      handleMoveDown={() => moveDown(productionOrder)}
                      disableMoveDown={
                        getSuccessor(productionOrders, productionOrder) === productionOrder.id
                      }
                      handleDelete={() => deleteProductionOrderById(productionOrder.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </PaperTable>
      ) : (
        <Typography>{t('data:productionOrder.noProductionOrders')}</Typography>
      )}
    </Box>
  );
};
