import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { NumericTextField } from '../../../../custom';
import {
  BarcodeType,
  CheckAttribute,
  CheckAttributeSpecification,
  CheckAttributeType,
} from '../../../../model';
import { CheckAttributeImages } from '../check-attribute-selection/check-attribute-select.component';

import { ButtonsFormComponent } from './buttons-form.component';
import { DescriptionFrameComponent } from './description-frame.component';
import { SampleSizeComponent } from './sample-size.component';

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (
    checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
    images?: CheckAttributeImages
  ) => void;
  cancel: () => void;
}

type FormData = {
  checkAttributeName: string;
  specification: CheckAttributeSpecification | '';
  barcodeDescription: string;
  sampleSize: number;
  barcodeType: BarcodeType | '';
  barcode: string;
};

const getFormValues = (checkAttribute?: CheckAttribute): FormData => ({
  checkAttributeName: checkAttribute?.name || '',
  specification: checkAttribute?.specification || '',
  barcodeDescription: checkAttribute?.barcodeDescription || '',
  sampleSize: checkAttribute?.sampleSize || 1,
  barcodeType: checkAttribute?.barcodeType || '',
  barcode: checkAttribute?.barcode || '',
});

const maxDigitsMap: { [key in BarcodeType]: number } = {
  [BarcodeType.GTIN8]: 8,
  [BarcodeType.GTIN13]: 13,
};

export const BarcodeFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);
  const { checkAttribute } = props;

  const methods = useForm<FormData>({
    defaultValues: getFormValues(checkAttribute),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    clearErrors,
  } = methods;

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit({
      checkAttributeType: CheckAttributeType.Barcode,
      name: formData.checkAttributeName,
      specification: CheckAttributeSpecification.Visual,
      sampleSize: Number(formData.sampleSize),
      barcodeDescription: formData.barcodeDescription,
      barcodeType: Number(formData.barcodeType),
      barcode: formData.barcode,
    });
  });

  const renderCount = useRef(0);
  useEffect(() => {
    if (renderCount.current <= 1) renderCount.current = renderCount.current + 1;
  });

  const { barcodeType } = watch();
  useEffect(() => {
    if (renderCount.current > 1) {
      setValue('barcode', '');
      clearErrors('barcode');
    }
  }, [renderCount, barcodeType, setValue, clearErrors]);

  return (
    <FormRoot>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className={formClasses.root}>
          <ContainerOutsideWithHeader>
            <Typography variant="h2">{t('data:checkAttribute.description')}</Typography>
            <Paper>
              <ContainerInside>
                <GridContainer>
                  <GridItem>
                    <GridContainer level={GridLevel.InputPaper}>
                      <DescriptionFrameComponent checkAttributeType={CheckAttributeType.Barcode} />

                      <GridItem s={12} xl={8}>
                        <FormControl error={errors.specification !== undefined} fullWidth={true}>
                          <InputLabel id="barcodeType-label" variant="outlined" required>
                            {t('data:checkAttribute.barcodeType')}
                          </InputLabel>
                          <Controller
                            render={({ field }) => (
                              <Select
                                {...field}
                                data-testid="select-barcodeType"
                                label={t('data:checkAttribute.barcodeType')}
                                required
                                inputProps={{
                                  'data-testid': 'barcodeType-select',
                                }}
                              >
                                {Object.keys(BarcodeType).map((barcodeType: string) =>
                                  Number(barcodeType) ? (
                                    <MenuItem value={barcodeType} key={barcodeType}>
                                      {t([
                                        `data:checkAttribute.barcodeTypeCodes.${barcodeType}`,
                                        `data:checkAttribute.barcodeTypeCodes.notFound`,
                                      ])}
                                    </MenuItem>
                                  ) : null
                                )}
                              </Select>
                            )}
                            control={control}
                            name="barcodeType"
                            rules={{
                              required: { value: true, message: t('form:fieldIsRequired') },
                            }}
                          />
                          {errors.barcodeType && errors.barcodeType.message && (
                            <FormHelperText style={{ marginLeft: '14px', marginRight: '14px' }}>
                              {errors.barcodeType.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </GridItem>

                      {barcodeType && (
                        <GridItem s={12} xl={8}>
                          <Controller
                            render={({ field }) => (
                              <NumericTextField
                                {...field}
                                maxdigits={maxDigitsMap[barcodeType]}
                                fullWidth={true}
                                required
                                label={t('data:checkAttribute.barcode')}
                                inputProps={{
                                  'aria-label': t('data:checkAttribute.barcode'),
                                  'data-testid': 'barcode-input',
                                }}
                                error={errors.barcode !== undefined}
                                helperText={errors.barcode && errors.barcode.message}
                              />
                            )}
                            control={control}
                            defaultValue={''}
                            name="barcode"
                            rules={{
                              required: { value: true, message: t('form:fieldIsRequired') },
                              validate: (value: string) =>
                                Number.isInteger(Number(value))
                                  ? true
                                  : (t('form:integerValue') as string),
                              minLength: {
                                value: maxDigitsMap[barcodeType],
                                message: t('form:exactDigits', {
                                  exact: maxDigitsMap[barcodeType],
                                }),
                              },
                              maxLength: {
                                value: maxDigitsMap[barcodeType],
                                message: t('form:exactDigits', {
                                  exact: maxDigitsMap[barcodeType],
                                }),
                              },
                            }}
                          />
                        </GridItem>
                      )}
                      <GridItem s={12} xl={8}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth={true}
                              label={t('data:checkAttribute.description')}
                              inputProps={{
                                'aria-label': t('data:checkAttribute.description'),
                                'data-testid': 'barcodeDescription-input',
                              }}
                              error={errors.barcodeDescription !== undefined}
                              helperText={
                                errors.barcodeDescription && errors.barcodeDescription.message
                              }
                            />
                          )}
                          control={control}
                          defaultValue={''}
                          name="barcodeDescription"
                          rules={{
                            maxLength: { value: 256, message: t('form:maxLength', { max: '256' }) },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </ContainerOutsideWithHeader>
          <SampleSizeComponent />
          <GridItem>
            <ButtonsFormComponent cancel={props.cancel} />
          </GridItem>
        </form>
      </FormProvider>
    </FormRoot>
  );
};
