import { styled } from '@mui/material/styles';

import { irisCustomColors } from '../../../theme';

const disruptionDimension = 80;
const hypothenuse = Math.sqrt(2 * Math.pow(2 * disruptionDimension, 2));
const hypothenuseAdjusted = hypothenuse - 10;

const PREFIX = 'StyledProductionRunBox';
export const productionRunBoxClasses = {
  paper: `${PREFIX}-paper`,
  verticalBox: `${PREFIX}-verticalBox`,
  detailsBox: `${PREFIX}-detailsBox`,
  imageBox: `${PREFIX}-imageBox`,
  prodInfoBox: `${PREFIX}-prodInfoBox`,
  prodInfoItem: `${PREFIX}-prodInfoItem`,
  reverseFlexRow: `${PREFIX}-reverseFlexRow`,
  prodStateContainer: `${PREFIX}-prodStateContainer`,
  buttonBox: `${PREFIX}-buttonBox`,
  textElement: `${PREFIX}-textElement`,
  messageFlagContainer: `${PREFIX}-messageFlagContainer`,
  messageFlagContent: `${PREFIX}-messageFlagContent`,
  diagonalShadow: `${PREFIX}-diagonalShadow`,
  bold: `${PREFIX}-bold`,
  link: `${PREFIX}-link`,
  arrowIcon: `${PREFIX}-arrowIcon`,
  messageFlagTU1: `${PREFIX}-messageFlagTU1`,
  messageFlagContentTU1: `${PREFIX}-messageFlagContentTU1`,
  productionDetailsBox: `${PREFIX}-productionDetailsBox`,
  textOverflow: `${PREFIX}-textOverflow`,
  averageWeightSymbolSpacing: `${PREFIX}-averageWeightSymbolSpacing`,
  deviationSymbolSpacing: `${PREFIX}-deviationSymbolSpacing`,
};

export const ProductionRunBoxRoot = styled('div')(({ theme }) => ({
  '& .MuiTypography-root.MuiTypography-h2': {
    marginTop: 0,
  },

  [`& .${productionRunBoxClasses.paper}`]: {
    position: 'relative',
  },

  [`& .${productionRunBoxClasses.prodInfoItem}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`& .${productionRunBoxClasses.reverseFlexRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  [`& .${productionRunBoxClasses.prodStateContainer}`]: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 138,
    maxHeight: 138,
    width: '100%',
  },
  [`& .${productionRunBoxClasses.buttonBox}`]: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  [`& .${productionRunBoxClasses.textElement}`]: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [`& .${productionRunBoxClasses.messageFlagContainer}`]: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTop: `${disruptionDimension}px solid ${irisCustomColors.irisRed}`,
    borderRight: `${disruptionDimension}px solid ${irisCustomColors.irisRed}`,
    borderBottom: `${disruptionDimension}px solid transparent`,
    borderLeft: `${disruptionDimension}px solid transparent`,
    borderRadius: 0,
  },
  [`& .${productionRunBoxClasses.messageFlagContent}`]: {
    zIndex: 6,
    position: 'absolute',
    top: 0,
    right: 0,
    color: irisCustomColors.irisWhite,
    height: disruptionDimension * 1.25,
    width: disruptionDimension * 1.25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${productionRunBoxClasses.diagonalShadow}`]: {
    zIndex: 4,
    position: 'absolute',
    top: 0,
    right: 0,
    width: hypothenuseAdjusted,
    boxShadow: `0px 0px 10px 1px ${irisCustomColors.irisBlack}`,
    marginTop: disruptionDimension,
    marginRight: disruptionDimension - hypothenuseAdjusted / 2,
    transform: 'rotate(225deg)',
  },
  [`& .${productionRunBoxClasses.bold}`]: {
    fontWeight: 'bold',
  },
  [`& .${productionRunBoxClasses.link}`]: {
    fontSize: '18px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  [`& .${productionRunBoxClasses.arrowIcon}`]: {
    verticalAlign: 'sub',
    marginLeft: '8px',
    color: irisCustomColors.irisBlueGray,
  },
  [`& .${productionRunBoxClasses.messageFlagTU1}`]: {
    zIndex: 5,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTop: `${disruptionDimension}px solid ${irisCustomColors.irisYellow}`,
    borderRight: `${disruptionDimension}px solid ${irisCustomColors.irisYellow}`,
    borderBottom: `${disruptionDimension}px solid transparent`,
    borderLeft: `${disruptionDimension}px solid transparent`,
    borderRadius: 0,
  },

  [`& .${productionRunBoxClasses.messageFlagContentTU1}`]: {
    zIndex: 6,
    position: 'absolute',
    top: 0,
    right: 0,
    color: irisCustomColors.irisBlack,
    height: disruptionDimension * 1.25,
    width: disruptionDimension * 1.25,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${productionRunBoxClasses.productionDetailsBox}`]: {
    minHeight: 112,
    maxHeight: 112,
  },
  [`& .${productionRunBoxClasses.textOverflow}`]: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  [`& .${productionRunBoxClasses.averageWeightSymbolSpacing}`]: {
    marginRight: 4,
  },
  [`& .${productionRunBoxClasses.deviationSymbolSpacing}`]: {
    marginRight: 4,
    marginLeft: 4,
  },
}));
