import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import { Box, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../../components/components-styles/form.styles';
import { ResultTextField } from '../../../../components/form/result-text-field/result-text-field.component';
import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ResponsiveImageInputPreviewComponent } from '../../../../components/image/responsive-image/responsive-image-input-preview.component';
import { InfoboxComponent } from '../../../../components/infobox/infobox.component';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../../components/structure';
import { CheckAttribute, CheckAttributeSpecification, CheckAttributeType } from '../../../../model';
import { irisCheckResultColors, irisCustomColors } from '../../../../theme';
import { CheckAttributeImages } from '../check-attribute-selection/check-attribute-select.component';

import { ButtonsFormComponent } from './buttons-form.component';
import { DescriptionFrameComponent } from './description-frame.component';
import { SampleSizeComponent } from './sample-size.component';

interface StylePropsWrapper {
  styleProps: {
    columnCount?: number;
    color?: string;
  };
}

const TrafficLightBorder = styled('div', {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ styleProps }) => ({
  borderColor: styleProps.color,
  borderWidth: 5,
  borderRadius: 0,
  borderStyle: 'solid',
}));

const TrafficLightBackgroundIcon = styled(FiberManualRecordRoundedIcon)(() => ({
  color: irisCustomColors.irisWhite,
  fontSize: 80,
  zIndex: 3,
}));

const TrafficLightResultIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(-3),
  marginRight: theme.spacing(0.5),
}));

const TrafficLightResultIcon = styled(CheckIcon)(() => ({
  backgroundColor: irisCustomColors.irisWhite,
  zIndex: 4,
}));

const TrafficLightCloseIcon = styled(CloseIcon)(() => ({
  backgroundColor: irisCustomColors.irisWhite,
  zIndex: 4,
}));

const TrafficLightResultIconBackgroundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(-6),
  marginRight: theme.spacing(-3.25),
}));

enum Answer {
  Green,
  Yellow,
  Red,
}

interface ImageProps {
  changeImage: React.Dispatch<React.SetStateAction<File | undefined>>;
  chosenImage: File | undefined;
  removeImage: () => void;
  imageUrl: string | undefined;
  labelId: string | undefined;
}

export interface OwnProps {
  checkAttribute?: CheckAttribute;
  submit: (
    checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>,
    images?: CheckAttributeImages
  ) => void;
  cancel: () => void;
}

type FormData = {
  checkAttributeName: string;
  specification: CheckAttributeSpecification | '';
  trafficLightDescription: string;
  trafficLightGreenDescription: string;
  trafficLightYellowDescription: string;
  trafficLightRedDescription: string;
  trafficLightValues: string;
  sampleSize: number;
};

const getFormValues = (checkAttribute?: CheckAttribute): FormData => ({
  checkAttributeName: checkAttribute?.name || '',
  specification: checkAttribute?.specification || '',
  trafficLightDescription: checkAttribute?.trafficLightDescription || '',
  trafficLightGreenDescription: checkAttribute?.trafficLightGreenDescription || '',
  trafficLightYellowDescription: checkAttribute?.trafficLightYellowDescription || '',
  trafficLightRedDescription: checkAttribute?.trafficLightRedDescription || '',
  trafficLightValues: '',
  sampleSize: checkAttribute?.sampleSize || 1,
});

export const TrafficLightFormComponent = (props: OwnProps) => {
  const { t } = useTranslation(['form', 'data']);
  const { checkAttribute } = props;

  const [trafficLightGreenImageUrl, setTrafficLightGreenImageUrl] = useState<string | undefined>(
    checkAttribute?.trafficLightGreenImageUrl
  );
  const [trafficLightYellowImageUrl, setTrafficLightYellowImageUrl] = useState<string | undefined>(
    checkAttribute?.trafficLightYellowImageUrl
  );
  const [trafficLightRedImageUrl, setTrafficLightRedImageUrl] = useState<string | undefined>(
    checkAttribute?.trafficLightRedImageUrl
  );
  const [chosenTrafficLightGreenImageFile, setChosenTrafficLightGreenImageFile] = useState<
    File | undefined
  >();
  const [chosenTrafficLightYellowImageFile, setChosenTrafficLightYellowImageFile] = useState<
    File | undefined
  >();
  const [chosenTrafficLightRedImageFile, setChosenTrafficLightRedImageFile] = useState<
    File | undefined
  >();

  const methods = useForm<FormData>({
    defaultValues: getFormValues(checkAttribute),
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = methods;

  const removeTrafficLightGreenImage = () => {
    setTrafficLightGreenImageUrl(undefined);
    setChosenTrafficLightGreenImageFile(undefined);
  };

  const removeTrafficLightYellowImage = () => {
    setTrafficLightYellowImageUrl(undefined);
    setChosenTrafficLightYellowImageFile(undefined);
  };

  const removeTrafficLightRedImage = () => {
    setTrafficLightRedImageUrl(undefined);
    setChosenTrafficLightRedImageFile(undefined);
  };

  const validateTrafficLightValues = (): boolean => {
    const formData = getValues();

    const hasADescription =
      formData.trafficLightGreenDescription ||
      formData.trafficLightRedDescription ||
      formData.trafficLightYellowDescription;

    const isValid =
      hasADescription ||
      trafficLightRedImageUrl ||
      chosenTrafficLightRedImageFile ||
      trafficLightGreenImageUrl ||
      chosenTrafficLightGreenImageFile ||
      trafficLightYellowImageUrl ||
      chosenTrafficLightYellowImageFile;

    const returnValue = !!isValid || false;
    return returnValue;
  };

  const onSubmit = handleSubmit((formData: FormData) => {
    if (formData.specification) {
      props.submit(
        {
          checkAttributeType: CheckAttributeType.TrafficLight,
          name: formData.checkAttributeName,
          specification: Number(formData.specification),
          sampleSize: Number(formData.sampleSize),
          trafficLightDescription: formData.trafficLightDescription,
          trafficLightGreenDescription: formData.trafficLightGreenDescription,
          trafficLightYellowDescription: formData.trafficLightYellowDescription,
          trafficLightRedDescription: formData.trafficLightRedDescription,
          trafficLightGreenImageUrl: trafficLightGreenImageUrl,
          trafficLightYellowImageUrl: trafficLightYellowImageUrl,
          trafficLightRedImageUrl: trafficLightRedImageUrl,
        },
        {
          trafficLightGreenImage: chosenTrafficLightGreenImageFile,
          trafficLightYellowImage: chosenTrafficLightYellowImageFile,
          trafficLightRedImage: chosenTrafficLightRedImageFile,
        }
      );
    }
  });

  const getHeadlineDescription = (answer: Answer) => {
    switch (answer) {
      case Answer.Green:
        return t('data:checkAttribute.trafficLightGreenDescription');
      case Answer.Yellow:
        return t('data:checkAttribute.trafficLightYellowDescription');
      case Answer.Red:
        return t('data:checkAttribute.trafficLightRedDescription');
    }
  };

  const getHeadlineColumn = (answer: Answer) => {
    const description = getHeadlineDescription(answer);

    return <Typography variant="h3">{description}</Typography>;
  };

  const getHeadlineRow = () => {
    return (
      <GridContainer level={GridLevel.InputPaper}>
        <GridItem s={4}>{getHeadlineColumn(Answer.Green)}</GridItem>
        <GridItem s={4}>{getHeadlineColumn(Answer.Yellow)}</GridItem>
        <GridItem s={4}>{getHeadlineColumn(Answer.Red)}</GridItem>
      </GridContainer>
    );
  };

  const getBorderColor = (answer: Answer) => {
    switch (answer) {
      case Answer.Green:
        return irisCheckResultColors.passed;
      case Answer.Yellow:
        return irisCheckResultColors.sufficient;
      case Answer.Red:
        return irisCheckResultColors.failed;
    }
  };

  const getResultIcon = (answer: Answer) => {
    switch (answer) {
      case Answer.Green:
        return (
          <TrafficLightResultIconContainer>
            <TrafficLightResultIcon style={{ color: irisCheckResultColors.passed }} />
          </TrafficLightResultIconContainer>
        );
      case Answer.Yellow:
        return (
          <TrafficLightResultIconContainer>
            <TrafficLightResultIcon style={{ color: irisCheckResultColors.sufficient }} />
          </TrafficLightResultIconContainer>
        );
      case Answer.Red:
        return (
          <TrafficLightResultIconContainer>
            <TrafficLightCloseIcon style={{ color: irisCheckResultColors.failed }} />
          </TrafficLightResultIconContainer>
        );
    }
  };

  const getImageProps = (answer: Answer) => {
    let imageProps: ImageProps;
    switch (answer) {
      case Answer.Green:
        imageProps = {
          changeImage: setChosenTrafficLightGreenImageFile,
          chosenImage: chosenTrafficLightGreenImageFile,
          removeImage: removeTrafficLightGreenImage,
          imageUrl: trafficLightGreenImageUrl,
          labelId: 'greenImageInput',
        };
        break;
      case Answer.Yellow:
        imageProps = {
          changeImage: setChosenTrafficLightYellowImageFile,
          chosenImage: chosenTrafficLightYellowImageFile,
          removeImage: removeTrafficLightYellowImage,
          imageUrl: trafficLightYellowImageUrl,
          labelId: 'yellowImageInput',
        };
        break;
      case Answer.Red:
        imageProps = {
          changeImage: setChosenTrafficLightRedImageFile,
          chosenImage: chosenTrafficLightRedImageFile,
          removeImage: removeTrafficLightRedImage,
          imageUrl: trafficLightRedImageUrl,
          labelId: 'redImageInput',
        };
        break;
    }
    return imageProps;
  };

  const getImageColumn = (answer: Answer) => {
    const borderColor = getBorderColor(answer);
    const resultIcon = getResultIcon(answer);
    const imageProps = getImageProps(answer);

    return (
      <>
        <TrafficLightResultIconBackgroundContainer>
          <TrafficLightBackgroundIcon />
        </TrafficLightResultIconBackgroundContainer>
        {resultIcon}
        <TrafficLightBorder styleProps={{ color: borderColor }}>
          <ResponsiveImageInputPreviewComponent
            changeImage={imageProps.changeImage}
            chosenImageFile={imageProps.chosenImage}
            removeImage={imageProps.removeImage}
            imageUrl={imageProps.imageUrl}
            labelId={imageProps.labelId}
          />
        </TrafficLightBorder>
      </>
    );
  };

  const getImageRow = () => {
    return (
      <GridContainer level={GridLevel.InputPaper}>
        <GridItem s={4}>{getImageColumn(Answer.Green)}</GridItem>
        <GridItem s={4}>{getImageColumn(Answer.Yellow)}</GridItem>
        <GridItem s={4}>{getImageColumn(Answer.Red)}</GridItem>
      </GridContainer>
    );
  };

  const getDescriptionName = (answer: Answer) => {
    switch (answer) {
      case Answer.Green:
        return 'trafficLightGreenDescription';
      case Answer.Yellow:
        return 'trafficLightYellowDescription';
      case Answer.Red:
        return 'trafficLightRedDescription';
    }
  };

  const getErrors = (answer: Answer) => {
    switch (answer) {
      case Answer.Green:
        return errors.trafficLightGreenDescription;
      case Answer.Yellow:
        return errors.trafficLightYellowDescription;
      case Answer.Red:
        return errors.trafficLightRedDescription;
    }
  };

  const getDescriptionColumn = (answer: Answer) => {
    const borderColor = getBorderColor(answer);
    const description = getDescriptionName(answer);
    const error = getErrors(answer);
    const resultIcon = getResultIcon(answer);

    return (
      <>
        <TrafficLightResultIconBackgroundContainer>
          <TrafficLightBackgroundIcon />
        </TrafficLightResultIconBackgroundContainer>
        {resultIcon}
        <Controller
          render={({ field }) => (
            <ResultTextField
              {...field}
              bordercolor={borderColor}
              placeholder={t('form:addDescription')}
              multiline
              rows={5}
              fullWidth
              inputProps={{
                'data-testid': `${description}-input`,
              }}
              error={error !== undefined}
              helperText={error && error.message}
            />
          )}
          control={control}
          name={description}
          rules={{
            maxLength: {
              value: 1000,
              message: t('form:maxLength', { max: '1000' }),
            },
          }}
        />
      </>
    );
  };

  const getDescriptionRow = () => {
    return (
      <GridContainer level={GridLevel.InputPaper}>
        <GridItem s={4}>{getDescriptionColumn(Answer.Green)}</GridItem>
        <GridItem s={4}>{getDescriptionColumn(Answer.Yellow)}</GridItem>
        <GridItem s={4}>{getDescriptionColumn(Answer.Red)}</GridItem>
      </GridContainer>
    );
  };

  return (
    <FormRoot>
      <FormProvider {...methods}>
        <form onSubmit={onSubmit} className={formClasses.root}>
          <ContainerOutsideWithHeader>
            <Typography variant="h2">{t('data:checkAttribute.description')}</Typography>
            <Paper>
              <ContainerInside>
                <GridContainer>
                  <GridItem>
                    <GridContainer level={GridLevel.InputPaper}>
                      <DescriptionFrameComponent
                        checkAttributeType={CheckAttributeType.TrafficLight}
                      />
                      <GridItem s={12} xl={8}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth={true}
                              label={t('data:checkAttribute.description')}
                              inputProps={{
                                'aria-label': t('data:checkAttribute.description'),
                                'data-testid': 'trafficLightDescription-input',
                              }}
                              error={errors.trafficLightDescription !== undefined}
                              helperText={
                                errors.trafficLightDescription &&
                                errors.trafficLightDescription.message
                              }
                            />
                          )}
                          defaultValue={''}
                          control={control}
                          name="trafficLightDescription"
                          rules={{
                            maxLength: {
                              value: 256,
                              message: t('form:maxLength', { max: '256' }),
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </ContainerOutsideWithHeader>
          <ContainerOutsideWithHeader>
            <Typography variant="h2">{t('data:checkAttribute.possibleResults')}</Typography>
            <Paper>
              <ContainerInside>
                <GridContainer level={GridLevel.InputPaper}>
                  <Controller
                    name="trafficLightValues"
                    control={control}
                    defaultValue={' '}
                    render={({ field }) => <input {...field} type="hidden" />}
                    rules={{
                      validate: () =>
                        validateTrafficLightValues() ||
                        (t('data:checkAttribute.trafficLightValuesNotValid') as string),
                    }}
                  />
                  {errors.trafficLightValues && errors.trafficLightValues.message && (
                    <GridItem>
                      <InfoboxComponent headline={errors.trafficLightValues.message} type="error" />
                    </GridItem>
                  )}
                  <GridItem>
                    {getHeadlineRow()}
                    {getImageRow()}
                    {getDescriptionRow()}
                  </GridItem>
                </GridContainer>
              </ContainerInside>
            </Paper>
          </ContainerOutsideWithHeader>
          <SampleSizeComponent />
          <GridItem>
            <ButtonsFormComponent cancel={props.cancel} />
          </GridItem>
        </form>
      </FormProvider>
    </FormRoot>
  );
};
