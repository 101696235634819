import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { FormHelperText, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import noImagePlaceholder from '../../../assets/img/no-image-placeholder.svg';
import { IMAGE_RATIO, MAX_IMG_SIZE } from '../../../constants';
import { useImageLoader } from '../../../hooks/image-loader.hook';
import { backendUrl } from '../../../http';
import { irisCustomColors } from '../../../theme';

export interface ResponsiveProps {
  changeImage: (file: File | undefined) => void;
  chosenImageFile?: File | undefined;
  removeImage: () => void;
  imageUrl?: string;
  labelId?: string;
  flexible?: boolean;
  altText?: string;
}

const CenteringContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: 0,
}));

const LimitingContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 650,
  maxHeight: 650 / IMAGE_RATIO,
}));

const RatioContainer = styled('div')(({ theme }) => ({
  width: '100%',
  aspectRatio: `${IMAGE_RATIO}`,
}));

const FillingImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

const ImageInput = styled('input')(({ theme }) => ({
  display: 'none',
}));

const ButtonsContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': {
    margin: theme.spacing(1),
  },
  '& .MuiIconButton-root': {
    color: irisCustomColors.irisBlack,
    backgroundColor: `${irisCustomColors.irisWhite}99`,
    height: '80px',
    width: '80px',
  },
  '& .MuiIconButton-root:hover': {
    backgroundColor: `${irisCustomColors.irisWhite}77`,
  },
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
  },
}));

const FlexibleContainer = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  position: 'relative',
}));

export const ResponsiveImageInputPreviewComponent = (props: ResponsiveProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { imageUrl, chosenImageFile, removeImage, labelId, altText } = props;
  const loadedImage = useImageLoader(chosenImageFile);
  const inputName = labelId || 'imageInput';
  const errorMessage = errors[inputName]?.message;
  const { t } = useTranslation(['form']);

  const getImageSrc = () =>
    loadedImage ? loadedImage : imageUrl ? `${backendUrl}${imageUrl}` : noImagePlaceholder;

  const uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const { changeImage: onInputChange } = props;
      onInputChange && onInputChange(e.target.files[0]);
    }
  };

  return (
    <CenteringContainer>
      <LimitingContainer>
        <RatioContainer>
          <ImageInput
            type="file"
            accept="image/*"
            title=""
            id={inputName}
            {...register(inputName, {
              validate: {
                maxImageSize: (value) => {
                  if (props.chosenImageFile) {
                    return (
                      props.chosenImageFile.size <= MAX_IMG_SIZE ||
                      (t('form:maxImageSize') as string)
                    );
                  }
                  return true;
                },
              },
            })}
            data-testid={inputName}
            onChange={(event) => {
              uploadFile(event);
              event.target.value = '';
            }}
            name={inputName}
          />
          <FlexibleContainer>
            <FillingImage src={getImageSrc()} alt={altText || t('form:image')} />
            <ButtonsContainer>
              {imageUrl || chosenImageFile ? (
                <>
                  <label htmlFor={labelId ? labelId : 'imageInput'}>
                    <IconButton
                      className="chosenImage"
                      data-testid="editImgBtn"
                      component="span"
                      title={t('form:editImage')}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </label>
                  <IconButton
                    className="chosenImage"
                    data-testid="removeImgBtn"
                    onClick={removeImage}
                    title={t('form:removeImage')}
                    size="large"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </>
              ) : (
                <label htmlFor={inputName}>
                  <IconButton
                    data-testid="newImgBtn"
                    title={t('form:newImage')}
                    component="span"
                    size="large"
                  >
                    <AddAPhotoIcon />
                  </IconButton>
                </label>
              )}
            </ButtonsContainer>
          </FlexibleContainer>
          {errorMessage && <FormHelperText error={true}>{errorMessage}</FormHelperText>}
        </RatioContainer>
      </LimitingContainer>
    </CenteringContainer>
  );
};
