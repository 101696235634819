import { Box, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { ContainerInside } from '../../../components/structure';
import { ProductionRun } from '../../../model';
import { getPixels, irisSpacing } from '../../../theme';

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

const OpenCheckBox = styled(Box)(({ theme }) => ({
  height: 100 - getPixels(irisSpacing.container.space) * 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CheckCountTextBox = styled(Box)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 40,
  paddingRight: theme.spacing(irisSpacing.container.p),
}));

const MinContentTypography = styled(Typography)(({ theme }) => ({
  width: 'min-content',
}));

interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunOpenChecksComponent = (props: OwnProps) => {
  const { productionRun } = props;
  const { t } = useTranslation('data');

  return (
    <StyledPaper data-testid="ProductionRunOpenChecksComponent">
      <ContainerInside>
        <OpenCheckBox>
          <CheckCountTextBox data-testid="checkCount">
            {productionRun.openCheckExecutions.length
              ? productionRun.openCheckExecutions.length
              : 0}
          </CheckCountTextBox>
          <MinContentTypography>
            {productionRun.openCheckExecutions.length === 1
              ? t('data:productionRun.openCheckExecution')
              : t('data:productionRun.openCheckExecutions')}
          </MinContentTypography>
        </OpenCheckBox>
      </ContainerInside>
    </StyledPaper>
  );
};
