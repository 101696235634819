import { Box, Paper, TextField, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GridContainer, GridLevel } from '../../../../components/grid/grid.container';
import { GridItem } from '../../../../components/grid/grid.item';
import { ContainerInside } from '../../../../components/structure';
import { ToleranceBarComponent } from '../../../../components/tolerance-bar/tolerance-bar.component';
import { MeasurementOption, ToleranceResult } from '../../../../model';

import { articleFormClasses } from './article-form.component.styles';

export interface OwnProps {
  measurementType: MeasurementOption;
  currentTolerances?: ToleranceResult;
  lowerTolerance?: number;
  upperTolerance?: number;
}

export const ArticleFormBaseSettingsComponent = (props: OwnProps) => {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();
  const { t } = useTranslation(['data']);
  const { currentTolerances, measurementType, lowerTolerance, upperTolerance } = props;

  const { volumeValue, nominalValue, nominalValueVolume, measurementUnit } = watch();

  return (
    <>
      <Typography variant="h2">{t('data:article.checkSettings')}</Typography>
      <GridContainer level={GridLevel.Page}>
        <GridItem xl={8}>
          <Paper className={articleFormClasses.equalHeightContainer} data-testid="fpvoPaper">
            <ContainerInside>
              <Typography variant="h3">{t('data:article.FertigPackV')}</Typography>
              <ToleranceBarComponent
                nominalValue={
                  nominalValue &&
                  nominalValue !== '' &&
                  measurementType === MeasurementOption.Weight
                    ? +nominalValue
                    : nominalValueVolume &&
                      nominalValueVolume !== '' &&
                      volumeValue &&
                      measurementType === MeasurementOption.Volume
                    ? +volumeValue
                    : undefined
                }
                tolerances={currentTolerances}
                lowerTolerance={lowerTolerance}
                upperTolerance={upperTolerance}
                hasVolumeValues={measurementType === MeasurementOption.Volume}
                measurementUnit={measurementUnit}
              />
            </ContainerInside>
          </Paper>
        </GridItem>

        <GridItem xl={4}>
          <Paper className={articleFormClasses.equalHeightContainer}>
            <ContainerInside>
              <Typography variant="h3">{t('data:article.checkPeriodicity')}</Typography>
              <Box display="flex" alignItems="center">
                <Box mr={2}>{t('data:article.repeatCheck_A')}</Box>
                <Box className={articleFormClasses.checkPeriodicityItemContainer}>
                  <Box className={articleFormClasses.checkPeriodicityItem}>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth={true}
                          required
                          label={t('data:article.minutes')}
                          inputProps={{ 'aria-label': t('data:article.checkPeriodicity') }}
                          error={errors.checkPeriodicity !== undefined}
                        />
                      )}
                      control={control}
                      name="checkPeriodicity"
                      rules={{
                        required: { value: true, message: t('form:fieldIsRequired') },
                        validate: (value: string) =>
                          Number.isInteger(Number(value))
                            ? true
                            : (t('form:integerValue') as string),
                        min: { value: 5, message: t('form:minValue', { min: 5 }) },
                        max: { value: 480, message: t('form:maxValue', { max: 480 }) },
                      }}
                    />
                  </Box>
                  {errors.checkPeriodicity && errors.checkPeriodicity.message && (
                    <Box className={articleFormClasses.validationError}>
                      {errors.checkPeriodicity.message}
                    </Box>
                  )}
                </Box>
                <Box ml={2}>{t('data:article.repeatCheck_B')}</Box>
              </Box>
            </ContainerInside>
          </Paper>
        </GridItem>
      </GridContainer>
    </>
  );
};
