import { produce } from 'immer';
import { Action } from 'redux';

import { Check, CheckAttributeType } from '../../model';

import {
  addItemToArray,
  deleteItemFromArray,
  swapArrayItems,
  updateItemInArray,
} from './../reducer-utils';
import {
  ChecksActionType,
  fetchChecksForArticleSuccess,
  fetchCheckForArticleSuccess,
  addCheckForArticleSuccess,
  editCheckForArticleSuccess,
  deleteCheckFromArticleSuccess,
  changeCheckOrderForArticleSuccess,
} from './checks.actions';

export interface ChecksState {
  checksForArticle?: Check[];
  currentCheckForArticle?: Check;
  isAddCheckDialogOpen: boolean;
  isEditCheckDialogOpen: boolean;
}

export const getInitialState = (): ChecksState => {
  return {
    isAddCheckDialogOpen: false,
    isEditCheckDialogOpen: false,
  };
};

export const hasFertigPackChecksInState = (stateArray?: Check[]) => {
  if (!stateArray) return false;
  const itemIndex = stateArray.findIndex(
    (item: Check) => item.checkAttribute.checkAttributeType === CheckAttributeType.FertigPackV
  );
  return itemIndex !== -1;
};

const clearChecksForArticleInState = (previousState: ChecksState) => {
  return produce(previousState, (draftState) => {
    delete draftState.checksForArticle;
  });
};

export const checksReducer = (
  previousState: ChecksState = getInitialState(),
  action: Action<ChecksActionType>
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case ChecksActionType.checksFetchAllForArticle:
      nextState = produce(previousState, (draftState) => {
        delete draftState.checksForArticle;
      });
      break;
    case ChecksActionType.checksFetchAllForArticleSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchChecksForArticleSuccess>;
        draftState.checksForArticle = fetchSuccessAction.payload.checks;
      });
      break;
    case ChecksActionType.checkFetchForArticle:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentCheckForArticle;
      });
      break;
    case ChecksActionType.checkFetchForArticleSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchCheckForArticleSuccess>;
        draftState.currentCheckForArticle = fetchSuccessAction.payload.check;
      });
      break;
    case ChecksActionType.checkAddForArticleSuccess:
      nextState = produce(previousState, (draftState) => {
        const addSuccessAction = action as ReturnType<typeof addCheckForArticleSuccess>;
        draftState.checksForArticle = addItemToArray(
          draftState.checksForArticle,
          addSuccessAction.payload.check
        );
        draftState.isAddCheckDialogOpen = false;
      });
      break;
    case ChecksActionType.checkEditForArticleSuccess:
      nextState = produce(previousState, (draftState) => {
        const editSuccessAction = action as ReturnType<typeof editCheckForArticleSuccess>;
        draftState.checksForArticle = updateItemInArray(
          draftState.checksForArticle,
          editSuccessAction.payload.check
        );
        draftState.isEditCheckDialogOpen = false;
      });
      break;
    case ChecksActionType.checkDeleteFromArticleSuccess:
      nextState = produce(previousState, (draftState) => {
        const deleteSuccessAction = action as ReturnType<typeof deleteCheckFromArticleSuccess>;
        draftState.checksForArticle = deleteItemFromArray(
          draftState.checksForArticle,
          deleteSuccessAction.payload.checkId
        );
      });
      break;
    case ChecksActionType.checkChangeOrderForArticleSuccess:
      nextState = produce(previousState, (draftState) => {
        const changeOrderSuccessAction = action as ReturnType<
          typeof changeCheckOrderForArticleSuccess
        >;
        draftState.checksForArticle = swapArrayItems(
          draftState.checksForArticle,
          changeOrderSuccessAction.payload.sourceCheckId,
          changeOrderSuccessAction.payload.destinationCheckId
        );
      });
      break;
    case ChecksActionType.checkOpenAddCheckDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isAddCheckDialogOpen = true;
      });
      break;
    case ChecksActionType.checkCloseAddCheckDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isAddCheckDialogOpen = false;
      });
      break;
    case ChecksActionType.checkOpenEditCheckDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isEditCheckDialogOpen = true;
      });
      break;
    case ChecksActionType.checkCloseEditCheckDialog:
      nextState = produce(previousState, (draftState) => {
        draftState.isEditCheckDialogOpen = false;
      });
      break;
    case ChecksActionType.checksForArticleClear:
      nextState = clearChecksForArticleInState(previousState);
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
