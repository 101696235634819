import { Box, Paper } from '@mui/material';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { GridContainer, GridLevel } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { MessageFlagComponent } from '../../../components/message-flag/message-flag.component';
import { ProductionRunDetailsResponsiveComponent } from '../../../components/production-details/production-details-responsive.component';
import { ContainerInside, ContainerOutside } from '../../../components/structure';
import { TableData } from '../../../components/table/description-table.component';
import { MILLIS_SECOND } from '../../../constants';
import { hasIssue } from '../../../helper';
import {
  getTableDataArticle,
  getTableDataBatchNumber,
  getTableDataOrderAmount,
  getTableDataStartTime,
} from '../../../helper/table-data/table-data.helper';
import { IssueCode, ProductionRun, ProductionRunStatus } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  productionRunRunningSelector,
  fetchProductionRunRunning,
  startWsConnection,
  productionRunsWebsocketConfig,
  calcArticleFertigPackTolerances,
  clearProductionRunCheckResults,
  clearProductionRunRunning,
  fetchSystemTime,
  serverTimeDeltaSelector,
} from '../../../store';
import { getDurationMinutes } from '../../../utils/date-utils';

import { ProductionRunRunningContentComponent } from './production-run-content.component';
import { ViolationFormComponent } from './production-run-violation.component';

export const ProductionRunRunningPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const productionRunId = id;
  useEffect(() => {
    if (productionRunId) {
      dispatch(fetchProductionRunRunning(productionRunId));
      dispatch(startWsConnection(productionRunsWebsocketConfig));
    }
    return () => {
      dispatch(clearProductionRunRunning());
      dispatch(clearProductionRunCheckResults());
    };
  }, [dispatch, productionRunId]);
  const { t } = useTranslation(['common', 'data']);
  const productionRun = useSelector(productionRunRunningSelector);
  const serverTimeDelta = useSelector(serverTimeDeltaSelector);
  const [clientTime, setClientTime] = useState<Date>(new Date());
  const [currentTime, setCurrentTime] = useState<Date | undefined>(undefined);
  const [disruptionMinutes, setDisruptionMinutes] = useState<number | undefined>(undefined);

  function refreshTime() {
    setClientTime(new Date());
  }

  useEffect(() => {
    refreshTime();
    const timerId = setInterval(refreshTime, 10 * MILLIS_SECOND);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    const adjustedTime = serverTimeDelta
      ? new Date(clientTime.valueOf() + serverTimeDelta)
      : clientTime;
    setCurrentTime(adjustedTime);
  }, [serverTimeDelta, clientTime]);

  useEffect(() => {
    if (productionRun?.article.nominalValue && productionRun?.article.densityValue) {
      dispatch(
        calcArticleFertigPackTolerances(
          Number(productionRun.article.measurementUnit),
          Number(productionRun.article.nominalValue),
          Number(productionRun.article.densityValue)
        )
      );
    } else if (productionRun?.article.nominalValue)
      dispatch(
        calcArticleFertigPackTolerances(
          Number(productionRun.article.measurementUnit),
          Number(productionRun.article.nominalValue)
        )
      );
    if (productionRun?.disrupted) dispatch(fetchSystemTime());
  }, [dispatch, productionRun]);

  useEffect(() => {
    if (productionRun) {
      const started: Date | undefined =
        productionRun.disrupted &&
        productionRun.disruptions.length > 0 &&
        !productionRun.disruptions[0].resolved
          ? new Date(productionRun.disruptions[0].started)
          : undefined;

      if (started && currentTime) {
        const minutes = getDurationMinutes(started, currentTime);
        setDisruptionMinutes(minutes);
      }
    }
  }, [currentTime, productionRun]);

  const getProductionRunTableData = (productionRun: ProductionRun, t: TFunction): TableData[] => {
    return [
      getTableDataStartTime(productionRun, t),
      getTableDataOrderAmount(productionRun, t),
      getTableDataArticle(productionRun, t),
      getTableDataBatchNumber(productionRun, t),
    ];
  };

  return (
    <Box data-testid="ProductionRunRunningPage">
      <DetailLayoutComponent
        backLinkText={t('data:productionRun.backToOverview')}
        backLinkTarget={`/${AppRoutePath.production}/${AppRoutePath.runs}/${AppRoutePath.running}/`}
        headlineText={''}
      >
        {productionRun && (
          <>
            <GridContainer level={GridLevel.InputPaper}>
              {(!!productionRun.fertigPackVData.hasCriticalTU1Violation ||
                !!productionRun.fertigPackVData.hasCriticalAverageWeightBelowNominalViolation ||
                hasIssue(productionRun, IssueCode.FertigPackVTU2Violation)) &&
                productionRun.status === ProductionRunStatus.Paused && (
                  <GridItem>
                    <ViolationFormComponent productionRun={productionRun} />
                  </GridItem>
                )}
              <GridItem>
                <Paper sx={{ position: 'relative' }}>
                  <ContainerInside>
                    <MessageFlagComponent
                      productionRun={productionRun}
                      disruptionMinutes={disruptionMinutes}
                    />
                    <ProductionRunDetailsResponsiveComponent
                      tableData={getProductionRunTableData(productionRun, t)}
                      productionRun={productionRun}
                    />
                  </ContainerInside>
                </Paper>
              </GridItem>
            </GridContainer>
            <ContainerOutside>
              <ProductionRunRunningContentComponent productionRun={productionRun} />
            </ContainerOutside>
          </>
        )}
      </DetailLayoutComponent>
    </Box>
  );
};
