import { useTranslation } from 'react-i18next';

import { GridContainer } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { getFertigPackVStatistics } from '../../../helper';
import { ProductionRun } from '../../../model';
import { FertigPackVCategoriesComponent } from '../production-run/fertigpackv-categories.component';
import { FertigPackVGiveAwayComponent } from '../production-run/fertigpackv-give-away.component';
import { ProductionRunAverageWeightComponent } from '../production-run/production-run-average-weight.component';
import { ProductionRunPieChartComponent } from '../production-run/production-run-pie-chart.component';

import { ProductionRunFertigPackVStatisticsComponent } from './production-run-fertigpackv-statistics';
import { ProductionRunOpenChecksComponent } from './production-run-open-checks';

interface OwnProps {
  productionRun: ProductionRun;
}

export const ProductionRunRunningOverviewTab = (props: OwnProps) => {
  const { productionRun } = props;
  const { t } = useTranslation(['common', 'data']);
  const statistics = getFertigPackVStatistics(productionRun);
  const hasFertigPackVWeight =
    !!statistics?.checkSamplesAverageWeight || !!statistics?.checkSamplesAverageVolume;
  const hasFertigPackVSamples = !!statistics?.checkSamplesDone;

  return (
    <GridContainer data-testid="OverviewTab">
      <GridItem
        s={statistics?.checkSamplesAverageWeight || statistics?.checkSamplesAverageVolume ? 4 : 12}
      >
        <ProductionRunOpenChecksComponent productionRun={productionRun} />
      </GridItem>
      {(statistics?.checkSamplesAverageWeight || statistics?.checkSamplesAverageVolume) && (
        <GridItem s={8}>
          <ProductionRunFertigPackVStatisticsComponent productionRun={productionRun} />
        </GridItem>
      )}
      <GridItem>
        <ProductionRunPieChartComponent
          productionRun={productionRun}
          noCheckResultsText={t('data:productionRun.noCurrentCheckResults')}
        />
      </GridItem>
      {hasFertigPackVWeight && (
        <GridItem>
          <ProductionRunAverageWeightComponent
            headlineText={t('data:productionRun.fertigPackVCurrentAverageResult')}
            productionRun={productionRun}
          />
        </GridItem>
      )}
      {hasFertigPackVSamples && (
        <>
          <GridItem>
            <FertigPackVCategoriesComponent productionRun={productionRun} />
          </GridItem>
          <GridItem>
            <FertigPackVGiveAwayComponent productionRun={productionRun} />
          </GridItem>
        </>
      )}
    </GridContainer>
  );
};
