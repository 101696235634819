import { produce } from 'immer';
import { Action } from 'redux';

import { CheckAttributeType } from '../../model';

import {
  CheckAttributeTypesActionType,
  fetchCheckAttributeTypesSuccess,
} from './check-attribute-types.actions';

export interface CheckAttributeTypesState {
  checkAttributeTypes?: CheckAttributeType[];
}

export const getInitialState = (): CheckAttributeTypesState => {
  return {};
};

export const checkAttributeTypesReducer = (
  previousState: CheckAttributeTypesState = getInitialState(),
  action: Action<CheckAttributeTypesActionType>
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case CheckAttributeTypesActionType.checkAttributeTypesFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchCheckAttributeTypesSuccess>;
        const { ids } = fetchSuccessAction.payload.checkAttributeTypesList;
        draftState.checkAttributeTypes = ids;
      });
      break;
    default:
      nextState = previousState;
  }

  return nextState;
};
