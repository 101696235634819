import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getFertigPackVStatistics, getSuffix } from '../../helper';
import { MeasurementUnit, ProductionRun } from '../../model';
import { articleFertigPackTolerancesSelector, calcArticleFertigPackTolerances } from '../../store';
import { FormattedNumberOutputComponent } from '../form/formatted-number-output/formatted-number-output.component';

import { ToleranceBarComponent } from './tolerance-bar.component';

const PREFIX = 'ToleranceBarInfoComponent';
const classes = {
  outerBox: `${PREFIX}-outerBox`,
  resultBox: `${PREFIX}-resultBox`,
  infoBox: `${PREFIX}-infoBox`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.outerBox}`]: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
    flexDirection: 'column',
  },

  [`& .${classes.resultBox}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.infoBox}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
  },
}));

export interface OwnProps {
  headlineText: string;
  productionRun: ProductionRun;
  forPrint?: boolean;
}

export const ToleranceBarInfoComponent = (props: OwnProps) => {
  const dispatch = useDispatch();
  const { headlineText, productionRun, forPrint } = props;
  const { t } = useTranslation(['data']);
  const statistics = getFertigPackVStatistics(productionRun);

  const currentTolerances = useSelector(articleFertigPackTolerancesSelector);
  const nominalValue = productionRun.article.nominalValue;
  const volumeValue = productionRun.article.volumeValue;
  const densityValue = productionRun.article.densityValue;
  const measurementUnit = productionRun.article.measurementUnit
    ? productionRun.article.measurementUnit
    : MeasurementUnit.Gram;
  const suffix = getSuffix(
    !!productionRun.article.volumeValue,
    productionRun.article.measurementUnit
  );
  const averageValue = statistics?.checkSamplesAverageVolume
    ? statistics?.checkSamplesAverageVolume
    : statistics?.checkSamplesAverageWeight;
  const standardDeviation = statistics?.checkSamplesStandardVolumeDeviation
    ? statistics?.checkSamplesStandardVolumeDeviation
    : statistics?.checkSamplesStandardDeviation;
  const hasAverage =
    !!statistics?.checkSamplesAverageWeight || !!statistics?.checkSamplesAverageVolume;
  const hasDeviation =
    !!statistics?.checkSamplesStandardDeviation ||
    !!statistics?.checkSamplesStandardVolumeDeviation;

  useEffect(() => {
    if (densityValue && nominalValue) {
      dispatch(
        calcArticleFertigPackTolerances(
          Number(measurementUnit),
          Number(nominalValue),
          Number(densityValue)
        )
      );
    } else if (nominalValue) {
      dispatch(calcArticleFertigPackTolerances(Number(measurementUnit), Number(nominalValue)));
    }
  }, [dispatch, nominalValue, volumeValue, densityValue, measurementUnit]);

  return (
    <Root data-testid="toleranceBarInfoComponent">
      <Box className={classes.outerBox}>
        <Box mb={2}>
          <Typography align="left" variant="h3">
            {headlineText}
          </Typography>
          <Box className={classes.infoBox} data-testid="averageWeightSamplesAmountInfo">
            <Typography data-testid="amountSamplesDisplay">
              {`${t('data:productionRun.amountSamples')} `}
              {statistics?.checkSamplesDone}
            </Typography>

            {hasAverage && (
              <>
                <Typography marginX={1}>{'|'}</Typography>
                <Typography data-testid="averageWeightDisplay">
                  {`${t('data:productionRun.averageWeight')} `}
                  <FormattedNumberOutputComponent value={averageValue} suffix={suffix} />
                </Typography>
              </>
            )}

            {hasDeviation && (
              <>
                <Typography marginX={1}>{'|'}</Typography>
                <Typography data-testid="standardDeviationDisplay">
                  {`${t('data:productionRun.standardDeviation')} `}
                  <FormattedNumberOutputComponent
                    value={standardDeviation}
                    suffix={suffix}
                    decimalScale={2}
                  />
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.resultBox}>
        <ToleranceBarComponent
          nominalValue={
            productionRun.article.volumeValue
              ? productionRun.article.volumeValue
              : productionRun.article.nominalValue
          }
          tolerances={currentTolerances}
          lowerTolerance={productionRun.article.lowerTolerance}
          upperTolerance={productionRun.article.upperTolerance}
          averageValue={averageValue}
          averagePassed={statistics?.checkSamplesAverageWeightPassed}
          averageSufficient={statistics?.checkSamplesAverageWeightSufficient}
          averageFailed={statistics?.checkSamplesAverageWeightFailed}
          width={forPrint ? 700 : undefined}
          measurementUnit={measurementUnit}
        />
      </Box>
    </Root>
  );
};
