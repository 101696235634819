import { Autocomplete, Box, Button, Paper, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { formClasses, FormRoot } from '../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { ContainerInside } from '../../../components/structure';
import { MAX_SRV_INT } from '../../../constants';
import { isModuleLicensed } from '../../../helper';
import { Article, Line, ModuleType, ProductionOrder } from '../../../model';
import { fetchLicense, licenseSelector } from '../../../store';

import { TestRunFormComponent } from './test-run-form.component';

export interface OwnProps {
  productionOrder?: ProductionOrder;
  submit: (
    productionOrder: Omit<
      ProductionOrder,
      'id' | 'order' | 'lastModified' | 'startable' | 'startErrors'
    >
  ) => void;
  cancel: () => void;
  articles: Article[];
  lines: Line[];
}

type FormData = {
  productionOrderNumber: string;
  productionOrderBatchNumber: string;
  productionOrderArticle: Article | null;
  productionOrderLine: Line | undefined;
  productionOrderAmount: any;
};

const getFormValues = (productionOrder?: ProductionOrder): FormData => ({
  productionOrderNumber: productionOrder?.orderNumber || '',
  productionOrderBatchNumber: productionOrder?.batchNumber || '',
  productionOrderArticle: productionOrder?.article || null,
  productionOrderLine: productionOrder?.productionLine || undefined,
  productionOrderAmount: productionOrder?.amount || '',
});

export const ProductionOrderFormComponent = (props: OwnProps) => {
  const { productionOrder, articles, lines } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'form', 'data']);
  const license = useSelector(licenseSelector);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    register,
    watch,
  } = useForm<FormData>({
    defaultValues: getFormValues(productionOrder),
  });
  const [testRunAfterStart, setTestRunAfterStart] = useState<boolean>(
    !!productionOrder?.testRunAfterStart
  );
  const [testRunAfterDisruption, setTestRunAfterDisruption] = useState<boolean>(
    !!productionOrder?.testRunAfterDisruption
  );
  const [displayLine, setDisplayLine] = useState<Line | undefined>(undefined);

  const { productionOrderLine: currentLine, productionOrderArticle: article } = watch();

  useEffect(() => {
    currentLine && setDisplayLine(currentLine);
  }, [currentLine]);

  useEffect(() => {
    !license && dispatch(fetchLicense());
  }, [dispatch, license]);

  useEffect(() => {
    // Autocomplete needs custom register
    register('productionOrderLine');
  }, [register, t]);

  useEffect(() => {
    // wait for data to be loaded and reset default values
    productionOrder && reset(getFormValues(productionOrder));
  }, [productionOrder, reset]);

  const onSubmit = handleSubmit((formData: FormData) => {
    props.submit({
      orderNumber: formData.productionOrderNumber,
      batchNumber: formData.productionOrderBatchNumber,
      article: formData.productionOrderArticle!,
      productionLine: formData.productionOrderLine,
      amount: formData.productionOrderAmount ? parseInt(formData.productionOrderAmount) : undefined,
      testRunAfterStart: testRunAfterStart,
      testRunAfterDisruption: testRunAfterDisruption,
    });
  });

  const handleArticleChange = (e: React.ChangeEvent<{}>, value: Article | null) => {
    setValue('productionOrderArticle', value || null);
    if (value?.productionLine) {
      setValue('productionOrderLine', value?.productionLine);
      setDisplayLine(value?.productionLine);
    }
  };

  const handleLineChange = (e: React.ChangeEvent<{}>, value: Line | null) => {
    setValue('productionOrderLine', value || undefined);
    setDisplayLine(value || undefined);
  };

  const getArticleLabel = (article: Article) => {
    return t('data:productionOrder.articleLabel', {
      name: article.name,
      numberCaption: t('data:article.number'),
      number: article.articleNumber,
      suffixCaption: t('data:article.nameSuffix'),
      suffix: article.nameSuffix ? article.nameSuffix : '-',
      lineCaption: t('data:productionOrder.line'),
      line: article.productionLine ? article.productionLine!.name : '-',
    });
  };

  return (
    <FormRoot>
      <form onSubmit={onSubmit} className={formClasses.root}>
        <Paper>
          <ContainerInside>
            <GridContainer level={GridLevel.InputPaper}>
              <GridItem>
                <GridContainer level={GridLevel.InputPaper}>
                  <GridItem xl={4} s={6}>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth={true}
                          required
                          label={t('data:productionOrder.number')}
                          inputProps={{ 'aria-label': t('data:productionOrder.number') }}
                          error={errors.productionOrderNumber !== undefined}
                          helperText={
                            errors.productionOrderNumber && errors.productionOrderNumber.message
                          }
                        />
                      )}
                      control={control}
                      name="productionOrderNumber"
                      rules={{
                        required: { value: true, message: t('form:fieldIsRequired') },
                        validate: (value: string) =>
                          value.trim() ? true : (t('form:fieldIsRequired') as string),
                        minLength: { value: 2, message: t('form:minLength', { min: '2' }) },
                        maxLength: { value: 20, message: t('form:maxLength', { max: '20' }) },
                      }}
                    />
                  </GridItem>
                  <GridItem xl={4} s={6}>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth={true}
                          label={t('data:productionOrder.batchNumber')}
                          inputProps={{ 'aria-label': t('data:productionOrder.batchNumber') }}
                          error={errors.productionOrderBatchNumber !== undefined}
                          helperText={
                            errors.productionOrderBatchNumber &&
                            errors.productionOrderBatchNumber.message
                          }
                        />
                      )}
                      control={control}
                      name="productionOrderBatchNumber"
                      rules={{
                        maxLength: { value: 30, message: t('form:maxLength', { max: '30' }) },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem>
                <GridContainer level={GridLevel.InputPaper}>
                  <GridItem xl={4} s={6}>
                    <Controller
                      name="productionOrderArticle"
                      control={control}
                      defaultValue={productionOrder?.article || null}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={articles}
                          getOptionLabel={(article: Article) => getArticleLabel(article)}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {getArticleLabel(option)}
                              </li>
                            );
                          }}
                          isOptionEqualToValue={(option: Article, value: Article) =>
                            option.id === value.id
                          }
                          clearText={t('common:delete')}
                          noOptionsText={t('common:noDataAvailable')}
                          openText={t('common:open')}
                          closeText={t('common:close')}
                          id="productionOrderArticleAutocomplete"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="productionOrderArticle"
                              label={t('data:article.article')}
                              required
                              inputProps={{
                                ...params.inputProps,
                                'data-testid': 'article-input',
                                'aria-label': t('data:article.article'),
                              }}
                              error={!!errors.productionOrderArticle}
                              helperText={errors.productionOrderArticle?.message}
                            />
                          )}
                          onChange={handleArticleChange}
                        />
                      )}
                      rules={{ required: { value: true, message: t('form:fieldIsRequired') } }}
                    />
                  </GridItem>
                  <GridItem xl={4} s={6}>
                    <Autocomplete
                      options={lines}
                      getOptionLabel={(line: Line) => line.name}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      value={displayLine || null}
                      isOptionEqualToValue={(option: Line, value: Line | null) =>
                        option.id === value?.id
                      }
                      clearText={t('common:delete')}
                      noOptionsText={t('common:noDataAvailable')}
                      openText={t('common:open')}
                      closeText={t('common:close')}
                      id="productionOrderLineAutocomplete"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="productionOrderLine"
                          variant="outlined"
                          label={t('data:productionOrder.line')}
                          inputProps={{
                            ...params.inputProps,
                            'data-testid': 'line-input',
                            'aria-label': t('data:productionOrder.line'),
                          }}
                          error={!!errors.productionOrderLine}
                          helperText={
                            errors.productionOrderLine &&
                            (errors.productionOrderLine as any).message
                          }
                        />
                      )}
                      onChange={handleLineChange}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem>
                <GridContainer level={GridLevel.InputPaper}>
                  <GridItem xl={4} s={6}>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth={true}
                          label={t('data:productionOrder.amount')}
                          inputProps={{ 'aria-label': t('data:productionOrder.amount') }}
                          error={errors.productionOrderAmount !== undefined}
                          helperText={
                            errors.productionOrderAmount && errors.productionOrderAmount.message
                          }
                        />
                      )}
                      control={control}
                      name="productionOrderAmount"
                      rules={{
                        validate: (value: string) =>
                          Number.isInteger(Number(value))
                            ? true
                            : (t('form:integerValue') as string),
                        min: { value: 1, message: t('form:minValue', { min: '1' }) },
                        max: {
                          value: MAX_SRV_INT,
                          message: t('form:maxValue', { max: MAX_SRV_INT }),
                        },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </ContainerInside>
        </Paper>
        {isModuleLicensed(license, ModuleType.StaticQualityControl) && (
          <TestRunFormComponent
            articleId={article?.id}
            afterStart={testRunAfterStart}
            afterDisruption={testRunAfterDisruption}
            afterStartChange={setTestRunAfterStart}
            afterDisruptionChange={setTestRunAfterDisruption}
          />
        )}
        <GridItem>
          <Box className={formClasses.buttons}>
            <Button
              data-testid="cancel-btn"
              color="secondary"
              variant="contained"
              onClick={() => props.cancel()}
            >
              {t('form:cancel')}
            </Button>
            <Button data-testid="submit-btn" color="primary" type="submit" variant="contained">
              {t('form:submit')}
            </Button>
          </Box>
        </GridItem>
      </form>
    </FormRoot>
  );
};
