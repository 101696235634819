import { produce } from 'immer';
import { Action } from 'redux';

import {
  ProductionRun,
  ProductionRunCheckExecution,
  CheckHistoryEntry,
  ResultType,
  ProductionRunEvent,
} from '../../model';
import { getProductionTotalsSuccess, ProductionActionType } from '../production/production.actions';
import { changedProductionOverview as changedProductionOverviewAction } from '../production/production.actions';
import {
  updateArrayWithChangedItem,
  deleteItemFromArray,
  addItemToFirstPositionInArray,
} from '../reducer-utils';

import {
  ProductionRunsActionType,
  fetchProductionRunsRunningSuccess,
  fetchProductionRunRunningSuccess,
  changedProductionRunRunning as changedProductionRunAction,
  finishedProductionRunRunning as finishedProductionRunAction,
  fetchProductionRunsFinishedSuccess,
  fetchProductionRunFinishedSuccess,
  fetchProductionRunCheckResultsSuccess,
  openProductionRunSkipReasonDialog,
  openProductionRunFreeTextDialog,
  stopProductionRunSuccess,
  openProductionRunEventDescriptionDialog,
} from './production-runs.actions';

export interface ProductionRunsState {
  fetchingTotal?: boolean;
  fetchingAllRunning?: boolean;
  productionRunsRunning?: ProductionRun[];
  currentProductionRunRunning?: ProductionRun;
  productionRunsRunningTotal?: number;
  fetchingAllFinished?: boolean;
  productionRunsFinished?: ProductionRun[];
  currentProductionRunFinished?: ProductionRun;
  productionRunsFinishedTotal?: number;
  currentProductionRunCheckResult?: {
    execution: ProductionRunCheckExecution;
    resultType: ResultType;
  };
  isSkipReasonDialogOpen?: boolean;
  isFreeTextDialogOpen?: boolean;
  currentProductionRunCheckHistory?: CheckHistoryEntry[];
  currentProductionRunCheckResultsTotal?: number;
  currentProductionRunCheckHistoryPage?: number;
  isEventDescriptionDialogOpen?: boolean;
  openDialogEvent?: ProductionRunEvent;
}

export const getInitialState = (): ProductionRunsState => ({});

export const productionRunsReducer = (
  previousState: ProductionRunsState = getInitialState(),
  action: Action
) => {
  const { type } = action;
  let nextState;

  switch (type) {
    case ProductionActionType.productionGetTotals:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingTotal = true;
      });
      break;

    case ProductionActionType.productionGetTotalsSuccess:
      nextState = produce(previousState, (draftState) => {
        const getTotalsSuccessAction = action as ReturnType<typeof getProductionTotalsSuccess>;
        draftState.productionRunsRunningTotal =
          getTotalsSuccessAction.payload.productionOverview.started;
        draftState.productionRunsFinishedTotal =
          getTotalsSuccessAction.payload.productionOverview.finished;
        draftState.fetchingTotal = false;
      });
      break;

    case ProductionActionType.productionGetTotalsFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingTotal = false;
      });
      break;

    case ProductionRunsActionType.productionRunsRunningFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAllRunning = true;
      });
      break;

    case ProductionRunsActionType.productionRunsRunningFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchProductionRunsRunningSuccess>;
        draftState.productionRunsRunning = fetchSuccessAction.payload.productionRuns;
        draftState.fetchingAllRunning = false;
      });
      break;

    case ProductionRunsActionType.productionRunsRunningFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAllRunning = false;
      });
      break;

    case ProductionRunsActionType.productionRunRunningFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentProductionRunRunning;
      });
      break;

    case ProductionRunsActionType.productionRunRunningFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchProductionRunRunningSuccess>;
        draftState.currentProductionRunRunning = fetchSuccessAction.payload.productionRun;
      });
      break;

    case ProductionRunsActionType.productionRunRunningClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentProductionRunRunning;
      });
      break;

    case ProductionActionType.productionOverviewChanged:
      nextState = produce(previousState, (draftState) => {
        const changedAction = action as ReturnType<typeof changedProductionOverviewAction>;
        const changedProductionOverview = changedAction.payload.productionOverview;
        draftState.productionRunsRunningTotal = changedProductionOverview.started;
        draftState.productionRunsFinishedTotal = changedProductionOverview.finished;
      });
      break;

    case ProductionRunsActionType.productionRunContinueSuccess:
    case ProductionRunsActionType.productionRunRunningChanged:
      nextState = produce(previousState, (draftState) => {
        const changedAction = action as ReturnType<typeof changedProductionRunAction>;
        const changedProductionRun = changedAction.payload.productionRun;
        if (
          draftState.currentProductionRunRunning &&
          draftState.currentProductionRunRunning.id === changedProductionRun.id
        ) {
          draftState.currentProductionRunRunning = changedProductionRun;
        }
        draftState.productionRunsRunning = updateArrayWithChangedItem(
          changedProductionRun,
          draftState.productionRunsRunning
        );
      });
      break;

    case ProductionRunsActionType.productionRunStopSuccess:
    case ProductionRunsActionType.productionRunRunningFinished:
      nextState = produce(previousState, (draftState) => {
        const productionRunAction = action as ReturnType<
          typeof finishedProductionRunAction | typeof stopProductionRunSuccess
        >;
        const productionRun = productionRunAction.payload.productionRun;
        draftState.productionRunsRunning = deleteItemFromArray(
          draftState.productionRunsRunning,
          productionRun.id
        );

        draftState.productionRunsFinished = addItemToFirstPositionInArray(
          draftState.productionRunsFinished,
          productionRun
        );

        draftState.productionRunsFinishedTotal = draftState.productionRunsFinishedTotal
          ? draftState.productionRunsFinishedTotal + 1
          : 1;

        if (
          previousState.currentProductionRunRunning &&
          previousState.currentProductionRunRunning.id === productionRun.id
        ) {
          draftState.currentProductionRunRunning = productionRun;
        }
      });
      break;

    case ProductionRunsActionType.productionRunsFinishedFetchAll:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAllFinished = true;
      });
      break;

    case ProductionRunsActionType.productionRunsFinishedFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchProductionRunsFinishedSuccess>;
        draftState.productionRunsFinished = fetchSuccessAction.payload.productionRuns;
        draftState.productionRunsFinishedTotal = fetchSuccessAction.payload.total;
        draftState.fetchingAllFinished = false;
      });
      break;

    case ProductionRunsActionType.productionRunsFinishedFetchAllFailure:
      nextState = produce(previousState, (draftState) => {
        draftState.fetchingAllFinished = false;
      });
      break;

    case ProductionRunsActionType.productionRunFinishedFetch:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentProductionRunFinished;
        delete draftState.currentProductionRunCheckHistory;
        delete draftState.currentProductionRunCheckResultsTotal;
      });
      break;

    case ProductionRunsActionType.productionRunFinishedFetchSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof fetchProductionRunFinishedSuccess>;
        draftState.currentProductionRunFinished = fetchSuccessAction.payload.productionRun;
      });
      break;

    case ProductionRunsActionType.productionRunOpenSkipReasonDialog:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof openProductionRunSkipReasonDialog>;
        draftState.currentProductionRunCheckResult = fetchSuccessAction.payload;
        draftState.isSkipReasonDialogOpen = true;
      });
      break;

    case ProductionRunsActionType.productionRunCloseSkipReasonDialog:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentProductionRunCheckResult;
        delete draftState.isSkipReasonDialogOpen;
      });
      break;

    case ProductionRunsActionType.productionRunOpenFreeTextDialog:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<typeof openProductionRunFreeTextDialog>;
        draftState.currentProductionRunCheckResult = fetchSuccessAction.payload;
        draftState.isFreeTextDialogOpen = true;
      });
      break;

    case ProductionRunsActionType.productionRunCloseFreeTextDialog:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentProductionRunCheckResult;
        delete draftState.isFreeTextDialogOpen;
      });
      break;

    case ProductionRunsActionType.productionRunCheckResultsFetchAllSuccess:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<
          typeof fetchProductionRunCheckResultsSuccess
        >;
        draftState.currentProductionRunCheckHistory = fetchSuccessAction.payload.checkHistory;
        draftState.currentProductionRunCheckResultsTotal = fetchSuccessAction.payload.total;
        draftState.currentProductionRunCheckHistoryPage = fetchSuccessAction.payload.page;
      });
      break;

    case ProductionRunsActionType.productionRunCheckResultsClear:
      nextState = produce(previousState, (draftState) => {
        delete draftState.currentProductionRunCheckHistory;
        delete draftState.currentProductionRunCheckHistoryPage;
      });
      break;

    case ProductionRunsActionType.productionRunOpenEventDescriptionDialog:
      nextState = produce(previousState, (draftState) => {
        const fetchSuccessAction = action as ReturnType<
          typeof openProductionRunEventDescriptionDialog
        >;
        draftState.openDialogEvent = fetchSuccessAction.payload.event;
        draftState.isEventDescriptionDialogOpen = true;
      });
      break;

    case ProductionRunsActionType.productionRunCloseEventDescriptionDialog:
      nextState = produce(previousState, (draftState) => {
        delete draftState.isEventDescriptionDialogOpen;
        delete draftState.openDialogEvent;
      });
      break;

    default:
      nextState = previousState;
  }

  return nextState;
};
