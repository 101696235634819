import { TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CheckAttribute,
  CheckAttributeType,
  ProductionRunCheckExecution,
  ResultStatus,
} from '../../../model';
import { extendTableBreakpoint } from '../../../theme';
import { dateAndTimeOptions } from '../../../utils/date-utils';

import { CheckResultCellContentComponent } from './check-results-cell-content.component';
import {
  PrimaryTableCell,
  SecondaryTableCell,
  DoublePrimaryTableCell,
} from './check-results-table.styles';

const CancelledCell = styled(TableCell)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: '5px',
  backgroundColor: theme.palette.error.main,
  borderColor: theme.palette.error.main,
}));

export interface OwnProps {
  execution: ProductionRunCheckExecution;
}

const getCheckAttributeDescription = (
  checkAttribute: Omit<CheckAttribute, 'id' | 'editable' | 'lastModified'>
) => {
  switch (checkAttribute.checkAttributeType) {
    case CheckAttributeType.YesNo:
      return checkAttribute.yesNoQuestion;
    case CheckAttributeType.TrafficLight:
      return checkAttribute.trafficLightDescription;
    case CheckAttributeType.FertigPackV:
      return checkAttribute.fertigPackVDescription;
    case CheckAttributeType.Weight:
      return checkAttribute.weightDescription;
    case CheckAttributeType.Temperature:
      return checkAttribute.temperatureDescription;
    case CheckAttributeType.Volume:
      return checkAttribute.volumeDescription;
    case CheckAttributeType.FreeText:
      return checkAttribute.freeTextDescription;
    case CheckAttributeType.Barcode:
      return checkAttribute.barcodeDescription;
    default:
      return undefined;
  }
};

export const CheckResultsTableResultRowComponent = (props: OwnProps) => {
  const { t, i18n } = useTranslation(['common', 'table', 'data']);
  const { execution } = props;
  const theme = useTheme();
  const isTableExtended = useMediaQuery(theme.breakpoints.up(extendTableBreakpoint));

  const getFormattedDate = (execution: ProductionRunCheckExecution) => {
    const checkedDate = new Date(execution.checkedOn);
    return `${checkedDate.toLocaleString(i18n.language, dateAndTimeOptions)}`;
  };

  const getCheckResultRow = (execution: ProductionRunCheckExecution) => {
    return (
      <TableRow key={`execution_row_${execution.id}`}>
        {execution.status === ResultStatus.Cancelled ? (
          <CancelledCell style={{ padding: 0, width: '10px' }} />
        ) : (
          <TableCell style={{ padding: 0, width: '10px' }} />
        )}
        <PrimaryTableCell styleProps={{ isTableExtended }}>
          {getFormattedDate(execution)}
        </PrimaryTableCell>
        <PrimaryTableCell styleProps={{ isTableExtended }}>
          {execution.checkAttribute.name}
        </PrimaryTableCell>
        <SecondaryTableCell styleProps={{ isTableExtended }}>
          {getCheckAttributeDescription(execution.checkAttribute)}
        </SecondaryTableCell>
        <SecondaryTableCell styleProps={{ isTableExtended }}>
          {t([
            `data:checkAttribute.checkAttributeTypeCodes.${execution.checkAttribute.checkAttributeType}`,
            `data:checkAttribute.checkAttributeTypeCodes.notFound`,
          ])}
        </SecondaryTableCell>
        <DoublePrimaryTableCell styleProps={{ isTableExtended }}>
          <CheckResultCellContentComponent execution={execution} />
        </DoublePrimaryTableCell>
      </TableRow>
    );
  };

  return getCheckResultRow(execution);
};
