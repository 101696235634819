import { styled } from '@mui/material/styles';

import { irisCustomColors } from '../../theme';

const PREFIX = 'PrintContentComponent';

export const printContentComponentClasses = {
  headlineSpacing: `${PREFIX}-headlineSpacing`,
  boxSpacing: `${PREFIX}-boxSpacing`,
  boxSpacingWithoutPadding: `${PREFIX}-boxSpacingWithoutPadding`,
  pieChart: `${PREFIX}-pieChart`,
  tablePageBreakAuto: `${PREFIX}-tablePageBreakAuto`,
  tablePageBreakAlways: `${PREFIX}-tablePageBreakAlways`,
  breakInsideAvoid: `${PREFIX}-breakInsideAvoid`,
  breakInsideAvoidPage: `${PREFIX}-breakInsideAvoidPage`,
  noCheckResultSpacing: `${PREFIX}-noCheckResultSpacing`,
  notificationBox: `${PREFIX}-notificationBox`,
};

export const PrintContentComponentRoot = styled('div')(({ theme }) => ({
  '@page': {
    size: '700px 989px',
    margin: '50px',
  },
  [`& .${printContentComponentClasses.headlineSpacing}`]: {
    marginBottom: theme.spacing(3),
  },
  [`& .${printContentComponentClasses.boxSpacing}`]: {
    marginBottom: theme.spacing(7),
    border: 'solid',
    borderColor: irisCustomColors.irisGray,
    borderWidth: '1px',
    padding: theme.spacing(3),
  },
  [`& .${printContentComponentClasses.boxSpacingWithoutPadding}`]: {
    marginBottom: theme.spacing(7),
    border: 'solid',
    borderColor: irisCustomColors.irisGray,
    borderWidth: '1px',
  },
  [`& .${printContentComponentClasses.noCheckResultSpacing}`]: {
    marginTop: theme.spacing(3),
  },
  [`& .${printContentComponentClasses.pieChart}`]: {
    marginLeft: -100,
    marginBottom: -35,
  },
  [`& .${printContentComponentClasses.tablePageBreakAuto}`]: {
    '@media print': {
      pageBreakBefore: 'auto',
    },
  },
  [`& .${printContentComponentClasses.tablePageBreakAlways}`]: {
    '@media print': {
      pageBreakBefore: 'always',
    },
  },
  [`& .${printContentComponentClasses.breakInsideAvoid}`]: {
    breakInside: 'avoid',
  },
  [`& .${printContentComponentClasses.breakInsideAvoidPage}`]: {
    breakInside: 'avoid-page',
  },
  [`& .${printContentComponentClasses.notificationBox}`]: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));
