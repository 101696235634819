import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import deviceLabelExample from '../../../assets/img/device-label-example.png';
import { formClasses } from '../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { ContainerInside } from '../../../components/structure';
import { SRA_PREFIX } from '../../../constants';
import {
  ConnectedScale,
  DeviceType,
  Line,
  Scale,
  Unit,
  WeighingBridge,
  WeighingMode,
} from '../../../model';
import {
  clearScaleLineAlreadySet,
  connectedScaleListSelector,
  fetchConnectedScales,
  fetchLines,
  lineListSelector,
} from '../../../store';

import { WeighingBridgeComponent } from './weighing-bridge.component';

const ScaleFormComponentImg = styled('img')(() => ({
  maxWidth: '100%',
}));

export interface OwnProps {
  device?: Scale;
  submit: (scale: Omit<Scale, 'id' | 'lastModified'>) => void;
  cancel: () => void;
}

export type FormData = {
  scaleName: string;
  productionLine: Line | undefined;
  sraNumber?: string;
  serialNumber?: string;
  connectedScale?: ConnectedScale;
  minimalLoad11?: number | string;
  minimalLoad12?: number | string;
  minimalLoad13?: number | string;
  minimalLoad21?: number | string;
  minimalLoad22?: number | string;
  minimalLoad23?: number | string;
  maximalLoad11?: number | string;
  maximalLoad12?: number | string;
  maximalLoad13?: number | string;
  maximalLoad21?: number | string;
  maximalLoad22?: number | string;
  maximalLoad23?: number | string;
  digitStep11?: number | string;
  digitStep12?: number | string;
  digitStep13?: number | string;
  digitStep21?: number | string;
  digitStep22?: number | string;
  digitStep23?: number | string;
  weighingMode: WeighingMode | undefined;
  unit1: Unit | undefined;
  unit2: Unit | undefined;
};

const getFormValues = (scale?: Scale): FormData => {
  const hasBridge1 = scale?.weighingBridges && scale.weighingBridges.length > 0;
  const bridge1 = hasBridge1 ? scale.weighingBridges![0] : undefined;

  const hasBridge2 = scale?.weighingBridges && scale.weighingBridges.length > 1;
  const bridge2 = hasBridge2 ? scale.weighingBridges![1] : undefined;
  return {
    scaleName: scale?.name || '',
    sraNumber: (scale?.sraNumber && scale?.sraNumber.replace(SRA_PREFIX, '')) || '',
    serialNumber: scale?.serialNumber || '',
    productionLine: scale?.productionLine || undefined,
    connectedScale: scale?.connectedScale || undefined,
    minimalLoad11: !!bridge1 && bridge1.firstMinimalLoad ? bridge1.firstMinimalLoad : '',
    minimalLoad12: !!bridge1 && bridge1.secondMinimalLoad ? bridge1.secondMinimalLoad : '',
    minimalLoad13: !!bridge1 && bridge1.thirdMinimalLoad ? bridge1.thirdMinimalLoad : '',
    minimalLoad21: !!bridge2 && bridge2.firstMinimalLoad ? bridge2.firstMinimalLoad : '',
    minimalLoad22: !!bridge2 && bridge2.secondMinimalLoad ? bridge2.secondMinimalLoad : '',
    minimalLoad23: !!bridge2 && bridge2.thirdMinimalLoad ? bridge2.thirdMinimalLoad : '',
    maximalLoad11: !!bridge1 && bridge1.firstMaximalLoad ? bridge1.firstMaximalLoad : '',
    maximalLoad12: !!bridge1 && bridge1.secondMaximalLoad ? bridge1.secondMaximalLoad : '',
    maximalLoad13: !!bridge1 && bridge1.thirdMaximalLoad ? bridge1.thirdMaximalLoad : '',
    maximalLoad21: !!bridge2 && bridge2.firstMaximalLoad ? bridge2.firstMaximalLoad : '',
    maximalLoad22: !!bridge2 && bridge2.secondMaximalLoad ? bridge2.secondMaximalLoad : '',
    maximalLoad23: !!bridge2 && bridge2.thirdMaximalLoad ? bridge2.thirdMaximalLoad : '',
    digitStep11: !!bridge1 && bridge1.firstDigitStep ? bridge1.firstDigitStep : '',
    digitStep12: !!bridge1 && bridge1.secondDigitStep ? bridge1.secondDigitStep : '',
    digitStep13: !!bridge1 && bridge1.thirdDigitStep ? bridge1.thirdDigitStep : '',
    digitStep21: !!bridge2 && bridge2.firstDigitStep ? bridge2.firstDigitStep : '',
    digitStep22: !!bridge2 && bridge2.secondDigitStep ? bridge2.secondDigitStep : '',
    digitStep23: !!bridge2 && bridge2.thirdDigitStep ? bridge2.thirdDigitStep : '',
    weighingMode: scale ? scale?.weighingMode : WeighingMode.Automatic,
    unit1: !!bridge1 && bridge1.unit ? bridge1.unit : Unit.Gram,
    unit2: !!bridge2 && bridge2.unit ? bridge2.unit : Unit.Gram,
  };
};

export const ScaleFormComponent = (props: OwnProps) => {
  const { device } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['form', 'data']);
  const methods = useForm<FormData>({ defaultValues: getFormValues(device) });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    register,
  } = methods;

  const lines = useSelector(lineListSelector);
  const connectedScales = useSelector(connectedScaleListSelector);

  useEffect(() => {
    dispatch(fetchLines());
    dispatch(fetchConnectedScales());
    return () => {
      dispatch(clearScaleLineAlreadySet());
    };
  }, [dispatch]);

  useEffect(() => {
    // wait for data to be loaded and reset default values
    device && reset(getFormValues(device));
  }, [device, reset]);

  useEffect(() => {
    // Autocomplete needs custom register
    register('productionLine');
    register('connectedScale');
  });

  const [automaticWeighing, setAutomaticWeighing] = useState(
    device && device.weighingMode === WeighingMode.Manual ? false : true
  );

  const onSubmit = handleSubmit((formData: FormData) => {
    const bridge1: WeighingBridge = {
      firstMinimalLoad: formData.minimalLoad11 ? Number(formData.minimalLoad11) : undefined,
      secondMinimalLoad: formData.minimalLoad12 ? Number(formData.minimalLoad12) : undefined,
      thirdMinimalLoad: formData.minimalLoad13 ? Number(formData.minimalLoad13) : undefined,
      firstMaximalLoad: formData.maximalLoad11 ? Number(formData.maximalLoad11) : undefined,
      secondMaximalLoad: formData.maximalLoad12 ? Number(formData.maximalLoad12) : undefined,
      thirdMaximalLoad: formData.maximalLoad13 ? Number(formData.maximalLoad13) : undefined,
      firstDigitStep: formData.digitStep11 ? Number(formData.digitStep11) : undefined,
      secondDigitStep: formData.digitStep12 ? Number(formData.digitStep12) : undefined,
      thirdDigitStep: formData.digitStep13 ? Number(formData.digitStep13) : undefined,
      unit: formData.unit1 ? formData.unit1 : Unit.Gram,
    };
    const bridge2: WeighingBridge = {
      firstMinimalLoad: formData.minimalLoad21 ? Number(formData.minimalLoad21) : undefined,
      secondMinimalLoad: formData.minimalLoad22 ? Number(formData.minimalLoad22) : undefined,
      thirdMinimalLoad: formData.minimalLoad23 ? Number(formData.minimalLoad23) : undefined,
      firstMaximalLoad: formData.maximalLoad21 ? Number(formData.maximalLoad21) : undefined,
      secondMaximalLoad: formData.maximalLoad22 ? Number(formData.maximalLoad22) : undefined,
      thirdMaximalLoad: formData.maximalLoad23 ? Number(formData.maximalLoad23) : undefined,
      firstDigitStep: formData.digitStep21 ? Number(formData.digitStep21) : undefined,
      secondDigitStep: formData.digitStep22 ? Number(formData.digitStep22) : undefined,
      thirdDigitStep: formData.digitStep23 ? Number(formData.digitStep23) : undefined,
      unit: formData.unit2 ? formData.unit2 : undefined,
    };
    props.submit({
      name: formData.scaleName,
      sraNumber: formData.sraNumber ? `${SRA_PREFIX}${formData.sraNumber}` : '',
      serialNumber: formData.serialNumber,
      productionLine: formData.productionLine || undefined,
      connectedScale: formData.connectedScale || undefined,
      deviceType: DeviceType.scale,
      weighingMode: !automaticWeighing ? WeighingMode.Manual : WeighingMode.Automatic,
      weighingBridges: formData.minimalLoad21 ? [bridge1, bridge2] : [bridge1],
    });
  });

  const handleLineChange = (e: React.ChangeEvent<{}>, value: Line | null) => {
    setValue('productionLine', value || undefined);
  };

  const handleConnectedScaleChange = (e: React.ChangeEvent<{}>, value: ConnectedScale | null) => {
    setValue('connectedScale', value || undefined);
  };

  const handleWeighingModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutomaticWeighing(e.target.checked ? true : false);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className={formClasses.root}>
        <Typography variant="h2">{t('data:device:baseData')}</Typography>
        <Paper data-testid="scaleBaseData">
          <ContainerInside>
            <GridContainer level={GridLevel.InputPaper}>
              <GridItem s={6} xl={4}>
                <GridContainer level={GridLevel.InputPaper}>
                  <GridItem>
                    <Autocomplete
                      options={connectedScales || []}
                      getOptionLabel={(connectedScale: ConnectedScale) => connectedScale.name}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      defaultValue={device?.connectedScale || undefined}
                      isOptionEqualToValue={(option: ConnectedScale, value: ConnectedScale) =>
                        option.id === value.id
                      }
                      clearText={t('common:delete')}
                      noOptionsText={t('common:noDataAvailable')}
                      openText={t('common:open')}
                      closeText={t('common:close')}
                      id="connectedScaleAutocomplete"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="connectedScale"
                          label={t('data:device.chooseDevice')}
                          inputProps={{
                            ...params.inputProps,
                            'data-testid': 'connected-scale-input',
                          }}
                          variant="outlined"
                          error={!!errors.connectedScale}
                          helperText={
                            errors.connectedScale && (errors.connectedScale as any).message
                          }
                        />
                      )}
                      onChange={handleConnectedScaleChange}
                    />
                  </GridItem>
                  <GridItem>
                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth={true}
                          required
                          label={t('data:device.name')}
                          inputProps={{
                            'aria-label': t('data:device.name'),
                            'data-testid': 'scale-name-input',
                          }}
                          error={errors.scaleName !== undefined}
                          helperText={errors.scaleName && errors.scaleName.message}
                        />
                      )}
                      control={control}
                      name="scaleName"
                      rules={{
                        required: { value: true, message: t('form:fieldIsRequired') },
                        validate: (value: string) =>
                          value.trim() ? true : (t('form:fieldIsRequired') as string),
                        minLength: {
                          value: 2,
                          message: t('form:minLength', { min: '2' }),
                        },
                        maxLength: {
                          value: 100,
                          message: t('form:maxLength', { max: '100' }),
                        },
                      }}
                    />
                  </GridItem>
                  <GridItem>
                    <GridContainer level={GridLevel.InputPaper}>
                      <GridItem s={6}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth={true}
                              label={t('data:device.serialNumber')}
                              inputProps={{
                                'aria-label': t('data:device.serialNumber'),
                                'data-testid': 'serial-number-input',
                              }}
                              error={errors.serialNumber !== undefined}
                              helperText={errors.serialNumber && errors.serialNumber.message}
                            />
                          )}
                          control={control}
                          name="serialNumber"
                          rules={{
                            maxLength: {
                              value: 64,
                              message: t('form:maxLength', { max: '64' }),
                            },
                          }}
                        />
                      </GridItem>
                      <GridItem s={6}>
                        <Controller
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth={true}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">{SRA_PREFIX}</InputAdornment>
                                ),
                              }}
                              label={t('data:device.sraNumber')}
                              inputProps={{
                                'aria-label': t('data:device.sraNumber'),
                                'data-testid': 'sra-number-input',
                              }}
                              error={errors.sraNumber !== undefined}
                              helperText={errors.sraNumber && errors.sraNumber.message}
                            />
                          )}
                          control={control}
                          name="sraNumber"
                          rules={{
                            maxLength: {
                              value: 64,
                              message: t('form:maxLength', { max: '64' }),
                            },
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem>
                    <Autocomplete
                      options={lines || []}
                      getOptionLabel={(line: Line) => line.name}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      defaultValue={device?.productionLine || undefined}
                      isOptionEqualToValue={(option: Line, value: Line) => option.id === value.id}
                      clearText={t('common:delete')}
                      noOptionsText={t('common:noDataAvailable')}
                      openText={t('common:open')}
                      closeText={t('common:close')}
                      id="productionLineAutocomplete"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="productionLine"
                          label={t('data:line.line')}
                          inputProps={{
                            ...params.inputProps,
                            'data-testid': 'line-input',
                          }}
                          variant="outlined"
                          error={!!errors.productionLine}
                          helperText={
                            errors.productionLine && (errors.productionLine as any).message
                          }
                        />
                      )}
                      onChange={handleLineChange}
                    />
                  </GridItem>
                  <GridItem>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={automaticWeighing}
                          onChange={handleWeighingModeChange}
                          name="weighingMode"
                        />
                      }
                      label={t('data:device.automaticWeighing')}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem s={6} xl={4}>
                <Typography>{t('data:device.scaleLabelInfo')}</Typography>
                <Box mt={1}>
                  <ScaleFormComponentImg
                    src={deviceLabelExample}
                    alt={t('data:device.deviceLabelExample')}
                  />
                </Box>
              </GridItem>
            </GridContainer>
          </ContainerInside>
        </Paper>
        <WeighingBridgeComponent device={device} />
        <Box className={formClasses.buttons}>
          <Button
            data-testid="cancel-btn"
            color="secondary"
            variant="contained"
            onClick={() => props.cancel()}
          >
            {t('form:cancel')}
          </Button>
          <Button data-testid="submit-btn" color="primary" variant="contained" type="submit">
            {t('form:submit')}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
