import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getFertigPackVStatistics } from '../../helper';
import { ProductionRun } from '../../model';
import { GridContainer, GridLevel } from '../grid/grid.container';
import { GridItem } from '../grid/grid.item';

import { BarChartComponent } from './bar-chart.component';

export interface OwnProps {
  productionRun: ProductionRun;
  forPrint?: boolean;
}

export const BarChartInfoComponent = (props: OwnProps) => {
  const { t } = useTranslation(['data']);
  const { productionRun, forPrint } = props;
  const statistics = getFertigPackVStatistics(productionRun);

  return (
    <GridContainer level={GridLevel.Graphic} data-testid="barChartInfoComponent">
      <GridItem>
        <Typography variant="h4">{t('data:check.countsPerCategory')}</Typography>
      </GridItem>
      <GridItem>
        <Typography data-testid="samplesAmountCategories">
          {`${t('data:productionRun.amountSamples')} `}
          {statistics?.checkSamplesDone === undefined ? '0' : statistics?.checkSamplesDone}
        </Typography>
      </GridItem>
      <GridItem>
        <BarChartComponent
          productionRun={productionRun}
          noCheckSamplesText={t('data:productionRun.noFertigPackVCheckSamples')}
          forPrint={forPrint}
        />
      </GridItem>
    </GridContainer>
  );
};
