import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  Box,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../components/structure';
import { Device, DeviceType } from '../../../model';

import { getDeviceFormComponent } from './device.component';

export interface OwnProps {
  device?: Device;
  submit: (device: Omit<Device, 'id' | 'lastModified'>) => void;
  cancel: () => void;
}

export const DeviceSelectComponent = (props: OwnProps) => {
  const { device } = props;
  const { t } = useTranslation(['form', 'data']);

  const [currentDeviceType, setCurrentDeviceType] = useState<DeviceType | undefined>(
    device?.deviceType
  );

  const deviceFormProps = {
    ...props,
    deviceType: currentDeviceType,
  };

  return (
    <FormRoot>
      <ContainerOutsideWithHeader>
        <Typography variant="h2">{t('data:device:deviceType')}</Typography>
        <Paper>
          <ContainerInside>
            <GridContainer level={GridLevel.InputPaper}>
              <GridItem s={6} xl={4}>
                <FormControl fullWidth={true} id="deviceTypeSelect" disabled={!!device}>
                  <InputLabel id="deviceType-label" variant="outlined" required>
                    {t('data:device.deviceType')}
                  </InputLabel>
                  <Select
                    labelId="deviceType-label"
                    label={`${t('data:device.deviceType')} *`}
                    variant="outlined"
                    value={currentDeviceType ? currentDeviceType : ''}
                    onChange={(event) => setCurrentDeviceType(event.target.value as number)}
                    data-testid="selectDeviceType"
                  >
                    <MenuItem
                      value={DeviceType.scale}
                      key={1}
                      data-testid={`deviceType-${DeviceType.scale}`}
                    >
                      {t([
                        `data:device.deviceTypes.${DeviceType.scale}`,
                        `data:device.deviceTypes.notFound`,
                      ])}
                    </MenuItem>
                    <MenuItem
                      value={DeviceType.virtual}
                      key={2}
                      data-testid={`deviceType-${DeviceType.virtual}`}
                    >
                      {t([
                        `data:device.deviceTypes.${DeviceType.virtual}`,
                        `data:device.deviceTypes.notFound`,
                      ])}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </ContainerInside>
        </Paper>
      </ContainerOutsideWithHeader>
      {currentDeviceType ? (
        getDeviceFormComponent(deviceFormProps)
      ) : (
        <GridItem>
          <Box className={formClasses.buttons}>
            <Button
              data-testid="cancel-btn"
              color="secondary"
              variant="contained"
              onClick={() => props.cancel()}
            >
              {t('form:cancel')}
            </Button>
            <Button
              data-testid="submit-btn"
              color="primary"
              variant="contained"
              disabled
              type="submit"
            >
              {t('form:submit')}
            </Button>
          </Box>
        </GridItem>
      )}
    </FormRoot>
  );
};
