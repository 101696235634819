import { DialogContent, Theme, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Check } from '../../../model';
import {
  addCheckForArticle,
  editableCheckAttributeListSelector,
  fetchCheckAttributes,
  isAddCheckDialogOpenSelector,
} from '../../../store';
import { popOverBreakpoint } from '../../../theme';

import { CheckFormComponent } from './check-form.component';
import { StyledDialog } from './check.styles';

export interface OwnProps {
  handleCancel: () => void;
  checkPeriodicity: number;
  articleId: string;
}

export const CheckNewDialogComponent = (props: OwnProps) => {
  const { articleId, checkPeriodicity, handleCancel } = props;

  const dispatch = useDispatch();
  const editableCheckAttributes = useSelector(editableCheckAttributeListSelector);
  const isAddCheckDialogOpen = useSelector(isAddCheckDialogOpenSelector);

  useEffect(() => {
    dispatch(fetchCheckAttributes());
  }, [dispatch]);

  const submit = (check: Omit<Check, 'id'>) => {
    dispatch(addCheckForArticle(articleId, check));
  };

  const cancel = () => {
    handleCancel();
  };

  const smallScreen = !useMediaQuery((theme: Theme) => theme.breakpoints.up(popOverBreakpoint));

  return (
    <StyledDialog styleProps={{ smallScreen }} open={isAddCheckDialogOpen} maxWidth="md">
      <DialogContent>
        <CheckFormComponent
          submit={submit}
          cancel={cancel}
          articleCheckPeriodicity={checkPeriodicity}
          checkAttributes={editableCheckAttributes || []}
        ></CheckFormComponent>
      </DialogContent>
    </StyledDialog>
  );
};
