import { Box, Button, Paper, TextField } from '@mui/material';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formClasses, FormRoot } from '../../../components/components-styles/form.styles';
import { GridContainer, GridLevel } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { ContainerInside, ContainerOutsideWithHeader } from '../../../components/structure';
import { Line } from '../../../model';

export interface OwnProps {
  line?: Line;
  submit: (line: Omit<Line, 'id' | 'lastModified'>) => void;
  cancel: () => void;
}

type FormData = {
  lineName: string;
};

const getFormValues = (line?: Line): FormData => ({
  lineName: line?.name || '',
});

export const LineFormComponent = (props: OwnProps) => {
  const { line } = props;

  const { t } = useTranslation(['form', 'data']);
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormData>({
    defaultValues: getFormValues(line),
  });
  React.useEffect(() => {
    // wait for data to be loaded and reset default values
    line && reset(getFormValues(line));
  }, [line, reset]);

  const onSubmit = handleSubmit(({ lineName }) => {
    props.submit({
      name: lineName,
    });
  });

  return (
    <FormRoot>
      <form onSubmit={onSubmit} className={formClasses.root}>
        <ContainerOutsideWithHeader>
          <Paper>
            <ContainerInside>
              <GridContainer level={GridLevel.InputPaper}>
                <GridItem s={6} xl={4}>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth={true}
                        required
                        label={t('data:line.name')}
                        inputProps={{ 'aria-label': t('data:line.name') }}
                        error={errors.lineName !== undefined}
                        helperText={errors.lineName && errors.lineName.message}
                      />
                    )}
                    control={control}
                    name="lineName"
                    rules={{
                      required: { value: true, message: t('form:fieldIsRequired') },
                      validate: (value: string) =>
                        value.trim() ? true : (t('form:fieldIsRequired') as string),
                      minLength: { value: 2, message: t('form:minLength', { min: '2' }) },
                      maxLength: { value: 100, message: t('form:maxLength', { max: '100' }) },
                    }}
                  />
                </GridItem>
              </GridContainer>
            </ContainerInside>
          </Paper>
        </ContainerOutsideWithHeader>
        <GridItem>
          <Box className={formClasses.buttons}>
            <Button
              data-testid="cancel-btn"
              color="secondary"
              variant="contained"
              onClick={() => props.cancel()}
            >
              {t('form:cancel')}
            </Button>
            <Button data-testid="submit-btn" color="primary" type="submit" variant="contained">
              {t('form:submit')}
            </Button>
          </Box>
        </GridItem>
      </form>
    </FormRoot>
  );
};
