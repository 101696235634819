import { push } from 'connected-react-router';
import { Action } from 'redux';
import { takeLatest, put, fork } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';

import { SagaRequest, SagaRequestHelper } from '../../http';
import { Article, NewArticle, TareMode } from '../../model';
import { AppRoutePath } from '../../routes/routes';
import { sagaErrorHandler } from '../saga-error-handler';

import { tableDataQueryToUrl } from './../../model';
import { ToleranceResult } from './../../model/tolerance-result.model';
import {
  ArticlesActionType,
  fetchArticlesSuccess,
  fetchArticlesFailure,
  fetchArticleSuccess,
  fetchArticleFailure,
  addArticle as addArticleAction,
  addArticleSuccess,
  addArticleFailure,
  editArticle as editArticleAction,
  editArticleSuccess,
  editArticleFailure,
  deleteArticle as deleteArticleAction,
  deleteArticleSuccess,
  deleteArticleFailure,
  copyArticle as copyArticleAction,
  copyArticleSuccess,
  copyArticleFailure,
  fetchArticle,
  fetchArticles,
  calcArticleFertigPackTolerances as calcArticleFertigPackTolerancesAction,
  calcArticleFertigPackTolerancesSuccess,
  calcArticleFertigPackTolerancesFailure,
} from './articles.actions';

const articlesUrl = 'articles';
const copyUrl = 'copy';
const fertigpackvUrl = 'fertigpackv';

const buildFormData = (article: NewArticle & { id?: string }, image?: File) => {
  const formData = new FormData();

  article.id && formData.append('id', article.id);
  formData.append('articleNumber', article.articleNumber.toString());
  formData.append('name', article.name.toString());
  article.nameSuffix && formData.append('nameSuffix', article.nameSuffix);
  formData.append('checkPeriodicity', article.checkPeriodicity.toString());
  article.productionLine && formData.append('productionLineId', article.productionLine.id);
  formData.append('measurementUnit', article.measurementUnit.toString());
  article.imageUrl && formData.append('imageUrl', article.imageUrl);
  image && formData.append('image', image);
  if (article.nominalValue !== undefined) {
    formData.append('nominalValue', article.nominalValue.toString());
  }
  formData.append('tareMode', article.tareMode.toString());
  if (article.tareValue !== undefined && article.tareMode === TareMode.Preset) {
    formData.append('tareValue', article.tareValue.toString());
  }
  if (article.tareSampleSize !== undefined && article.tareMode === TareMode.Single) {
    formData.append('tareSampleSize', article.tareSampleSize.toString());
  }
  if (article.tareDeterminationMode !== undefined && article.tareMode === TareMode.Single) {
    formData.append('tareDeterminationMode', article.tareDeterminationMode.toString());
  }
  if (article.tareMinValue !== undefined && article.tareMode !== TareMode.Preset) {
    formData.append('tareMinValue', article.tareMinValue.toString());
  }
  if (article.tareMaxValue !== undefined && article.tareMode !== TareMode.Preset) {
    formData.append('tareMaxValue', article.tareMaxValue.toString());
  }
  if (article.volumeValue !== undefined) {
    formData.append('volumeValue', article.volumeValue.toString());
  }
  if (article.densityValue !== undefined) {
    formData.append('densityValue', article.densityValue.toString());
  }
  if (article.lowerTolerance !== undefined) {
    formData.append('lowerTolerance', article.lowerTolerance.toString());
  }
  if (article.upperTolerance !== undefined) {
    formData.append('upperTolerance', article.upperTolerance.toString());
  }

  return formData;
};

function* getArticles(action: Action) {
  const { query } = (action as ReturnType<typeof fetchArticles>).payload;
  const tableDataQueryUrl = tableDataQueryToUrl(query);
  try {
    const response = yield* call<
      [boolean, string],
      SagaRequest<{ data: Article[]; metadata: { total: number } }>
    >(SagaRequestHelper.get, true, `${articlesUrl}${tableDataQueryUrl}`);
    yield put(fetchArticlesSuccess(response.data, response.metadata.total));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchArticlesFailure);
  }
}

function* getArticleById(action: Action) {
  const { id } = (action as ReturnType<typeof fetchArticle>).payload;
  try {
    const article = yield* call<[boolean, string], SagaRequest<Article>>(
      SagaRequestHelper.get,
      true,
      `${articlesUrl}/${id}`
    );
    yield put(fetchArticleSuccess(article));
  } catch (e: any) {
    yield sagaErrorHandler(e, fetchArticleFailure);
  }
}

function* addArticle(action: Action) {
  const { article, image } = (action as ReturnType<typeof addArticleAction>).payload;
  const formData = buildFormData(article, image);

  try {
    const addedArticle = yield* call<[boolean, string, { body: FormData }], SagaRequest<Article>>(
      SagaRequestHelper.post,
      true,
      articlesUrl,
      {
        body: formData,
      }
    );
    yield put(addArticleSuccess(addedArticle));
    yield put(push(`/${AppRoutePath.articles}/${addedArticle.id}/${AppRoutePath.edit}`));
  } catch (e: any) {
    yield sagaErrorHandler(e, addArticleFailure);
  }
}

function* editArticle(action: Action) {
  const { article, image } = (action as ReturnType<typeof editArticleAction>).payload;
  const formData = buildFormData(article, image);

  try {
    yield* call<[boolean, string, { body: FormData }], SagaRequest<Article>>(
      SagaRequestHelper.put,
      true,
      `${articlesUrl}/${article.id}`,
      {
        body: formData,
      }
    );
    yield put(editArticleSuccess(article));
    yield put(push(`/${AppRoutePath.articles}`));
  } catch (e: any) {
    yield sagaErrorHandler(e, editArticleFailure);
  }
}

function* deleteArticle(action: Action) {
  const { id } = (action as ReturnType<typeof deleteArticleAction>).payload;
  try {
    yield* call<[boolean, string, {}], SagaRequest<Article>>(
      SagaRequestHelper.delete,
      true,
      `${articlesUrl}/${id}`,
      {}
    );
    yield put(deleteArticleSuccess(id));
  } catch (e: any) {
    yield sagaErrorHandler(e, deleteArticleFailure);
  }
}

function* copyArticle(action: Action) {
  try {
    const { id, article, image } = (action as ReturnType<typeof copyArticleAction>).payload;
    const formData = buildFormData(article, image);
    const copiedArticle = yield* call<[boolean, string, { body: FormData }], SagaRequest<Article>>(
      SagaRequestHelper.post,
      true,
      `${articlesUrl}/${id}/${copyUrl}`,
      {
        body: formData,
      }
    );
    yield put(copyArticleSuccess(copiedArticle));
    yield put(push(`/${AppRoutePath.articles}/${copiedArticle.id}/${AppRoutePath.edit}`));
  } catch (e: any) {
    yield sagaErrorHandler(e, copyArticleFailure);
  }
}

function* getArticleFertigPackTolerances(action: Action) {
  const { measurementUnit, nominalValue, densityValue } = (
    action as ReturnType<typeof calcArticleFertigPackTolerancesAction>
  ).payload;
  let queryUrl = `?measurementUnit=${encodeURIComponent(
    measurementUnit!
  )}&nominalValue=${encodeURIComponent(nominalValue)}`;
  if (densityValue) {
    queryUrl += `&densityValue=${densityValue}`;
  }

  try {
    const tolerances = yield* call<[boolean, string], SagaRequest<ToleranceResult>>(
      SagaRequestHelper.get,
      true,
      `${articlesUrl}/${fertigpackvUrl}${queryUrl}`
    );

    yield put(calcArticleFertigPackTolerancesSuccess(tolerances));
  } catch (e: any) {
    yield sagaErrorHandler(e, calcArticleFertigPackTolerancesFailure);
  }
}

export function* fetchArticlesSaga() {
  yield takeLatest(ArticlesActionType.articlesFetchAll, getArticles);
}

export function* addArticleSaga() {
  yield takeLatest(ArticlesActionType.articleAdd, addArticle);
}

export function* fetchArticleSaga() {
  yield takeLatest(ArticlesActionType.articleFetch, getArticleById);
}

export function* editArticleSaga() {
  yield takeLatest(ArticlesActionType.articleEdit, editArticle);
}

export function* deleteArticleSaga() {
  yield takeLatest(ArticlesActionType.articleDelete, deleteArticle);
}

export function* copyArticleSaga() {
  yield takeLatest(ArticlesActionType.articleCopy, copyArticle);
}

export function* calcArticleFertigPackTolerancesSaga() {
  yield takeLatest(
    ArticlesActionType.articleCalcFertigPackTolerances,
    getArticleFertigPackTolerances
  );
}

export default function* articlesSaga() {
  yield fork(fetchArticlesSaga);
  yield fork(addArticleSaga);
  yield fork(fetchArticleSaga);
  yield fork(editArticleSaga);
  yield fork(deleteArticleSaga);
  yield fork(copyArticleSaga);
  yield fork(calcArticleFertigPackTolerancesSaga);
}
