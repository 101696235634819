import { Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  changePasswordAfterReset,
  changePasswordAfterResetFailedSelector,
  clearChangePasswordAfterResetFailure,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { InfoboxComponent } from '../../infobox/infobox.component';
import { PasswordInputComponent } from '../password-input/password-input.component';

interface FormData {
  password: string;
  passwordConfirmation: string;
  passwordsMismatchError: string;
}

export const ChangePasswordAfterResetFormComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['login', 'form', 'common']);
  const methods = useForm<FormData>();
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    control,
  } = methods;
  const changePasswordFailed = useSelector(changePasswordAfterResetFailedSelector);

  const onSubmit = handleSubmit((formData: FormData) => {
    dispatch(changePasswordAfterReset(formData.password, formData.passwordConfirmation));
  });

  useEffect(() => {
    return () => {
      dispatch(clearChangePasswordAfterResetFailure());
    };
  }, [dispatch]);

  const validatePasswordsMatch = () => {
    const formData = getValues();
    return formData.password === formData.passwordConfirmation;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <PasswordInputComponent
            name="password"
            label={t('login:newPassword')}
            testId="password-input"
            autocomplete="new-password"
          />
          <Box mt={irisSpacing.input.space}>
            <PasswordInputComponent
              name="passwordConfirmation"
              label={t('login:passwordConfirmation')}
              testId="password-confirmation-input"
              autocomplete="new-password"
            />
          </Box>
          {changePasswordFailed && (
            <Box mt={3}>
              <InfoboxComponent type="error" headline={t('login:passwordCouldNotBeChanged')} />
            </Box>
          )}
          <Controller
            name="passwordsMismatchError"
            control={control}
            defaultValue={' '}
            render={({ field }) => <input {...field} type="hidden" />}
            rules={{
              validate: () => validatePasswordsMatch() || (t('login:passwordsMismatch') as string),
            }}
          />
          {errors.passwordsMismatchError && errors.passwordsMismatchError.message && (
            <Box mt={3}>
              <InfoboxComponent type="error" headline={errors.passwordsMismatchError.message} />
            </Box>
          )}
          <Box mt={irisSpacing.button.boxed.mt} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="primary" data-testid="submit-btn" type="submit">
              {t('login:changePassword')}
            </Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};
