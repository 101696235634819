import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { AppRoutePath } from '../../../routes/routes';
import {
  clearLoginFailure,
  licenseExpiredLoginErrorSelector,
  login,
  loginFailedSelector,
  loginRoleInsufficientSelector,
  loginUndefinedErrorSelector,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { InfoboxComponent } from '../../infobox/infobox.component';

const PREFIX = 'LoginFormComponent';
const classes = {
  container: `${PREFIX}-container`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface FormData {
  username: string;
  password: string;
}

export const LoginFormComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['login', 'form', 'common']);
  const loginFailed = useSelector(loginFailedSelector);
  const loginUndefinedError = useSelector(loginUndefinedErrorSelector);
  const loginRoleInsufficient = useSelector(loginRoleInsufficientSelector);
  const licenseExpired = useSelector(licenseExpiredLoginErrorSelector);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>();

  const onSubmit = handleSubmit((formData: FormData) => {
    dispatch(login(formData.username, formData.password));
  });

  useEffect(() => {
    return () => {
      dispatch(clearLoginFailure());
    };
  }, [dispatch]);

  return (
    <Root>
      <form onSubmit={onSubmit}>
        <Box className={classes.container}>
          <Controller
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth={true}
                autoComplete="username"
                label={t('login:username')}
                inputProps={{
                  'data-testid': 'username-input',
                }}
                error={errors.username !== undefined}
                helperText={errors.username && errors.username.message}
              />
            )}
            control={control}
            name="username"
            rules={{
              required: { value: true, message: t('form:fieldIsRequired') },
              validate: (value: string) =>
                value.trim() ? true : (t('form:fieldIsRequired') as string),
            }}
          />
          <Box mt={irisSpacing.input.space}>
            <Controller
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth={true}
                  type="password"
                  autoComplete="current-password"
                  label={t('login:password')}
                  inputProps={{
                    'data-testid': 'password-input',
                  }}
                  error={errors.password !== undefined}
                  helperText={errors.password && errors.password.message}
                />
              )}
              control={control}
              name="password"
              rules={{
                required: { value: true, message: t('form:fieldIsRequired') },
                validate: (value: string) =>
                  value.trim() ? true : (t('form:fieldIsRequired') as string),
              }}
            />
          </Box>
          {loginFailed && (
            <Box mt={3}>
              <InfoboxComponent type="error" headline={t('login:loginFailed')} />
            </Box>
          )}
          {loginUndefinedError && (
            <Box mt={3}>
              <InfoboxComponent type="error" headline={t('login:undefinedError')} />
            </Box>
          )}
          {loginRoleInsufficient && (
            <Box mt={3}>
              <InfoboxComponent type="error" headline={t('login:loginRoleInsufficient')} />
            </Box>
          )}
          {licenseExpired && (
            <Box mt={3}>
              <InfoboxComponent type="error" headline={t('login:licenseExpired')} />
            </Box>
          )}
          <Box mt={irisSpacing.button.boxed.mt} display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Typography>{t('login:forgotPassword')}</Typography>
              <Link
                to={`/${AppRoutePath.requestResetPassword}`}
                component={NavLink}
                underline="hover"
              >
                {t('login:contactAdmin')}
              </Link>
            </Box>
            <Button variant="contained" color="primary" data-testid="submit-btn" type="submit">
              {t('login:login')}
            </Button>
          </Box>
        </Box>
      </form>
    </Root>
  );
};
