import WarningIcon from '@mui/icons-material/Warning';
import { Box, Paper, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CommandsMenuComponent } from '../../../components/commands-menu/commands-menu.component';
import { GridContainer, GridLevel } from '../../../components/grid/grid.container';
import { GridItem } from '../../../components/grid/grid.item';
import { ContainerInside, ContainerOutside } from '../../../components/structure';
import { Check } from '../../../model';
import { irisCustomColors, irisSpacing } from '../../../theme';

import { getCheckAttributeTypeInfo } from './check-attribute-type-info';

const HeadlineContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const HeadlineNameContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const DisabledText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'styleProps',
})<StylePropsWrapper>(({ theme, styleProps }) => ({
  color: styleProps.disabled ? theme.palette.text.disabled : undefined,
}));

const StyledWarningIcon = styled(WarningIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const CommandsMenuContainer = styled(Box)(({ theme }) => ({
  marginBottom: irisSpacing.h3.mb,
  padding: '12px',
}));

const StyledGridItem = styled(GridItem)(() => ({
  color: irisCustomColors.irisGrayDimmed,
}));

interface StylePropsWrapper {
  styleProps: {
    disabled: boolean;
  };
}

export interface OwnProps {
  check: Check;
  checks: Check[];
  checkPeriodicity: number;
  editCheck: (check: Check) => void;
  moveUp: (check: Check) => void;
  getPredecessor: (checks: Check[], check: Check) => string;
  moveDown: (check: Check) => void;
  getSuccessor: (checks: Check[], check: Check) => string;
  deleteCheckById: (checkId: string) => void;
  copy?: boolean;
}

export const ChecksListItemComponent = (props: OwnProps) => {
  const {
    check,
    checks,
    checkPeriodicity,
    editCheck,
    moveUp,
    getPredecessor,
    moveDown,
    getSuccessor,
    deleteCheckById,
    copy,
  } = props;
  const { checkAttribute } = check;
  const disabled = !checkAttribute.editable;

  const theme = useTheme();
  const { t } = useTranslation(['common', 'data']);
  return (
    <ContainerOutside>
      <Paper>
        <ContainerInside>
          <HeadlineContainer>
            <HeadlineNameContainer>
              {!check.checkAttribute.editable && (
                <Box title={t('license:licenseMissing')}>
                  <StyledWarningIcon htmlColor={theme.palette.warning.main} />
                </Box>
              )}
              <DisabledText styleProps={{ disabled }} variant="h3">
                {checkAttribute.name}
              </DisabledText>
            </HeadlineNameContainer>
            <CommandsMenuContainer>
              <CommandsMenuComponent
                handleEdit={() => editCheck(check)}
                disableEdit={!check.checkAttribute.editable}
                handleMoveUp={() => moveUp(check)}
                disableMoveUp={
                  !check.checkAttribute.editable || getPredecessor(checks, check) === check.id
                }
                handleMoveDown={() => moveDown(check)}
                disableMoveDown={
                  !check.checkAttribute.editable || getSuccessor(checks, check) === check.id
                }
                handleDelete={() => deleteCheckById(check.id)}
                disabled={copy}
              />
            </CommandsMenuContainer>
          </HeadlineContainer>
          <GridContainer level={GridLevel.InputPaper}>
            <StyledGridItem s={4}>
              <GridContainer level={GridLevel.Text}>
                <GridItem>
                  <div>{t('data:checkAttribute.specification')}:</div>
                </GridItem>
                {getCheckAttributeTypeInfo(checkAttribute, t).map((info) => (
                  <GridItem key={info.text}>
                    <div>{info.text}:</div>
                  </GridItem>
                ))}
                <GridItem>
                  <div>{t('data:check.checkTimes')}:</div>
                </GridItem>
              </GridContainer>
            </StyledGridItem>
            <GridItem s={8}>
              <GridContainer level={GridLevel.Text}>
                <GridItem>
                  <DisabledText styleProps={{ disabled }}>
                    {t([
                      `data:checkAttribute.specificationCodes.${check.checkAttribute.specification}`,
                      `data:checkAttribute.specificationCodes.notFound`,
                    ])}
                  </DisabledText>
                </GridItem>
                {getCheckAttributeTypeInfo(checkAttribute, t).map((info, index) => (
                  <GridItem key={`${info.value} + ${index}`}>
                    <DisabledText styleProps={{ disabled }}>{info.value || '-'}</DisabledText>
                  </GridItem>
                ))}
                <GridItem>
                  <div color={disabled ? theme.palette.text.disabled : undefined}>
                    {!check.afterStart &&
                    !check.afterDisruption &&
                    !check.periodically &&
                    !check.afterProduction &&
                    !check.checkPeriodicity ? (
                      '-'
                    ) : (
                      <>
                        <div>{check.afterStart && t(`data:check.afterStart`)}</div>
                        <div>{check.afterDisruption && t(`data:check.afterDisruption`)}</div>
                        <div>{check.afterProduction && t(`data:check.afterProduction`)}</div>
                        <div>
                          {check.periodically &&
                            t(`data:check.periodically`, { minutes: checkPeriodicity })}
                        </div>
                        <div>
                          {check.checkPeriodicity &&
                            t(`data:check.periodically`, { minutes: check.checkPeriodicity })}
                        </div>
                      </>
                    )}
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </ContainerInside>
      </Paper>
    </ContainerOutside>
  );
};
