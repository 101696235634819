import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { DateInfoComponent } from '../../../components/date-info/date-info.component';
import { InfoboxComponent } from '../../../components/infobox/infobox.component';
import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { ContainerOutside } from '../../../components/structure';
import { NewArticle } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  editArticle,
  currentArticleSelector,
  fetchArticle,
  fetchLines,
  lineListSelector,
  clearArticleCurrent,
  clearArticleFertigPackTolerances,
  hasFertigPackChecksSelector,
  articleHasFertigPackInputValuesSelector,
  clearChecksForArticle,
} from '../../../store';
import { irisSpacing } from '../../../theme';
import { ChecksListComponent } from '../../checks/checks/checks-list.component';

import { ArticleFormComponent } from './article-form/article-form.component';

export const ArticleEditPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(['common', 'data']);
  const articleId = id;
  const currentArticle = useSelector(currentArticleSelector);
  const lines = useSelector(lineListSelector);
  const hasFertigPackChecks = useSelector(hasFertigPackChecksSelector);
  const hasFertigPackInputValues = useSelector(articleHasFertigPackInputValuesSelector);

  useEffect(() => {
    articleId && dispatch(fetchArticle(articleId));
    return () => {
      dispatch(clearArticleCurrent());
      dispatch(clearChecksForArticle());
    };
  }, [dispatch, articleId]);

  useEffect(() => {
    dispatch(fetchLines());
    return () => {
      dispatch(clearArticleFertigPackTolerances());
    };
  }, [dispatch]);

  const submit = (article: NewArticle, image?: File) => {
    if (currentArticle) {
      const editedArticle = {
        ...article,
        id: currentArticle.id,
        lastModified: currentArticle.lastModified,
      };
      dispatch(editArticle(editedArticle, image));
    }
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.articles}`));
  };

  return (
    <>
      <DetailLayoutComponent
        backLinkText={t('data:article.backToArticles')}
        backLinkTarget={`/${AppRoutePath.articles}`}
        headlineText={t('data:article.edit')}
      >
        {currentArticle &&
          lines &&
          (currentArticle.productionLine
            ? lines.find((line) => line.id === currentArticle.productionLine!.id)
            : true) && (
            <>
              {hasFertigPackChecks && !hasFertigPackInputValues && (
                <ContainerOutside sx={{ marginBottom: irisSpacing.container.space }}>
                  <InfoboxComponent
                    subline={t('data:article.nominalAndTaraValueMissing')}
                    type={'warning'}
                  />
                </ContainerOutside>
              )}
              <ArticleFormComponent
                submit={submit}
                cancel={cancel}
                article={currentArticle}
                lines={lines}
              ></ArticleFormComponent>
              <DateInfoComponent dateVal={new Date(currentArticle.lastModified)} />
              <ChecksListComponent
                articleId={currentArticle.id}
                checkPeriodicity={currentArticle.checkPeriodicity}
              />
            </>
          )}
      </DetailLayoutComponent>
    </>
  );
};
