import { push } from 'connected-react-router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DetailLayoutComponent } from '../../../components/layout/detail-layout/detail-layout.component';
import { NewArticle } from '../../../model';
import { AppRoutePath } from '../../../routes/routes';
import {
  addArticle,
  fetchLines,
  lineListSelector,
  clearArticleFertigPackTolerances,
} from '../../../store';
import { ChecksListComponent } from '../../checks/checks/checks-list.component';

import { ArticleFormComponent } from './article-form/article-form.component';

export const ArticleNewPage = () => {
  const dispatch = useDispatch();
  const lines = useSelector(lineListSelector);
  const { t } = useTranslation(['data']);

  useEffect(() => {
    dispatch(fetchLines());
    return () => {
      dispatch(clearArticleFertigPackTolerances());
    };
  }, [dispatch]);

  const submit = (article: NewArticle, image?: File) => {
    dispatch(addArticle(article, image));
  };

  const cancel = () => {
    dispatch(push(`/${AppRoutePath.articles}`));
  };

  return (
    <>
      <DetailLayoutComponent
        backLinkText={t('data:article.backToArticles')}
        backLinkTarget={`/${AppRoutePath.articles}`}
        headlineText={t('data:article.new')}
      >
        <ArticleFormComponent
          lines={lines || []}
          submit={submit}
          cancel={cancel}
        ></ArticleFormComponent>
        <ChecksListComponent articleId={undefined} checkPeriodicity={0} />
      </DetailLayoutComponent>
    </>
  );
};
