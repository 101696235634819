import { Box, Button, Paper, Typography, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PasswordInputComponent } from '../../components/form/password-input/password-input.component';
import { GridContainer, GridLevel } from '../../components/grid/grid.container';
import { GridItem } from '../../components/grid/grid.item';
import { InfoboxComponent } from '../../components/infobox/infobox.component';
import {
  HeaderContainer,
  BodyStructure,
  ContainerOutside,
  HeaderStructure,
  PageStructure,
  ContainerInside,
} from '../../components/structure';
import {
  changePassword,
  changePasswordSuccessfulSelector,
  clearChangePasswordSuccessful,
  fetchProfileInfo,
  profileInfoSelector,
} from '../../store';
import { irisSpecialColors } from '../../theme';

const StyledDiv = styled('div')(({ theme }) => ({
  color: irisSpecialColors.attributeCaption,
}));

interface FormData {
  password: string;
  passwordConfirmation: string;
  passwordsMismatchError: string;
  currentPassword: string;
}

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['login', 'form', 'common']);
  const methods = useForm<FormData>();
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    control,
  } = methods;

  const profileInfo = useSelector(profileInfoSelector);
  const changePasswordSuccessful = useSelector(changePasswordSuccessfulSelector);

  useEffect(() => {
    dispatch(fetchProfileInfo());
    return () => {
      dispatch(clearChangePasswordSuccessful());
    };
  }, [dispatch]);

  const onSubmit = handleSubmit((formData: FormData) => {
    reset({
      password: undefined,
      passwordConfirmation: undefined,
      passwordsMismatchError: undefined,
      currentPassword: undefined,
    });
    dispatch(
      changePassword(formData.currentPassword, formData.password, formData.passwordConfirmation)
    );
  });

  const validatePasswordsMatch = () => {
    const formData = getValues();
    return formData.password === formData.passwordConfirmation;
  };

  return (
    <PageStructure>
      {profileInfo && (
        <Box>
          <HeaderStructure>
            <HeaderContainer>
              <Typography variant="h1">
                {t('login:welcomeUser', {
                  firstName: profileInfo.firstName,
                  lastName: profileInfo.lastName,
                })}
              </Typography>
            </HeaderContainer>
          </HeaderStructure>
          <BodyStructure>
            <ContainerOutside>
              <Paper>
                <ContainerInside>
                  <GridContainer level={GridLevel.InputPaper}>
                    <GridItem>
                      <Typography variant="h3">{t('login:yourData')}</Typography>
                    </GridItem>
                    <GridItem>
                      <GridContainer level={GridLevel.Text}>
                        <GridItem>
                          <GridContainer level={GridLevel.Text}>
                            <GridItem s={4}>
                              <StyledDiv>{t('data:user.username')}:</StyledDiv>
                            </GridItem>
                            <GridItem s={8}>
                              <div>{profileInfo.username}</div>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem>
                          <GridContainer level={GridLevel.Text}>
                            <GridItem s={4}>
                              <StyledDiv>{t('data:user.firstAndLastName')}:</StyledDiv>
                            </GridItem>
                            <GridItem s={8}>
                              <div>{`${profileInfo.firstName} ${profileInfo.lastName}`}</div>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem>
                          <GridContainer level={GridLevel.Text}>
                            <GridItem s={4}>
                              <StyledDiv>{t('data:user.workspace')}:</StyledDiv>
                            </GridItem>
                            <GridItem s={8}>
                              <div>{profileInfo.workspace}</div>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem>
                          <GridContainer level={GridLevel.Text}>
                            <GridItem s={4}>
                              <StyledDiv>{t('data:user.role')}:</StyledDiv>
                            </GridItem>
                            <GridItem s={8}>
                              <div>
                                {t([
                                  `data:user.userRoles.${profileInfo.role}`,
                                  `data:user.userRoles.notFound`,
                                ])}
                              </div>
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </ContainerInside>
              </Paper>
            </ContainerOutside>
            <ContainerOutside>
              <Typography variant="h2">{t('login:changePassword')}</Typography>
              <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                  <Paper>
                    <ContainerInside>
                      <GridContainer level={GridLevel.InputPaper}>
                        <GridItem>
                          <GridContainer>
                            <GridItem s={6} xl={4}>
                              <PasswordInputComponent
                                name="currentPassword"
                                label={t('login:currentPassword')}
                                testId="current-password-input"
                                autocomplete="current-password"
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem>
                          <GridContainer>
                            <GridItem s={6} xl={4}>
                              <PasswordInputComponent
                                name="password"
                                label={t('login:newPassword')}
                                testId="password-input"
                                autocomplete="new-password"
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem>
                          <GridContainer>
                            <GridItem s={6} xl={4}>
                              <PasswordInputComponent
                                name="passwordConfirmation"
                                label={t('login:passwordConfirmation')}
                                testId="password-confirmation-input"
                                autocomplete="new-password"
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>

                        {changePasswordSuccessful === false && (
                          <GridItem>
                            <GridContainer>
                              <GridItem>
                                <InfoboxComponent
                                  type="error"
                                  headline={t('login:passwordCouldNotBeChanged')}
                                />
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        )}
                        {changePasswordSuccessful === true && (
                          <GridItem>
                            <GridContainer>
                              <GridItem>
                                <InfoboxComponent
                                  type="success"
                                  headline={t('login:passwordCouldBeChanged')}
                                />
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        )}
                        <Controller
                          name="passwordsMismatchError"
                          control={control}
                          render={({ field }) => (
                            <input {...field} type="hidden" defaultValue={' '} />
                          )}
                          rules={{
                            validate: () =>
                              validatePasswordsMatch() || (t('login:passwordsMismatch') as string),
                          }}
                        />
                        {errors.passwordsMismatchError && errors.passwordsMismatchError.message && (
                          <GridItem>
                            <InfoboxComponent
                              type="error"
                              headline={errors.passwordsMismatchError.message}
                            />
                          </GridItem>
                        )}
                      </GridContainer>
                    </ContainerInside>
                  </Paper>
                  <Box display="flex" justifyContent="flex-end" mt={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      data-testid="submit-btn"
                      type="submit"
                    >
                      {t('login:changePassword')}
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            </ContainerOutside>
          </BodyStructure>
        </Box>
      )}
    </PageStructure>
  );
};
